import { selectIsBaseOrg, } from "@/auth/authSlice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function BaseOrganizationComponents({children}){
    const navigate = useNavigate() // need this instance for this component to work
    const isBaseOrg = useSelector(selectIsBaseOrg)
    if (!isBaseOrg){
        return null
    }
    return children
}