import { useEffect, useState } from "react"
import { request } from "@/Api"
import { useDispatch } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import { selectIsLoggedIn, setToken } from "./authSlice"
import { setError } from "@/nav/navSlice"
import { useSelector } from "react-redux"

export default function useInvitation(){
    const dispath = useDispatch()
    const navigate = useNavigate()
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const { inviteToken } = useParams()
    const [ invitation, setInvitation ] = useState()

    const loadInvitation = () => {
        request.get(`invitation/${inviteToken}`)
        .then((response)=>{
            setInvitation(response.data)
        })
    }

    const joinOrganization = () => {
        request.post(`join-organization/${inviteToken}`)
        .then(response=>{
            dispath(setToken(response.data))
            navigate("/dashboard")
        })
        .catch(error=>{
            if (error.response.status == 400){
                let detail = error.response.data.detail
                dispath(setError({ message: detail }))
            }
        })
    }

    const tryGoToSignUp = () => {
        if (!invitation.existing_account_id){
            navigate(`/sign-up/${inviteToken}`)
        }
    }

    const tryGoToLogin = () => {
        if (invitation.existing_account_id && !isLoggedIn){
            navigate(`/login/${inviteToken}`)
        }
    }

    useEffect(()=>{
        if (!inviteToken){
            return
        }
        loadInvitation()
    }, [])
    
    return inviteToken && invitation ? { ...invitation, inviteToken, tryGoToSignUp, tryGoToLogin, joinOrganization } : null
}