import { useNavigate } from "react-router-dom";
import { selectHasStrategy, selectToken } from "@/auth/authSlice";
import { useSelector } from "react-redux";

export default function StrategyComponents({strategy, children}){
    const token = useSelector(selectToken)
    const hasStrategy = useSelector((state) => selectHasStrategy(state, strategy))
    const navigate = useNavigate() // need this instance for this component to work
    if (!token){
        return null
    }
    if (!hasStrategy){
        return null
    }
    return children
}
