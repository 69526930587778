import { useState, useEffect } from "react"

import TableCell from "@mui/material/TableCell"
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import IconButton from "@mui/material/IconButton"
import GroupRoundedIcon from '@mui/icons-material/GroupRounded';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';

import CustomTable, { CustomRow } from "@/table/CustomTable"
import PersonPopup from "@/persons/PersonPopup"
import EntityCreateButton from "@/common/EntityCreateButton"
import DeleteRowButton from "@/common/DeleteRowButton"
import { Heading } from "@/common/StyledComponents"
import { request } from "@/Api"
import { FLOAT_BASE_URL } from "@/config";
import { CircularProgress, Tooltip } from "@mui/material";

export default function Persons() {
    const [persons, setPersons] = useState([])
    const [activePerson, setActivePerson] = useState({})
    const [popupOpen, setPopupOpen] = useState(false)
    const [order, setOrder] = useState("+first_name")

    const getPersons = () => {
        request.get("persons", { 
            params: {
                offset: 0,
                limit: 10000,
                orders: order,
            }
        })
        .then((response)=>{
            setPersons(response.data)
        })
    }

    const handleEditClick = (personId) => {
        setPopupOpen(true)
        request.get(`persons/${personId}`)
        .then((response) => {
            setActivePerson(response.data)
        })
    }

    const handleDeleteClick = (personId) => {
        request.delete(`persons/${personId}`)
        .then(() => {
            setPersons(persons.filter(person => person.id != personId))
        })
    }

    useEffect(getPersons, [order])

    return(
        <>
            <Heading style={{marginBottom: 30, paddingTop: 12}}>Team</Heading>
            <CustomTable
                headCells={[
                    {id: "first_name", label: "First Name", orderable: true}, 
                    {id: "last_name", label: "Last Name", orderable: true}, 
                    {id: "email", label: "Email", orderable: true}, 
                    {id: "_roles", label: "Roles"},
                    {id: "_edit", label: "Edit"}, 
                ]}
                setOrder={setOrder}
                order={order.substring(1)}
                sign={order.substring(0,1)}
                rows={ persons }
                rowComponent={(person) => (
                    <CustomRow>
                        <TableCell component="th" scope="row">{person.first_name}</TableCell>
                        <TableCell align="left">{person.last_name}</TableCell>
                        <TableCell align="left">{person.email}</TableCell>
                        <TableCell align="left">
                            {person.roles_string}
                        </TableCell>
                        <TableCell align="left">
                            {person.person_profile_handle &&
                                <IconButton onClick={()=>window.open(`/profile/${person.person_profile_handle}`, "_blank")}>
                                    <AccountCircleIcon />
                                </IconButton>
                            }
                            <IconButton onClick={()=>handleEditClick(person.id)} aria-label="edit">
                                <EditRoundedIcon />
                            </IconButton>
                            {person.float_account_id &&
                                <IconButton onClick={()=>window.open(`${FLOAT_BASE_URL}/people?manager=${encodeURIComponent(
                                    `${person.first_name} ${person.last_name}`
                                )}`, "_blank")}>
                                    <GroupRoundedIcon />
                                </IconButton>
                            }
                            {person.float_id &&
                                <IconButton onClick={()=>window.open(`${FLOAT_BASE_URL}/?person=${encodeURIComponent(
                                    `${person.first_name} ${person.last_name}`
                                )}`, "_blank")}>
                                    <CalendarMonthIcon />
                                </IconButton>
                            }
                            {person.pending_invitation_id && !person.just_reminded &&
                                <ReminderButton invitationId={person.pending_invitation_id} />
                            }
                            <DeleteRowButton handleDeleteConfirm={()=>{handleDeleteClick(person.id)}}/>
                        </TableCell>
                    </CustomRow>
                )}
            />      
            <EntityCreateButton setPopupOpen={()=>{setPopupOpen(true); setActivePerson({})}} />
            <PersonPopup 
                isNew={activePerson.id==undefined} 
                popupOpen={popupOpen} 
                setPopupOpen={setPopupOpen} 
                persons={persons} 
                setPersons={setPersons} 
                activePerson={activePerson}
            />
        </>
    )
}

const ReminderButton = ({invitationId}) => {
    const [reminded, setReminded] = useState(false)
    const [reminding, setReminding] = useState(false)

    const handleInvitationReminderClick = () => {
        setReminding(true)
        request.post(`persons/invitation-reminder/${invitationId}`)
        .then(()=>{
            setReminded(true)
            setReminding(false)
        })
    }

    if (reminded){
        return(
            <IconButton style={{pointerEvents: "none"}}>
                <CheckCircleRoundedIcon color="success"/>
            </IconButton>
        )
    }

    if (reminding){
        return (
            <CircularProgress/>
        )
    }

    return (
        <IconButton onClick={handleInvitationReminderClick}>
            <NotificationsActiveIcon />
        </IconButton>
    )
}