import { Grid, TextField, Accordion, AccordionSummary, AccordionDetails, Autocomplete, IconButton, Switch, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { addActivity, selectGroup, selectGroups, selectGroupsSubset, selectReconstructedLineItemGroups, selectSections, updateGroup, updateGroupColumns } from "./bidSlice";
import React, { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { useTheme } from "@mui/material";
import { Checkbox, Button, Modal, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { findCommonElements } from "@/utils";
import { ToggleButton } from "@mui/material";
import { topSheetColumns } from "./TopSheetColumns";
import ApiAutocomplete from "@/common/ApiAutocomplete";
import ListAltIcon from '@mui/icons-material/ListAlt';
import styled from "styled-components";
import { CircularProgress } from "@mui/material";
import { request } from "@/Api";
import { getHtmlString } from "./TopsheetDownload";
import { setError } from "@/nav/navSlice";
import { BID_ACTIVITY_REQUESTED_SIGNATURE } from "./constants";

export const GroupSettings = ({bid, groupId, updateTopSheet}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const group = useSelector(state => selectGroup(state, groupId))
    const subGroups =!groupId ? Object.values(useSelector(selectGroups)) : useSelector(state => selectGroupsSubset(state, group.children))
    const hasChildren = !groupId || group.children.length > 0
    const toggleSelected = !groupId ? subGroups.map(g=>g.topsheet_show).every(b=>b) : group.topsheet_show
    const checkedColumns = hasChildren ? findCommonElements(subGroups.map(g=>g.topsheet_columns)) : group.topsheet_columns
    const reconstructedLineGroups = useSelector(selectReconstructedLineItemGroups)

    const onFieldChange = (field) => (event, value) => {
        value = field == "topsheet_show" ? !toggleSelected : value
        const updatedGroupsHash = {}
        if (group){
            const updatedGroup = {...group, [field]: value}
            updatedGroupsHash[updatedGroup.id] = updatedGroup
            dispatch(updateGroupColumns(updatedGroup))
        }
        subGroups.forEach(subGroup=>{
            const updatedSubGroup = {...subGroup, [field]: value}
            updatedGroupsHash[updatedSubGroup.id] = updatedSubGroup
            dispatch(updateGroupColumns(updatedSubGroup))
        })
        
        const updatedLineGroups = reconstructedLineGroups.map(section=>{
            return {
                ...(updatedGroupsHash[section.id] || section), 
                children: section.children.map(category=>{
                    return {...(updatedGroupsHash[category.id] || category) }
                })
            }
        })

        updateTopSheet({...bid, line_items_grouped: updatedLineGroups})
    }

    if (!group && groupId){
        return
    }
    return (
        <Grid spacing={2} container alignItems={"center"} justifyContent={"space-between"} marginBottom={hasChildren ? 0 : 3}>
            <Grid item md={5}><span style={{fontSize: 13}}>{!groupId ? "All" : group.name}</span></Grid>

            <Grid item container md={7} spacing={1} alignItems={"right"}>
                <Grid item>
                    <ToggleButton color={toggleSelected ? "primary" : "standard"} value={"check"} onChange={onFieldChange("topsheet_show")} selected={toggleSelected} size="tiny" sx={{padding: 1}}>
                        <ListAltIcon fontSize="small"/>
                    </ToggleButton>
                </Grid>
                <Grid item flexGrow={1}>
                    <Autocomplete
                        onChange={onFieldChange("topsheet_columns")}
                        disableClearable
                        disableCloseOnSelect
                        multiple
                        clearIcon={false}
                        size="small"
                        value={checkedColumns}
                        options={topSheetColumns.map(column=>column.field)}
                        getOptionDisabled={(option) => {
                            const rateTypeIgnorableColumn = topSheetColumns.find(column=>column.ignoredByRateTypes && column.field==option)
                            return option == "name" || 
                            option == "client_quoted" || 
                            (rateTypeIgnorableColumn ? rateTypeIgnorableColumn.ignoredByRateTypes.includes(group?.rate_type) : false)

                        }}
                        renderInput={(params) =>
                            <TextField
                                {...params}
                                InputProps={{
                                    ...params.InputProps,
                                    type: 'search',
                                    style: {fontSize: 13, padding: 6, maxHeight: 80, overflow: "hidden", background: hasChildren ? theme.palette.background.default : theme.palette.background.paper}
                                }}
                                sx={{"& fieldset": { border: 'none' }}}
                            />
                        }
                        renderOption={(props, option, { selected }) => {
                            const optionColumn = topSheetColumns.find((column)=>column.field == option)
                            const checkedForRateType = optionColumn.ignoredByRateTypes ? !optionColumn.ignoredByRateTypes.includes(group?.rate_type) : true
                            return (
                                <li key={option} {...props} style={{margin: 0, padding: 0, fontSize: 13}}>
                                    <Checkbox
                                        style={{margin: 0, padding: 0}}
                                        checked={checkedForRateType && selected}
                                    />
                                    {optionColumn.name}
                                </li>
                            )
                        }}
                        renderTags={ (options, getTagProps, ownerState) => {
                            if (options.length == 0 || !options){
                                return "None"
                            }else if (options.length == ownerState.options.length){
                                return "All"
                            }else{
                                return "Custom"
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </Grid>   
    )
}

export const GroupAccordion = (props) => {
    const { bid, group, updateTopSheet, children } = props
    const theme = useTheme()
    const [expanded, setExpanded] = useState(false)
    useEffect(()=>{
        setExpanded(group ? false : true)
    }, [])
    return (
        <Accordion expanded={expanded} sx={{boxShadow: "none", borderRadius: 1, '&::before': {content: 'unset'}, marginBottom: 2, marginTop: 1}}>
            <AccordionSummary expandIcon={
                <IconButton onClick={()=>{setExpanded(!expanded)}}>
                    <KeyboardArrowDownRoundedIcon />
                </IconButton>
            }>
                <GroupSettings bid={bid} updateTopSheet={updateTopSheet} key={group ? group.name : null} groupId={group ? group.id : null} /> 
            </AccordionSummary>
            <AccordionDetails bid={bid} sx={{background: theme.palette.background.default}}>
                {children}
            </AccordionDetails>
        </Accordion> 
    )
}

export const AllGroupAccordions = ({bid, updateTopSheet}) => {
    const sectionIds = useSelector(selectSections)
    const sections = useSelector(state => selectGroupsSubset(state, sectionIds))
    return(
        <GroupAccordion bid={bid} updateTopSheet={updateTopSheet}>
            {sections.map(section=>
                <GroupAccordion bid={bid} key={section.id} group={section} updateTopSheet={updateTopSheet}>
                    {section.children.map(category=>
                        <GroupSettings bid={bid} updateTopSheet={updateTopSheet} key={category} groupId={category} />  
                    )}
                </GroupAccordion> 
            )}
        </GroupAccordion>
    )
}

export const SwitchItem = ({ label, checked, onChange }) => {
    return (
        <Grid container alignItems={"center"} justifyContent={"space-between"}>
            <Grid item>{label}</Grid>
            <Switch checked={checked || false} onChange={onChange}/>
        </Grid>
    )
}

export const InfoInput = (props) => {
    const { onBlur, text } = props
    const [textLocal, setTextLocal] = useState("")
    const onChange = (event) => {
        setTextLocal(event.target.value)
    }
    useEffect(()=>{
        setTextLocal(text)
    }, [text])
    return (
        <TextField 
            {...props}
            size="small"
            onChange={onChange}
            onBlur={onBlur}
            fullWidth
            value={textLocal || ""}
        />
    )
}

export const SendersInput = ({ onChange, projectId, value }) => {
    return (
        <ApiAutocomplete
            placeholder="+ Add"
            size="small"
            silentErrors
            fullWidth
            fullOptionData
            value={value}
            apiRoute={"persons"}
            fields={["first_name", "last_name"]}
            onChange={onChange}
            multiple
            inputStyle={{"& fieldset": { border: 'none' }}}
            apiFilters={{"project_id": `${projectId}`}}
            isOptionEqualToValue={(option, value)=>{
                return option.id == value.id
            }}
        />
    )
}

const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    min-width: 500px;
    padding: 24px;
`
export const SignerPopup = ({bidId, open, close, setSignatureRequested}) => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const [signer, setSigner] = useState({})
    const [errors, setErrors] = useState({})
    const [requesting, setRequesting] = useState(false)

    const submitRequestSignature = (signer_name, signer_email) => {
        request.post(`/bids/${bidId}/activity`, {action: BID_ACTIVITY_REQUESTED_SIGNATURE, note: "Xodo signature requested"})
        .then(response => {
            dispatch(addActivity(response.data))
            requestXodoSignature(signer_name, signer_email)
        })
    }

    const requestXodoSignature = (signer_name, signer_email) => {
        setRequesting(true)
        setErrors({})
        request.post("/xodo-topsheet", {
            html_string: getHtmlString(), 
            bid_id: bidId,
            signer_name: signer_name,
            signer_email: signer_email
        })
        .then(()=>{
            close()
            setRequesting(false)
            setSignatureRequested(true)
        })
        .catch((error)=>{
            setRequesting(false)
            if (error.response.status == 422){
                const details = error.response.data.detail
                setErrors(Object.fromEntries(details.map(detail => [
                    detail.loc[detail.loc.length-1], detail.msg 
                ])))
            }
            if (error.response.status == 400){
                dispatch(setError({ message: error.response.data.detail }))
            }
        })
    }

    const handleInput = (field) => (event) => {
        setSigner({...signer, [field]: event.target.value})
    }

    const handleRequestSignature = (name, email) => () => {
        submitRequestSignature(name, email)
    }

    const handleClose = () => {
        close()
        setRequesting(false)
    }

    return (
        <Modal open={open} onClose={handleClose}>
            <CustomBox sx={{ background: theme.palette.background.default, borderRadius: 3 }}>
                <p style={{margin: 0, marginBottom: 20}}>Request Topsheet Signature</p>
                <Grid container spacing={2} justifyContent={"right"}>
                    <Grid item md={6}>
                        <TextField
                            error={errors.signer_name ? true : false}
                            helperText={errors.signer_name}
                            label="Signer Name"
                            fullWidth
                            value={signer.name || ""}
                            onChange={handleInput("name")}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={errors.signer_email ? true : false}
                            helperText={errors.signer_email}
                            label="Signer Email"
                            fullWidth
                            defaultValue={signer.email || ""}
                            onChange={handleInput("email")}
                        />
                    </Grid>

                    <Grid item>
                        <Button onClick={handleClose} color="secondary" variant="contained">
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {requesting ? 
                        <CircularProgress /> : 
                        <Button onClick={handleRequestSignature(signer.name, signer.email)} variant="contained">
                            Request
                        </Button>}
                    </Grid>
                </Grid>
            </CustomBox>
        </Modal>
    )
}