import { useState } from 'react'

import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'

import { request } from "@/Api"

export default function CustomFiltersSavePopup( props ){
    const {filters, orders, personId, modelType, customFilters, setCustomFilters, setActiveCustomFilter} = props
    const [open, setOpen] = useState(false)
    const [filterName, setFilterName] = useState("")

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleInputChange = (event) => {
        setFilterName(event.target.value)
    }

    const handleConfirm = () => {
        request.post("custom_filters", {
            name: filterName, 
            filters: JSON.stringify(filters), 
            orders: orders, 
            person_id: personId, 
            model_type: modelType,
            is_default: false
        })
        .then((response)=>{
            setOpen(false)
            setCustomFilters([...customFilters, response.data])
            setActiveCustomFilter(response.data)
        })
    }

    return(
        <>
            <Button variant="contained" onClick={handleClickOpen}>
                SAVE CUSTOM FILTER
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Enter the name of your filter
                </DialogTitle>
                <DialogContent>
                <TextField onChange={handleInputChange} />
                </DialogContent>
                <DialogActions>
                <Button onClick={handleConfirm}>SAVE</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}