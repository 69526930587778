import { FlexCell, FlexRow } from "@/common/StyledComponents"
import { Checkbox, Collapse, IconButton, Paper, Radio, TextField, Tooltip, useTheme } from "@mui/material"
import { useState } from "react"
import { Grid } from "@mui/material"
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';
import { AccountingBillHeaderLine, AccountingBill } from "./AccountingBill";
import { useDispatch, useSelector } from "react-redux";
import { checkLine, deleteLine, editAllocation, selectCanMultiCheckLines, selectLine, selectLineIsSearched, selectProjectCurrency, selectToAllocateLines, uncheckLine, updateLine, updateLines, updateTotals } from "./actualSlice";
import { formattedCurrency, getCurrencySymbol } from "@/utils";
import { request } from "@/Api";
import { NumericFormat } from "react-number-format/dist/react-number-format.cjs";
import DeleteRowButton from "@/common/DeleteRowButton";

export function ActualLine({lineId, isLeaf, allocations, billCurrency, selectable, billId, isCustom}){
    const theme = useTheme()
    const dispatch = useDispatch()
    const line = useSelector(state => selectLine(state, lineId))
    const searched = useSelector(state => selectLineIsSearched(state, lineId))
    const projectCurrency = useSelector(selectProjectCurrency)
    const [ expanded, setExpanded ] = useState(false) 

    if (!line || !searched){
        return
    }

    const hasExistingAllocations = line ? line.actual_line_accounting_bills.length > 0 : false
    const billAllocation = (billId && line) ? line.actual_line_accounting_bills.find(a=>a.accounting_bill_id==billId && a.actual_line_id==line.id) : null
    const billAllocatedVendor = billAllocation ? billAllocation.actual_vendor : 0
    const billAllocatedProject = billAllocation ? billAllocation.actual_project : 0

    let hasBills = line.actual_line_accounting_bills.length > 0

    const handlePortionOfBillInput = (values) => {
        const amount = values.value ? parseFloat(values.value) : 0
        dispatch(editAllocation({
            "model_type_id_key": "actual_line_id",
            "model_id": lineId,
            "data": {"actual": amount}
        }))
    }

    const handleNumberInput = (field) => (values) => {
        dispatch(updateLine({...line, [field]: values.value}))
    }

    const handleInput = (field) => (event) => {
        const value = event.target.value
        dispatch(updateLine({...line, [field]: value}))
    }

    const handleBlur = (_) => {
        request.put(`actual-lines/${lineId}`, line)
        .then(response=>{
            dispatch(updateLine({...line, ...response.data}))

            request.get(`/projects/${line.project_id}/actuals`)
            .then(response=>{
                const projectData = response.data
                dispatch(updateTotals(projectData))
            })
        })
    }

    const handleDelete = () => {
        request.delete(`actual-lines/${lineId}`)
        .then(()=>{
            dispatch(deleteLine(lineId))
        })
    }

    return (
        <>
            <FlexRow style={{paddingLeft: 25, paddingBottom: 10, gap: "20px"}}>
                {selectable &&
                <FlexCell style={{minWidth: 30, maxWidth: 30}}>
                    <ActualLineToggle lineId={lineId}/>
                </FlexCell>}
                <FlexCell minWidth={80}>
                    <Grid container wrap="nowrap" alignItems={"center"}>
                        <Tooltip title={`id: ${lineId}`}>
                            <Grid item><span>{line.name}</span></Grid>
                        </Tooltip>
                        {!isLeaf && 
                        <Grid item>
                            <IconButton onClick={()=>{setExpanded(!expanded)}} disabled={!hasBills}>
                                <ArticleOutlinedIcon />
                            </IconButton>
                        </Grid>}
                    </Grid>
                </FlexCell>
                {!allocations && <FlexCell minWidth={250}>
                    <TextField
                        sx={{marginRight: 10}}
                        size="small"
                        inputProps={{style:{
                            padding: "12px 20px 12px 20px"
                        }}}
                        InputProps={{style:{
                            borderRadius: 10,
                            fontSize: 12
                        }}}
                        fullWidth
                        value={line.notes || ""}
                        onInput={handleInput("notes")}
                        onBlur={handleBlur}
                    />
                </FlexCell>}
                <FlexCell align="right"><span>{formattedCurrency(line.budget, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>
                {!allocations && 
                <FlexCell style={{marginLeft: 20, minWidth: 120}}>
                    <NumericFormat
                        customInput={TextField}
                        value={line.running}
                        sx={{width:"100%", fontSize: 1}}
                        inputProps={{
                            style: {textAlign: "right", fontSize: 12, padding: "12px 15px 12px 15px",},
                        }}
                        InputProps={{
                            style:{
                                borderRadius: 10,
                                fontSize: 12,
                            },
                        }}
                        prefix={getCurrencySymbol(line.vendor_currency || projectCurrency)}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handleNumberInput("running")}
                        onBlur={handleBlur}
                        onFocus={(e)=>{e.target.select()}}
                        size="small"
                    />
                </FlexCell>}
                {!allocations && 
                <FlexCell item align="right">
                    <span 
                        style={{
                            color: 
                            line.running_variance < 0 ? "red" :
                            theme.palette.text.primary}}
                    >
                        {formattedCurrency(line.running_variance, line.vendor_currency || projectCurrency, false, 2)}
                    </span>
                </FlexCell>}
                {billAllocation && <FlexCell item align="right"><span>{formattedCurrency(billAllocatedVendor, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>}
                {billAllocation && <FlexCell item align="right"><span>{formattedCurrency(billAllocatedProject, projectCurrency, false, 2)}</span></FlexCell>}
                {!billAllocation && <FlexCell item align="right"><span>{formattedCurrency(line.actual, line.vendor_currency || projectCurrency, false, 2)}</span></FlexCell>}
                {!billAllocation && <FlexCell item align="right"><span>{formattedCurrency(line.actual_project, projectCurrency, false, 2)}</span></FlexCell>}
                {allocations && 
                <FlexCell>
                    <NumericFormat
                        variant="standard"
                        customInput={TextField}
                        value={allocations.find(allocation=>allocation.actual_line_id == lineId).actual}
                        sx={{width:"100%", fontSize: 1}}
                        inputProps={{
                            style: {textAlign: "right", fontSize: 12, padding: "12px 15px 12px 15px", padding: 4},
                        }}
                        InputProps={{
                            style:{
                                borderRadius: 10,
                                fontSize: 12,
                                padding: 0
                            },
                        }}
                        prefix={getCurrencySymbol(billCurrency)}
                        thousandsGroupStyle="thousand"
                        thousandSeparator=","
                        decimalScale={2}
                        fixedDecimalScale
                        onValueChange={handlePortionOfBillInput}
                        onFocus={(e)=>{e.target.select()}}
                        size="small"
                    />
                </FlexCell>}
                {isCustom && 
                <FlexCell item align="right" style={{minWidth: 100, maxWidth: 100}}>
                    {!hasExistingAllocations && <DeleteRowButton handleDeleteConfirm={handleDelete}/>}
                </FlexCell>}
            </FlexRow>
            {!isLeaf && 
            <Collapse in={expanded} sx={{width: "100%"}}>
                <Paper style={{marginLeft: 16, padding: 20, marginTop: 10, marginBottom: 15, overflow: "auto"}}>
                    <AccountingBillHeaderLine 
                        selectable={false}
                        lineId={lineId}
                    />
                    {line.actual_line_accounting_bills.map((bill, index)=>
                        <AccountingBill 
                            key={index} 
                            billId={bill.accounting_bill_id} 
                            lineId={lineId}
                            selectable={false} 
                            isLeaf={true}
                        />
                    )}
                </Paper>
            </Collapse>}
        </>
    )
}

export function ActualHeaderLine({isAllocating, selectable, billId, isCustom}){
    return (
        <FlexRow style={{color: "grey", paddingBottom: 20, paddingLeft: 25, gap: "20px", paddingTop: 15}}>
            {selectable && <FlexCell item style={{minWidth: 30, maxWidth: 30}}/>}
            <FlexCell item minWidth={80}>Item</FlexCell>
            {!isAllocating && <FlexCell item minWidth={250}>Notes</FlexCell>}
            <FlexCell item align="right">Vendor Budget</FlexCell>
            {!isAllocating && <FlexCell item style={{marginLeft: 20, minWidth: 120}} align="right">Vendor Running</FlexCell>}
            {!isAllocating && <FlexCell item align="right">Running Variance</FlexCell>}
            {billId && <FlexCell item align="right">Vendor Allocated</FlexCell>}
            {billId && <FlexCell item align="right">Project Allocated</FlexCell>}
            {!billId && <FlexCell item align="right">Vendor Total</FlexCell>}
            {! billId && <FlexCell item align="right">Project Total</FlexCell>}
            {isAllocating && <FlexCell item align="right">Portion of Bill</FlexCell>}
            {isCustom && <FlexCell item style={{minWidth: 100, maxWidth: 100}}/>}
        </FlexRow>
    )
}

const ActualLineToggle = ({lineId}) => {
    const dispatch = useDispatch()
    const toAllocateLines = useSelector(selectToAllocateLines) // TODO: optimize the state to be isolated from other lines
    const multiSelect = useSelector(selectCanMultiCheckLines)
    const Toggle = multiSelect ? Checkbox : Radio
    const handleToggle = (event, checked) => {
        checked ? dispatch(checkLine(lineId)) : dispatch(uncheckLine(lineId))
    }
    return (
        <Toggle 
            checked={toAllocateLines.find(l=>l.id==lineId) != null}
            onChange={handleToggle}
            size="small" 
        />
    )
}