import { useState } from "react"
import { useNavigate } from "react-router-dom"

import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField"
import Switch from '@mui/material/Switch'
import Link from "@mui/material/Link"
import TableRow from "@mui/material/TableRow"
import Collapse from "@mui/material/Collapse"
import IconButton from '@mui/material/IconButton'
import Tooltip from "@mui/material/Tooltip"
import Chip from "@mui/material/Chip"

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import ChatIcon from '@mui/icons-material/Chat'

import { request } from "@/Api"
import { getCurrencySymbol } from "@/utils"
import { CustomRow } from "@/table/CustomTable"
import { Subtitle } from "@/common/StyledComponents"
import { formattedCurrency } from "@/utils"
import { NumericFormatCustom } from "@/common/StyledComponents"
import DeleteRowButton from "@/common/DeleteRowButton"

const BillRow = ({item, items, setItems}) => {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)
    const [focusedBillId, setFocusedBillId] = useState(null)

    const isForecast = item.bill_type == "Forecast"

    const handleSwitchChange = (event) => {
        request.put(`bills/${item.id}`, {
            ...item,
            "sent": event.target.checked
        })
        .then((response)=>{
            setItems(items.map(bill=>{
                return (bill.id == item.id) ? {...item, "sent": response.data.sent} : bill
            }))
        })
    }

    const handleBillDeleteConfirm = () => {
        const baseEndpoint = isForecast ? "forecast-bills" : "bills"

        request.delete(`${baseEndpoint}/${item.id}`)
        .then(()=>{
            setItems(items.filter(bill=>{
                return bill.id != billId
            }))
        })
    }

    const handleInputBlur = (fieldName) => (event) => {
        const value = event.target.value.replace(/,/g, '');
        request.put(`bills/${item.id}`, {
            ...item,
            [fieldName]: value || 0
        })
        .then(()=>{
            setItems(items.map((bill) => {
                return (bill.id == item.id) ? { ...bill, [fieldName]: value } : bill
            }));
        })
    }

    const handleOpenProject = (event) => {
        event.preventDefault()
        navigate(`/projects/${item.project_id}`)
    }

    return (
        <>
            <CustomRow>
                <TableCell>
                    <IconButton disabled={!item.notes} size="small" onClick={() => setOpen(!open)} aria-label="expand row">
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">{item.bill_date ? item.bill_date.split("T")[0] : null}</TableCell>
                <TableCell align="left">{item.docket}</TableCell>
                <TableCell align="center"><Chip label={item.bill_type} /></TableCell>
                <TableCell align="center">{item.certainty}</TableCell>
                <TableCell align="right">{`${(item.quoted_percentage * 100).toFixed()}%`}</TableCell>
                <TableCell align="right">{formattedCurrency(item.estimated_amount, item.currency)}</TableCell>

                <TableCell width={160} align="right">
                    {isForecast ? "-" : item.sent ? (
                        <Tooltip title="This bill is already sent">
                            <div style={{cursor: "not-allowed", color: "grey"}}>{formattedCurrency(item.actual_amount||0, item.currency)}</div>
                        </Tooltip>
                    ) : focusedBillId == item.id ? (
                        <TextField
                            size="small"
                            autoFocus
                            onBlur={handleInputBlur("actual_amount")}
                            onKeyDown={(e) => {e.key!="Enter" || handleInputBlur("actual_amount")(e)}}
                            value={item.actual_amount || ""}
                            InputProps={{
                                startAdornment: getCurrencySymbol(item.currency),
                                inputComponent: NumericFormatCustom,
                            }}
                            inputProps={{
                                style: {textAlign: "right"}
                            }}
                        />
                    ) : (
                        <div onClick={()=>{setFocusedBillId(item.id)}} style={{cursor: "pointer"}}>
                            {formattedCurrency(item.actual_amount||0, item.currency)}
                        </div>
                    )}
                </TableCell>

                <TableCell align="left">
                    <Link href={`/projects/${item.project_id}`} onClick={handleOpenProject}>
                        {item.project_name}
                    </Link>
                </TableCell>
                <TableCell align="left">{item.project_status}</TableCell>
                <TableCell align="left">{item.client_name}</TableCell>
                <TableCell align="center">
                    {isForecast ? "-" : (
                        <IconButton disabled={!item.notes} size="small" onClick={() => setOpen(!open)} aria-label="expand row">
                            <ChatIcon />
                        </IconButton>
                    )}
                </TableCell>
                <TableCell align="center">
                    {isForecast ? "-" : (
                        item.signed ? <CheckCircleOutlineRoundedIcon color="success"/> : <HighlightOffRoundedIcon color="error"/>
                    )}
                </TableCell>
                <TableCell align="center">
                    {isForecast ? "-" : (
                        <Switch checked={item.sent ? true : false} onChange={handleSwitchChange} />
                    )}
                </TableCell>
                <TableCell align="left">
                    <DeleteRowButton handleDeleteConfirm={handleBillDeleteConfirm}/>
                </TableCell>
            </CustomRow>
            {
                item.notes ? 
                <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
                    <Collapse sx={{padding: "20px"}} in={open} timeout="auto" unmountOnExit>
                        <Subtitle style={{marginBottom: "16px"}}>Notes</Subtitle>
                        {item.notes}
                    </Collapse>
                </TableCell>
                </TableRow>
                : null
            }

        </>
    )
}

export default BillRow
