import { useEffect, useState } from 'react'

import { Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import InputLabel from '@mui/material/InputLabel'

import { request } from "@/Api";
import ApiAutocomplete from "@/common/ApiAutocomplete";
import { CircularProgress } from '@mui/material';

export default function PersonPopup( props ){
    const { isNew, popupOpen, setPopupOpen, persons, setPersons, activePerson } = props
    const [person, setPerson] = useState({})
    const [errors, setErrors] = useState({})
    const [processing, setProcessing] = useState(false)

    useEffect(()=>{
        setPerson({...activePerson})
        setErrors({})
    }, [activePerson])

    const handleClose = () => {
        setPopupOpen(false);
    };

    const handleInputChange = (feildName) => (event) => {
        setPerson({...person, [feildName]: event.target.value})
    }

    const handleAutocompleteChange = (feildName) => (event, newValue) => {
        setPerson({...person, [feildName]: newValue})    
    }

    const handleCreate = () => {
        setProcessing(true)
        request.post("persons", {...person, "role_names": person.roles.map(role=>role.name)})
        .then((response)=>{
            setPersons([response.data, ...persons])
            handleClose()
        })
        .catch((error)=>{
            if (error.response.status == 400){ // 400 detail come as a str, not array
                let detail = error.response.data.detail
                setErrors({... {"email": detail}})
            } else {
                error.defaultErrorResponse()
            }
        })
        .finally(()=>{
            setProcessing(false)
        })
    }

    const handleEdit = () => {
        setProcessing(true)
        request.put(`persons/${person.id}`, {...person, "role_names": person.roles.map(role=>role.name)})
        .then((response) => {
            setPersons(persons.map(person => 
                person.id === response.data.id 
                ? { ...response.data } 
                : person 
            ))
            handleClose()
        })
        .catch((error)=>{
            if (error.response.status == 400){
                let detail = error.response.data.detail
                setErrors({... {"email": detail}})
            }
        })
        .finally(()=>{
            setProcessing(false)
        })
    }

    return(
        <Dialog
        open={popupOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {isNew ? "Create Person" : `Edit ${activePerson.first_name} ${activePerson.last_name}` }
            </DialogTitle>

            <DialogContent>
                <Grid container sx={{ paddingTop: 2 }}>
                    <Grid xs={6} md={6}>
                        <InputLabel>First Name</InputLabel>
                        <TextField error={"first_name" in errors} sx={{width:"100%"}} value={person.first_name || ""} onChange={handleInputChange("first_name")}/>
                    </Grid>
                    <Grid xs={6} md={6}>
                        <InputLabel>Last Name</InputLabel>
                        <TextField error={"last_name" in errors} sx={{width:"100%"}} value={person.last_name || ""} onChange={handleInputChange("last_name")}/>
                    </Grid>
                    <Grid xs={6} md={6}>
                        <InputLabel>Email</InputLabel>
                        <TextField error={"email" in errors} helperText={errors["email"]} sx={{width:"100%"}} value={person.email || ""} onChange={handleInputChange("email")}/>
                    </Grid>
                    <Grid xs={6} md={6}>
                        <InputLabel>Roles</InputLabel>
                        <ApiAutocomplete
                            value={person.roles ? person.roles : []}
                            apiRoute={"roles"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("roles")}
                            error={errors["role_ids"]}
                            multiple
                            fullOptionData
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions>
                <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
                {processing ? <CircularProgress /> :
                    <>
                        {isNew ? (
                            <Button variant="contained" color="primary" onClick={handleCreate}>Create</Button>
                        ) : (
                            <Button variant="contained" color="primary" onClick={handleEdit}>Update</Button>
                        )}
                    </>
                }
            </DialogActions>

        </Dialog>
    )
}
