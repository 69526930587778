import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import useMediaQuery from "@mui/material/useMediaQuery"
import Grid from "@mui/material/Grid"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Drawer from "@mui/material/Drawer"
import Button from "@mui/material/Button"
import IconButton from "@mui/material/IconButton"
import Link from "@mui/material/Link"
import { useTheme } from "@mui/material"

import ArrowLeftRoundedIcon from '@mui/icons-material/ArrowLeftRounded';
import SupportIcon from '@mui/icons-material/Support';
import PendingIcon from '@mui/icons-material/Pending';
import ArrowRightRoundedIcon from '@mui/icons-material/ArrowRightRounded';

import LogoButton from "@/nav/LogoButton"
import { useHandlePageGoTo, useIsHidden } from "@/nav/navHooks"
import { selectNavOpen, setNavOpen } from "@/nav/navSlice"
import { getMenuItemsMain, getMenuItemsExternal, getMenuItemsOther } from "@/nav/menuItems"
import { selectNavTokenContext } from "@/auth/authSlice"
import { HighlightedIcon } from "@/common/StyledComponents"

export default function SideNav() {
    const theme = useTheme()
    const open = useSelector(selectNavOpen)
    const dispatch = useDispatch()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isHidden = useIsHidden()
    
    const [currentRoute, setCurrentRoute] = useState(null)
    
    const token = useSelector(selectNavTokenContext)
    const menuItemsMain = getMenuItemsMain(token)
    const menuItemsExternal = getMenuItemsExternal(token)
    const menuItemsOther = getMenuItemsOther(token)

    const handleClose = () => {
        localStorage.setItem("navOpen", false)
        dispatch(setNavOpen(false))
    }

    useEffect(()=>{
        setCurrentRoute(`/${location.pathname.split("/")[1]}`)
    }, [])

    if (isSmall || isHidden){
        return null
    }

    return (
        <div>
            <Drawer
                variant="persistent"
                open={open}
                anchor="left"
                sx={{
                    width: "100px",
                    '& .MuiDrawer-paper': {
                        top: "20px",
                        bottom: "20px",
                        height: "auto",
                        overflow: "visible",
                        borderRadius: "0px 20px 20px 0px",
                        border: "none",
                        width: "100px",
                        boxSizing: 'border-box',
                        padding: "16px 0",
                    },
                }}
            >
                <Grid
                    container
                    alignItems="center"
                    flexDirection="column"
                    justifyContent="space-between"
                    wrap="nowrap"
                    height="100%"
                >
                    <Grid
                        container
                        alignItems="center"
                        flexDirection="column"
                        minHeight={0}
                        wrap="nowrap"
                    >
                        <Grid item marginBottom="40px">
                            <LogoButton />
                        </Grid>

                        <Grid item flexShrink={1} sx={{overflow: "auto", scrollbarGutter: "stable both-edges", textAlign: "center"}}>
                            {menuItemsMain.map(item=>
                                <NavItem 
                                    key={item.url}
                                    name={item.title} 
                                    icon={item.icon} 
                                    route={item.url} 
                                    currentRoute={currentRoute} 
                                    setCurrentRoute={setCurrentRoute}
                                    theme={theme}
                                />
                            )}
                        </Grid>
                    </Grid>

                    <Grid item textAlign="center">
                        <div style={{width: "56px", marginBottom: "10px", height: "1px", backgroundColor: theme.palette.text.primary}}></div>
                        <MenuButton icon={PendingIcon} menuItems={menuItemsExternal} theme={theme} />
                        <MenuButton icon={SupportIcon} menuItems={menuItemsOther} theme={theme} />
                    </Grid>
                </Grid>

                <div
                    onClick={handleClose}
                    style={{
                        cursor: "pointer",
                        width: 15, height: 25,
                        background: theme.palette.background.paper,
                        position: "absolute",
                        right: 0,
                        bottom: "68px",
                        transform: "translate(100%, -100%)",
                        borderRadius: "0px 5px 5px 0px",
                    }}
                >
                    <div style={{
                        transform: "translate(-20%, 0%)",
                    }}>
                        <ArrowLeftRoundedIcon/>
                    </div>
                </div>
            </Drawer>
            
            <NavOpenButton/>
        </div>      
    )
}


const NavOpenButton = () => {
    const dispatch = useDispatch()
    const theme = useTheme()
    const handleOpen = () => {
        localStorage.setItem("navOpen", true)
        dispatch(setNavOpen(true))
    }
    return (
        <div 
            onClick={handleOpen}
            style={{
                top: "97.5vh",
                cursor: "pointer",
                width: 15, height: 25, 
                background: theme.palette.background.paper, 
                position: "fixed",
                left: 0,
                zIndex: 2,
                transform: "translate(0%, -118px)",
                borderRadius: "0px 5px 5px 0px",
            }}
        >
            <div style={{
                position: "absolute",
                transform: "translate(-20%, 0%)",
            }}>
                <ArrowRightRoundedIcon/>
            </div>
        </div>
    )
}



const NavItem = ({name, icon, route, currentRoute, setCurrentRoute, theme}) => {
    const highlighted = Boolean(currentRoute == route)
    const handlePageGoTo = useHandlePageGoTo()
    const handleButtonClick = (event) => {
        event.preventDefault()
        handlePageGoTo(route)
        setCurrentRoute(route)
    }

    return (
        <div style={{paddingBottom: 6}}>
            <Link href={route} sx={{ textDecoration: "none", color: theme.palette.text.primary }}>
                <Button onClick={handleButtonClick} variant="default">
                    <Grid container>
                        <Grid container width={"100%"} height={30} alignItems={"center"} justifyContent={"center"}>
                            <HighlightedIcon icon={icon} theme={theme} highlighted={highlighted} />
                        </Grid>
                        <Grid item width={"100%"}>
                            <span style={{fontSize: 12, margin: 0, padding: 0}}>
                                {name}
                            </span>
                        </Grid>
                    </Grid>
                </Button>
            </Link>
        </div>
    )
}

const MenuButton = ({icon, menuItems, theme}) => {
    const Icon = icon
    const handlePageGoTo = useHandlePageGoTo()
    const buttonRef = useRef()
    const [open, setOpen] = useState(false)

    const handleMenuClick = (url) => (event) => {
        event.preventDefault()
        handlePageGoTo(url)
        setOpen(false)
    }

    if (menuItems.length == 0) {
        return null
    }

    return (
        <div style={{width: "100%"}}>
            <IconButton ref={buttonRef} onClick={()=>{setOpen(true)}}>
                <Icon />
            </IconButton>
            <Menu
                PaperProps={{style: {
                    boxShadow: "0px 0px 30px 3px rgba(0,0,0,0.2)"
                }}}
                anchorEl={buttonRef.current}
                open={open}
                onClose={()=>{setOpen(false)}}
                elevation={0}
                anchorOrigin={{vertical: 'bottom',horizontal: 'right'}}
                transformOrigin={{vertical: 'bottom',horizontal: 'left'}}
            >
                {menuItems.map(item=>
                    <Link key={item.title} href={item.url} sx={{ textDecoration: "none", color: theme.palette.text.primary }}>
                        <MenuItem onClick={handleMenuClick(item.url)}>
                            {item.title}
                        </MenuItem>
                    </Link>
                )}
            </Menu>
        </div>
    )
}
