import { Button, Grid, IconButton, colors, useTheme } from "@mui/material"
import { useRef, useState } from "react"
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteOutlineRoundedIcon from '@mui/icons-material/DeleteOutlineRounded';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

export function ProjectProfileImageUploader({images, setImages, setFlaggedImageId}){
    const fileUploaderRef = useRef()

    const handleFilesUpload = () => {
        const newImageData = []
        const numFiles = fileUploaderRef.current.files.length
        for(var i in fileUploaderRef.current.files){
            const file = fileUploaderRef.current.files[i]
            const fileReader = new FileReader()
            fileReader.onload = (e) => {
                const dataURL = fileReader.result
                newImageData.push({
                    dataURL: dataURL,
                    is_flagged: false,
                    file: file
                })
                if (newImageData.length == numFiles){
                    setImages([...images, ...newImageData])
                }
            }
            if (typeof file == "object"){
                fileReader.readAsDataURL(file)
            }
        }
    }

    const RemoveImage = (imageToRemove) => {
        if (imageToRemove.id){
            setImages(images.map(image =>{
                return image.id != imageToRemove.id ? image : {...image, markedDelete: true}
            }))
        }else{
            setImages(images.filter(image => image.dataURL != imageToRemove.dataURL))
        }
    }

    const FlagImage = (imageToFlag) => {
        setImages(images.map(image=>{
            let isCurrentImage = false
            if (image.id){
                isCurrentImage = image.id == imageToFlag.id
                setFlaggedImageId(imageToFlag.id)
            }else{
                isCurrentImage = image.dataURL == imageToFlag.dataURL
            }
            return isCurrentImage ? {...image, is_flagged: true} : {...image, is_flagged: false}
        }))
    }

    return (
        <>
            <Grid container spacing={2}>
                {images.map((image, index)=>
                    <ImageGridItem 
                        key={index} image={image} 
                        RemoveImage={RemoveImage}
                        FlagImage={FlagImage}
                    />
                )}
                <AddButtonGridItem fileUploaderRef={fileUploaderRef}/>
            </Grid>
            <input 
                multiple type="file" ref={fileUploaderRef} 
                hidden onChange={handleFilesUpload} 
                accept="image/png, image/jpeg, image/webp"
            />
        </>
    )
}

const ImageGridItem = ({image, RemoveImage, FlagImage}) => {
    const url = image.id ? (image.processed ? image.url_thumb : image.url_orig) : image.dataURL
    const theme = useTheme()
    const StarIcon = image.is_flagged ? StarRoundedIcon : StarBorderRoundedIcon
    const iconButtonStyle = {
        boxShadow: "0px 0px 5px #00000055",
        padding: 0.1, 
        background: theme.palette.background.paper,
        "&:hover": {
            "&.MuiButtonBase-root":{
                background: theme.palette.primary.light,
            }
        }
    }
    const handleDelete = () => {
        RemoveImage(image)
    }
    const handleFlagging = () => {
        FlagImage(image)
    }
    if (image.markedDelete){
        return
    }
    return (
        <Grid item>
            <div style={{position: "relative"}}>
                <div style={{position: "absolute", top: -10, right: -10, width: 20}}>
                    <IconButton onClick={handleFlagging} size="small" sx={{
                        ...iconButtonStyle,
                        background: image.is_flagged ? theme.palette.primary.main : theme.palette.background.paper,
                        color: image.is_flagged ? theme.palette.secondary.main : theme.palette.grey 
                    }}>
                        <StarIcon fontSize="tiny"/>
                    </IconButton>
                    <IconButton onClick={handleDelete} size="small" sx={iconButtonStyle}>
                        <DeleteOutlineRoundedIcon fontSize="tiny"/>
                    </IconButton>
                </div>
                <img src={url} style={{width: 100, height: 100, overflow: "hidden", borderRadius: 8, objectFit: "cover", boxShadow: "0px 0px 5px #00000055"}}/>
            </div>
        </Grid>
    )
}

const AddButtonGridItem = ({fileUploaderRef}) => {
    return (
        <Grid item>
            <div style={{width: 100, height: 100}}>
                <Button onClick={()=>{fileUploaderRef.current.click()}} startIcon={<AddRoundedIcon />} variant="contained" color="secondary" fullWidth sx={{height: "100%", borderRadius: 2}}>
                    Add
                </Button>
            </div>
        </Grid>
    )
}