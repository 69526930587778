import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";

export default function ProjectLoading() {
    return (
        <div>
            <Skeleton variant="rounded" height={170} sx={{marginTop: "20px"}} />

            <Divider sx={{marginTop: "40px", marginBottom: "90px"}}/>

            <Grid container spacing={2}>
                <Grid item sm={12}><Skeleton variant="rounded" height={100} /></Grid>
                <Grid item sm={4}><Skeleton variant="rounded" height={300} /></Grid>
                <Grid item sm={4}><Skeleton variant="rounded" height={300} /></Grid>
                <Grid item sm={4}><Skeleton variant="rounded" height={300} /></Grid>
            </Grid>
        </div>
    )
}