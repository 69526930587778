import { TextField, Grid, ListItem, ListItemIcon, ListItemText, useTheme } from "@mui/material"
import FavoriteRounded from "@mui/icons-material/FavoriteRounded"
import FavoriteBorderRounded from "@mui/icons-material/FavoriteBorderRounded"
import { request } from "@/Api"
import { useNavigate } from "react-router-dom"
import { useState } from "react"
import { Chip } from "@mui/material"

export const ProfileListItem = (props) => {
    const theme = useTheme()
    const {icon, text, secondary, fontSize} = props
    if (!text){
        return
    }
    const specialPrimaryColor = theme.palette.mode == "light" ? "#464646" : "#9E9E9E"
    return (
        <Grid item>
            <ListItem {...props} sx={{padding: 0}}>
                <ListItemIcon sx={{minWidth: 0, "&&": { color: secondary ? theme.palette.text.secondary : specialPrimaryColor }}}>
                    {icon}
                </ListItemIcon>
                <ListItemText>
                    <p style={{
                        margin: 0,
                        paddingLeft: `${fontSize ? "0.6rem" : "0.2rem"}`,
                        fontSize: fontSize || 12, 
                        lineHeight: "25px", 
                        color: secondary ? theme.palette.text.secondary : specialPrimaryColor
                    }}>{text}</p>
                </ListItemText>
            </ListItem>
        </Grid>
    )
}

export const ProfileListItemLikeButton = ({likableEntity, setLikableEntity, modelType}) => {
    const [ hovering, setHovering ] = useState()
    const navigate = useNavigate()
    const HeartIcon = likableEntity.liked ? FavoriteRounded : FavoriteBorderRounded
    const handleLike = () => {
        request.post("like-button", {
            model_type: modelType,
            model_id: likableEntity.id
        })
        .then(response=>{
            if (!response.data){
                navigate("/login")
            }
            const liked = !likableEntity.liked
            const newLikesCount = likableEntity.likes_count + (liked ? +1 : -1)
            setLikableEntity({...likableEntity, liked: liked, likes_count: newLikesCount})
        })
    }
    
    return (
        <ProfileListItem 
            style={{cursor: "pointer"}}
            onClick={handleLike}
            icon={
                <div style={{position: "relative", width: 18, height: 18}}>
                    <FavoriteRounded sx={{fontSize: 18, opacity: hovering ? 0.5 : 0, transition: "opacity 0.3s", position: "absolute"}} color="primary"/>
                    <HeartIcon
                        onMouseOver={()=>{setHovering(true)}}
                        onMouseLeave={()=>{setHovering(false)}}
                        onClick={()=>{setHovering(false)}}
                        sx={{
                        fontSize: 18, transition: "all 0.3s cubic-bezier(0.175, 0.185, 0.32, 3.5)", 
                        position: "absolute", top: 0, transform: `scale(${hovering ? 1.15 : 1})`}}
                        color="primary"
                    />
                </div>
            } 
            text={`(${likableEntity.likes_count})`}
        />
    )
}

export function CustomChip(props){
    const { icon, text, chipColorInherit } = props
    const theme = useTheme()
    const Icon = icon
    return (
        <Chip 
            sx={{fontSize: 11, background: !chipColorInherit && theme.palette.background.default}} 
            icon={<Icon fontSize="small" sx={{paddingLeft: 0.5, "&&": { color: theme.palette.text.primary + "88" }}} />} 
            label={<span style={{color: theme.palette.text.primary + "99" }}>{text}</span>} 
        />
    )
}