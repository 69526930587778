import styled from "styled-components"

export const Subtitle = styled.div`
    display: flex;
    align-items: center;
    margin: 6px 0;

    h4 {
        color: grey;
        margin: 0 0 0 12px;
        font-weight: normal;
    }
`