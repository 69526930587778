import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogContentText from "@mui/material/DialogContentText"
import DialogTitle from "@mui/material/DialogTitle"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import { ThemeProvider } from "@mui/material/styles"

import { baseTheme, darkTheme } from "@/themes"

const checklistItems = [
    "Final completetion report details in the Project Central",
    "Saved all final deliverables to the Project folder",
    "Ensured all invoices & receipts are saved in the Project Finances folder",
    "Obtained P&L from Finance",
    "Actualized the bid sheet",
]

export default function WrapDialog(props) {
    const { open, handleClose } = props
    const themeMode = localStorage.getItem("themeMode") || "light"

    return (
        <ThemeProvider theme={themeMode == "dark" ? darkTheme : baseTheme}>
            <Dialog
                open={open}
                onClose={() => handleClose(false)}
            >
                <DialogTitle>
                    Let's Wrap This Up
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        By clicking submit you are confirming that you have completed the following steps:
                    </DialogContentText>

                    <List dense sx={{ marginTop: "12px" }}>
                        {checklistItems.map((item, index) => (
                            <ListItem key={index}>
                                <ListItemIcon>
                                    <CheckCircleIcon color="success" />
                                </ListItemIcon>
                                <ListItemText primary={item} />
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="secondary" onClick={() => handleClose(false)}>Cancel</Button>
                    <Button variant="contained" color="success" onClick={() => handleClose(true)}>Submit</Button>
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
