import Fab from '@mui/material/Fab';
import AddIcon from '@mui/icons-material/Add';

export default function EntityCreateButton(props){
    const {setPopupOpen} = props
    const handleEntityCreateButton = () => {
        setPopupOpen(true)
    }
    return(
        <Fab onClick={handleEntityCreateButton} color="primary" aria-label="add">
            <AddIcon />
        </Fab>
    )
}