import { CustomBox } from "@/common/StyledComponents";
import { IconButton, MobileStepper, Modal } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { clampNumber } from "@/utils";
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import KeyboardArrowRightRoundedIcon from '@mui/icons-material/KeyboardArrowRightRounded';

// TODO: make it generic

export default function SwippableView({images, viewOpen, close, defaultIndex}){
    const [ currIndex, setCurrIndex ] = useState(0)
    const scrollRef = useRef()
    const closeButtonStyle = {
        background: "#ffffff44",
        "&:hover": {
            "&.MuiButtonBase-root":{
                background: "#ffffff66",
            }
        }
    }

    useEffect(()=>{
        if (viewOpen && defaultIndex != null){
            handleGoToImage(defaultIndex)
        }
    }, [viewOpen])


    useEffect(()=>{
        console.log(defaultIndex)
    }, [scrollRef])

    const handleScroll = (e) => {
        clearTimeout(e.target.scrollTimeout)
        e.target.scrollTimeout = setTimeout(()=>{
            const { scrollLeft } = scrollRef.current;
            setCurrIndex(
                clampNumber(
                    0, Math.ceil(scrollLeft / e.target.offsetWidth), images.length - 1
                )
            )
        }, 150)
    }

    const handleGoToImage = (index) => {
        const newIndex = clampNumber(index, 0, images.length - 1)
        setCurrIndex(newIndex)
        if (scrollRef.current){
            scrollRef.current.scrollTo({
                left: newIndex * scrollRef.current.offsetWidth,
                behavior: "smooth",
            })
        }
    }

    return (
        <Modal keepMounted open={viewOpen} onClose={close}>
            <CustomBox style={{
                outline: "none",
                width: "80%", height: "90%",
                maxHeight: "100vh", background: "#00000044", 
                boxShadow: "0 0 100px 100px #00000044"}}
            >
                <IconButton onClick={close} sx={{position: "absolute", top: 30, right: 30, ...closeButtonStyle}}>
                    <CloseRoundedIcon />
                </IconButton>
                <IconButton disabled={currIndex == 0} onClick={()=>{handleGoToImage(currIndex - 1)}} sx={{position: "absolute", top: "50%", left: 30, ...closeButtonStyle}}>
                    <KeyboardArrowLeftRoundedIcon />
                </IconButton>
                <IconButton disabled={currIndex == images.length - 1}  onClick={()=>{handleGoToImage(currIndex + 1)}} sx={{position: "absolute", top: "50%", right: 30, ...closeButtonStyle}}>
                    <KeyboardArrowRightRoundedIcon />
                </IconButton>
                <div 
                    ref={scrollRef}
                    onScroll={handleScroll}
                    style={{
                        overflow: "scroll",
                        scrollSnapType: "x mandatory",
                        display: "flex",
                        height: "100%",
                        marginBottom: 10
                    }}
                >
                    {images.map((image,index)=>
                        <div key={index} style={{
                            scrollSnapAlign: "center",
                            textAlign: "center",
                            flex: "none",
                            width: "100%",
                            objectFit: "cover"
                        }}>
                            <img 
                                src={image.url_orig} 
                                style={{
                                    borderRadius: 15, objectFit: "contain",
                                    height: "92%", width: "92%"
                                }}    
                            />
                        </div>
                    )}
                </div>

                <div style={{width: "100%", display: "flex", justifyContent: "center", position: "absolute", bottom: 0, transform: "translateY(-150%)"}}>
                    <MobileStepper
                        steps={images.length}
                        position="static"
                        activeStep={currIndex}
                        sx={{width: "fit-content", background: "#ffffff55", borderRadius: 10}}
                    />
                </div>
            </CustomBox>
        </Modal>
    )
}