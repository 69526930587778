import { useEffect, useState, useRef } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import LinearProgress from "@mui/material/LinearProgress"

import { request } from "@/Api"
import { setToken, selectIsOrganizationInitializing } from "@/auth/authSlice"
import { InitScreen } from "@/auth/StyledComponents"
import "$/makers-interactive-logo/index"

export default function OrganizationInit() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    
    const [ error, setError ] = useState(null)
    const isOrganizationInitializing = useSelector(selectIsOrganizationInitializing)
    const timeoutRef = useRef(null)

    const checkIfStillInitializing = () => {
        request.post(`/refresh-token`, {}).then(response => { 
            dispatch(setToken(response.data))
            timeoutRef.current = setTimeout(checkIfStillInitializing, 3000)
        }).catch(e => {
            setError("Failed to setup account.")
        })
    }

    useEffect(() => {
        if (!isOrganizationInitializing) {
            clearTimeout(timeoutRef.current)

            navigate("/set-up")
        } else {
            checkIfStillInitializing()
        }
    }, [isOrganizationInitializing])

    return (
        <InitScreen>
            <makers-logo style={{display: "block", width: "200px", textAlign: "center", position: "absolute", top: "100px"}} />
            { error ? (
                <>
                    <p>{error}</p>
                    <p>Please contact us at central@makers.to</p>
                </>
            ) : (
                <p>Initializing account <LinearProgress color="primary" sx={{marginTop: "12px"}} /></p>
            ) }
        </InitScreen>
    )
}
