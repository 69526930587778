import { useEffect, useState } from "react"

import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import Paper from "@mui/material/Paper"
import { Grid, Card, CardContent, Link } from "@mui/material"

import { request } from "@/Api"
import { FormattedCurrency } from "@/common/StyledComponents"

export default function Stats(){   
    const [stats, setStats] = useState({})
    
    const getStats = () => {
        request.get("admin/stats").then(response => {
            setStats(response.data)
        })
    }

    useEffect(()=>{
        getStats()
    }, [])

    if (Object.keys(stats).length == 0) {
        return null
    }

    return (
        <>
            <Grid container spacing={2}>
                {Object.entries(stats.totals).map(([group, data]) => (
                    <Grid item key={group} flexGrow={1}>
                        <Card>
                            <CardContent>
                                <h4 style={{textTransform: "capitalize"}}>{group.replace(/_/g, " ")}</h4>
                                <ul style={{lineHeight: "32px"}}>
                                    {Object.entries(data).map(([key, value]) => (
                                        <li key={`${group}_${key}`}>{`${key.replace(/_/g, " ")}:`} {value}</li>
                                    ))}
                                </ul>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            <h4>Cancelled projects during the last year</h4>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Docket</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Cancelled</TableCell>
                            <TableCell align="right">Internal Actuals</TableCell>
                            <TableCell align="right">Bills Sent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {stats.cancelled_projects.map((project) => (
                            <TableRow
                                key={project.id}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="project">{project.docket}</TableCell>
                                <TableCell>
                                    <Link href={`/projects/${project.id}`} color="inherit">{project.name}</Link>
                                </TableCell>
                                <TableCell>{project.cancelled_at}</TableCell>
                                <TableCell align="right">
                                    <FormattedCurrency currency="CAD" number={project.float_internal_actual_base || 0} />
                                </TableCell>
                                <TableCell align="right">
                                    <FormattedCurrency currency="CAD" number={project.total_sent_bills || 0} />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    )
}
