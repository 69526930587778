import { useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import { useTheme } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import Alert from "@mui/material/Alert"
import Grid from "@mui/material/Grid"
import Link from "@mui/material/Link"
import LoadingButton from '@mui/lab/LoadingButton'
import Snackbar from '@mui/material/Snackbar'
import TextField from "@mui/material/TextField"

import FileUploadIcon from "@mui/icons-material/FileUpload"

import { request } from "@/Api"
import { AuthContainer, AuthSection, AuthForm, StepCount, Heading, SubHeading, FullImage, AuthStepsContainer, AuthSteps, AuthStep, AuthStepButton, AuthCarouselContainer, AuthCarousel, Footer, AuthUpload } from "@/auth/StyledComponents"
import { GlobalStyle } from "@/themes"
import ApiAutocomplete from "@/common/ApiAutocomplete"
import { selectPersonProfileId, setToken } from "@/auth/authSlice"

import "$/makers-interactive-logo/index"


export default function SetUp() {
    const theme = useTheme()
    const navigate = useNavigate()
    const fileHolder = useRef()
    const dispatch = useDispatch()

    const [ loading, setLoading ] = useState(false)
    const [ stepIndex, setStepIndex ] = useState(0)
    const [ title, setTitle ] = useState("")
    const [ location, setLocation ] = useState("")
    const [ headline, setHeadline ] = useState("")
    const [ file, setFile ] = useState(null)
    const [ uploadActive, setUploadActive ] = useState(false)
    const [ bio, setBio ] = useState("")
    const [ reel, setReel ] = useState("")
    const [ error, setError ] = useState(null)

    const themeMode = localStorage.getItem("themeMode") || "light"
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const personProfileId = useSelector(selectPersonProfileId)

    const steps = [
        {
            "heading": "General information",
            "image": "/auth/4.jpg",
            "hideSkip": true,
            "renderFields": () => (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            label="Job Title"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={title}
                            onChange={event => setTitle(event.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item>
                        <ApiAutocomplete
                            fullOptionData
                            value={location}
                            apiRoute={"locations"}
                            fields={["full_name"]}
                            onChange={(event, value) => setLocation(value)}
                            onInputResponseLimit={30}
                            label="Location*"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                                required: true
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Headline"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={headline}
                            onChange={event => setHeadline(event.target.value)}
                        />
                    </Grid>
                </Grid>
            ),
            "getData": () => ({
                "title": title,
                "location": location,
                "headline": headline,
            }),
        },
        {
            "heading": "Upload a profile picture",
            "image": "/auth/10.jpg",
            "renderFields": () => (
                <Grid item>
                    <AuthUpload
                        onDragLeave={toggleUploadActive} 
                        onDragOver={toggleUploadActive} 
                        onDrop={dropFile}
                        onClick={() => fileHolder.current.click()}
                        active={uploadActive}
                    >
                        <FileUploadIcon />
                        <p>{ file ? file.name : "Click or drag and drop to upload your file" }</p>
                        <input
                            accept="image/png, image/jpeg, image/webp"
                            ref={fileHolder}
                            style={{display: "none"}}
                            type="file"
                            onChange={selectFile}
                        />
                    </AuthUpload>
                </Grid>
            ),
            "getData": () => false,
        },
        {
            "heading": "Background information",
            "image": "/auth/2.jpg",
            "renderFields": () => (
                <Grid container direction="column" spacing={2}>
                    <Grid item>
                        <TextField
                            label="Bio"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={bio}
                            onChange={event => setBio(event.target.value)}
                            multiline
                            minRows={4}
                        />
                    </Grid>
                    <Grid item>
                        <TextField
                            label="Reel URL (YouTube or Vimeo)"
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                            value={reel}
                            onChange={event => setReel(event.target.value)}
                        />
                    </Grid>
                </Grid>
            ),
            "getData": () => ({
                "description": bio,
                "reel_url": reel,
            }),
        },
    ]

    const step = steps[stepIndex]
    const isFirst = stepIndex == 0
    const isLast = stepIndex == steps.length - 1

    const toggleUploadActive = (event) => {
        event.preventDefault()
        setUploadActive(!uploadActive)
    }

    const dropFile = (event) => {
        event.preventDefault()
    
        let file = event.dataTransfer.files[0]
        if (event.dataTransfer.items) {
            const item = event.dataTransfer.items[0]
            if (item.kind !== "file") {
                return
            }
            file = item.getAsFile()
        }       

        setFile(file)
    }

    const selectFile = () => {
        const file = fileHolder.current.files[0]
        setFile(file)
    }

    const submitStep = (event) => {
        event.preventDefault()

        setError(null)
        setLoading(true)

        const data = step.getData()

        if (data) {
            request.patch(`/person-profiles`, data)
            .then(nextStep)
            .catch(e => setError("Failed to save profile data, try again on your profile page."))
            .finally(() => setLoading(false))
        } else if (file) {            
            const formData = new FormData()
            formData.append("file", file, file.name)
            formData.append("person_profile_id", personProfileId)
            request.post(`person-profiles/${personProfileId}/image`, formData)
            .then(nextStep)
            .catch(e =>{
                setError("Failed to upload image")
            })
            .finally(() => setLoading(false))
        } else {
            setError("Please select a file.")
            setLoading(false)
        }
    }

    const prevStep = () => {
        if (isFirst) {
            return
        }

        setStepIndex(stepIndex - 1)
    }

    const nextStep = () => {
        if (isFirst) {
            request.post(`legal-entities/default`)
            setStepIndex(stepIndex + 1)
        } else if (isLast) {
            request.post(`/refresh-token`, {}).then(response => { 
                dispatch(setToken(response.data))
                navigate("/profile")
            }).catch(e => {
                setError("There was an error initializing your profile.")
            })
        } else {
            setStepIndex(stepIndex + 1)
        }
    }

    const handleCloseError = () => {
        setError(null)
    }

    return (
        <AuthContainer isMobile={isMobile}>
            <GlobalStyle theme={themeMode} />
            
            <AuthSection isMobile={isMobile}>
                <makers-logo style={{display: "block", width: "150px"}} />
                
                <AuthForm onSubmit={submitStep} isMobile={isMobile}>
                    <Grid container direction="column" spacing={5}>
                        <Grid item container direction="column" spacing={2}>
                            <Grid item>
                                <StepCount color={theme.palette.text.secondary}>{stepIndex + 1} / {steps.length}</StepCount>
                            </Grid>
                            <Grid item>
                                <Heading>Set up your profile</Heading>
                            </Grid>
                            <Grid item>
                                <SubHeading>{step.heading}</SubHeading>
                            </Grid>
                        </Grid>

                        <Grid item>
                            <AuthStepsContainer>
                                <AuthSteps count={steps.length} current={stepIndex}>
                                    {steps.map((step, index) => (
                                        <AuthStep key={index}>
                                            {step.renderFields()}

                                            <AuthStepButton>
                                                <LoadingButton
                                                    type="submit"
                                                    variant="contained"
                                                    size="large"
                                                    color="primary"
                                                    sx={{color: "white"}}
                                                    fullWidth
                                                    loading={loading}
                                                >
                                                    {index == steps.length - 1 ? "Finish" : "Next"}
                                                </LoadingButton>
                                            </AuthStepButton>
                                        </AuthStep>
                                    ))}
                                </AuthSteps>
                            </AuthStepsContainer>
                        </Grid>               
                    </Grid>
                </AuthForm>

                <Footer flexEnd={stepIndex < 1}>
                    {!isFirst ? (
                        <Link color="inherit" onClick={prevStep}>Back</Link>
                    ) : null}
                    {!isLast && !step.hideSkip ? (
                        <Link color="inherit" onClick={nextStep}>Skip</Link>
                    ) : null}
                </Footer>
            </AuthSection>

            {isMobile ? null : (
                <AuthCarouselContainer>
                    <AuthCarousel count={steps.length} current={stepIndex}>
                        {steps.map((step, index) => (
                            <FullImage key={index} url={step.image} />
                        ))}
                    </AuthCarousel>
                </AuthCarouselContainer>
            )}

            <Snackbar
                anchorOrigin={{vertical: "bottom", horizontal: "center" }}
                open={error != null}
                onClose={handleCloseError}
            >
                <Alert
                    variant="filled"
                    severity="error"
                    sx={{ width: '100%' }}
                    onClose={handleCloseError}
                >
                    { error }
                </Alert>
            </Snackbar>
        </AuthContainer>
    )
}
