import { styled } from '@mui/material/styles'
import Switch from '@mui/material/Switch'

const ThemeSwitch = styled(Switch)(({ theme }) => ({
    width: 62,
    height: 34,
    padding: 7,
    '& .MuiSwitch-switchBase': {
        margin: 1,
        padding: 0,
        transform: 'translateX(6px)',
        '&.Mui-checked': {
            color: '#fff',
            transform: 'translateX(22px)',
            '& .MuiSwitch-thumb:before': {
                backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 24"><path fill="${encodeURIComponent(
                '#ffffff',
                )}" d="M12.0017 24.0003C8.82016 23.9969 5.76991 22.7315 3.52022 20.4818C1.27053 18.2321 0.00514473 15.1819 0.00170365 12.0003C-0.151296 3.04432 10.0327 -2.98468 17.7927 1.50932L19.3457 2.37132L17.8027 3.25132C11.1027 6.93932 11.5927 17.1213 18.6027 20.1573L20.2237 20.8883L18.7567 21.8943C16.7701 23.2638 14.4146 23.9981 12.0017 24.0003V24.0003ZM12.0017 2.00032C9.35043 2.00323 6.80859 3.05774 4.93385 4.93247C3.05912 6.8072 2.00462 9.34905 2.0017 12.0003C1.8467 19.1173 9.7647 24.2003 16.1567 21.0823C14.6602 20.078 13.4197 18.7373 12.5344 17.1675C11.6491 15.5977 11.1437 13.8424 11.0585 12.0422C10.9734 10.242 11.3109 8.44689 12.0441 6.80054C12.7773 5.15419 13.8857 3.70237 15.2807 2.56132C14.2279 2.18777 13.1188 1.998 12.0017 2.00032V2.00032Z"/></svg>')`,
            },
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#ffffff' : '#232323',
        width: 32,
        height: 32,
        '&:before': {
            content: "''",
            position: 'absolute',
            width: '100%',
            height: '100%',
            left: 0,
            top: 0,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center',
            backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
            '#232323',
            )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
        },
    },
    '& .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
        borderRadius: 20 / 2,
    },
}))

export default ThemeSwitch
