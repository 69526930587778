import React from "react"
import ReactDOM from "react-dom/client"
import { Helmet } from "react-helmet"
import { Provider } from 'react-redux'

import App from "@/App"
import store from "@/store"
import { ENV, GOOGLE_ANALYTICS_ID, LUCKY_ORANGE_ID } from "@/config"

ReactDOM.createRoot(document.getElementById("root")).render(
    <>
        {ENV == "prod" ? (
            <Helmet>                
                {/* Google tag (gtag.js) */ }
                <script async src={`https://www.googletagmanager.com/gtag/js?id=${GOOGLE_ANALYTICS_ID}`}></script>
                <script>
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', "${GOOGLE_ANALYTICS_ID}");
                `}
                </script>
            
                {/* Lucky Orange */}
                <script async defer src={`https://tools.luckyorange.com/core/lo.js?site-id=${LUCKY_ORANGE_ID}`}></script>
            </Helmet>
        ) : null}
        
        <Provider store={store}>
            <App />
        </Provider>
    </>
)
