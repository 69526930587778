import { useSelector, useDispatch } from 'react-redux'
import { selectBidPopupStatus, closeBidPopup, selectProjectPopupOpen, closeProjectPopup } from '@/nav/navSlice'

import Modal from "@mui/material/Modal"

import BidPopup from "@/bids/BidPopup"
import CreateProjectPopup from "@/projects/CreateProjectPopup"

function Popups() {
    const dispatch = useDispatch()
    const bidPopupOpen = useSelector(selectBidPopupStatus)
    const projectPopupOpen = useSelector(selectProjectPopupOpen)

    // Note: empty React fragments are needed to wrap children, otherwise Modal shows errors
    return (
        <>
            <Modal
                sx={{overflow: "auto"}}
                open={bidPopupOpen.open}
                onClose={() => dispatch(closeBidPopup())}
            >
                <><BidPopup /></>
            </Modal>

            <Modal
                open={projectPopupOpen}
                onClose={() => dispatch(closeProjectPopup())}
            >
                <><CreateProjectPopup /></>
            </Modal>
        </>
    )
}

export default Popups
