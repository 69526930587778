import { Accordion, AccordionDetails, AccordionSummary, Button, Chip, Grid, useTheme } from "@mui/material";
import { ActualLine, ActualHeaderLine } from "./ActualLine";
import { ExpandMoreRounded } from "@mui/icons-material";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { openLinePopup, selectSearchedLineIdsSet } from "./actualSlice";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import { useSelector } from "react-redux";

export default function ActualsGroup({group, registerSetExpand, selectable}){
    const theme = useTheme()
    const dispatch = useDispatch()
    const isRoot = (group.line_items_grouped != null) || group.isCustomLineGroup
    const children = group.line_items_grouped || group.children || []
    const hasLines = group.line_items && group.line_items.length > 0
    const isLeaf = hasLines && !group.isCustomLineGroup
    const [expanded, setExpanded] = useState(false)

    const searchedLineIdsSet = useSelector(selectSearchedLineIdsSet)

    let groupStack = group.line_items_grouped ? [...group.line_items_grouped] : [group]
    let hasAnyNestedLine = false
    while (groupStack.length > 0) {
        const currGroup = groupStack.pop()
        const lineBeingSearched = (line) => line.actual_line != null && (!searchedLineIdsSet ? true : line.actual_line.id in searchedLineIdsSet)
        hasAnyNestedLine = currGroup.line_items.some(lineBeingSearched)
        if (hasAnyNestedLine){
            break
        }
        groupStack = groupStack.concat(currGroup.children)
    }
    if (!hasAnyNestedLine) {
        return
    }

    registerSetExpand(setExpanded)

    return (
    <Accordion
        onChange={()=>{setExpanded(!expanded)}}
        expanded={expanded}
        sx={{
            fontSize: 14,
        }}
    >
        <AccordionSummary 
            expandIcon={<ExpandMoreRounded />} 
            sx={{
                border: "none",
                background: (isRoot || isLeaf) && theme.palette.background.default,
                borderRadius: 1, 
            }}
        >
            <Grid container alignItems={"center"} spacing={3.5}>
                <Grid item>
                    <span style={{fontSize: isLeaf && 12, marginLeft: isLeaf && 10}}>
                        {group.name}
                    </span>
                </Grid>
                {(isRoot && group.bid_type) &&
                <Grid item>
                    <Chip label={group.bid_type}/>
                </Grid>
                }
            </Grid>
        </AccordionSummary>
        <AccordionDetails sx={{
            overflow: "auto",
            background: (isRoot || isLeaf) && theme.palette.background.default
        }}>
            {children.map((child, index)=>
                <ActualsGroup 
                    key={index} 
                    group={child}
                    registerSetExpand={registerSetExpand}
                    selectable={selectable}
                />
            )}
            {hasLines && 
            <>
                <ActualHeaderLine 
                    isCustom={group.isCustomLineGroup}
                    selectable={selectable}
                />
                {group.line_items.map((line, index)=>
                    line.actual_line && 
                    <ActualLine 
                        selectable={selectable}
                        key={index} 
                        lineId={line.actual_line.id}
                        isCustom={group.isCustomLineGroup}
                    />
                )}
            </>
            }
            {group.isCustomLineGroup &&
                <Button 
                    sx={{margin: 1}}
                    onClick={()=>{dispatch(openLinePopup())}}
                    variant="outlined" color={theme.palette.mode=="dark" ? "secondary" : "info"} 
                    startIcon={<AddCircleOutlineRoundedIcon/>}
                >
                    Add Actual Line
                </Button>
            }
        </AccordionDetails>
    </Accordion>
    )
}
