import { useEffect, useState } from 'react'
import { useNavigate } from "react-router-dom"
import axios from "axios"

import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'

import { API_BASE_URL } from "@/config"

const request = axios.create({
    baseURL: API_BASE_URL,
    paramsSerializer: {indexes: null},
})

const requestSilent = axios.create({
    baseURL: API_BASE_URL,
    paramsSerializer: {indexes: null},
})

function setAuthHeader(token) {
    request.defaults.headers.common['Authorization'] = token
    requestSilent.defaults.headers.common['Authorization'] = token
}

function Api() {
    const navigate = useNavigate()
    const [message, setMessage] = useState("")

    useEffect(() => {
        request.interceptors.response.use((response) => response, (error) => {
            const statusCode = error.response ? error.response.status : null
            if (statusCode === 401) {
                setMessage("Your session has expired, please login.")
                navigate("/logout")
                return
            }
            if (statusCode === 403) {
                setMessage("You do not have access to this resource.")
            }
            if (statusCode === 404) {
                setMessage("The requested resource was not found.")
            }
        
            return Promise.reject(error)
        })
    }, [])

    const handleClose = () => {
        setMessage("")
    }

    return (
        <Snackbar
            anchorOrigin={{vertical: "top", horizontal: "center" }}
            open={message != ""}
            onClose={handleClose}
        >
            <Alert
                variant="filled"
                severity="error"
                sx={{ width: '100%' }}
                onClose={handleClose}
            >
                { message }
            </Alert>
        </Snackbar>
    )
}

export { Api as default, setAuthHeader, request, requestSilent }
