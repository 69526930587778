import { Chip, Grid, IconButton, TextField, Tooltip } from "@mui/material"
import { ShadedBox } from "@/common/StyledComponents";
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import { getDeltaDays } from "@/utils";
import { milestoneReminderOptions } from "../project/constants";
import { parse } from "date-fns";
import { request } from "@/Api";
import EditRoundedIcon from '@mui/icons-material/EditRounded';

export default function Milestone({ milestone, milestones, setMilestones, setPopupMilestone, readOnly }){
    const theme = useTheme()
    const [ milestoneLocal, setMilestoneLocal ] = useState(milestone)
    const [ cache, setCache ] = useState(null)
    const deltaDays = getDeltaDays(new Date(milestoneLocal.remind_date), new Date(milestoneLocal.complete_date))
    const reminderObj = milestoneReminderOptions.find((option)=>option.delta == deltaDays)
    const reminderLabel = reminderObj ? reminderObj.label : null

    const handleInput = (event) => {
        setMilestoneLocal({...milestoneLocal, description: event.target.value})
    }

    const handleFocus = (field) => () => {
        setCache(milestoneLocal[field])
    }

    const handleBlur = (event) => {
        if (cache == event.target.value) {
            return
        }

        request.put(`milestone/${milestone.id}`, {
            ...milestoneLocal,
            description: event.target.value
        })
        .then((response)=>{
            setMilestones(milestones.map(milestone=>{
                return milestone.id == response.data.id ? response.data : milestone
            }))
        })
    }

    const handleMenuOpen = (event) => {
        setPopupMilestone({
            ...milestone,
            remind_date: milestone.remind_date ? parse(milestone.remind_date, "y-MM-dd", new Date()) : null,
            complete_date: milestone.complete_date ? parse(milestone.complete_date, "y-MM-dd", new Date()) : null,
        })
    }

    useEffect(()=>{
        setMilestoneLocal(milestone)
    }, [milestone])

    return (
        <ShadedBox backgroundColor={theme.palette.background.paper} style={{padding: 15, margin: 0, marginBottom: 20}}>
            <Grid container height={"100%"} spacing={2}>
                <Grid item md={0.3}>
                    <div style={{width: 10, marginTop: 10}}>
                        <div style={{
                            width: 10, height: 10, 
                            background: new Date(milestone.complete_date) < new Date() ? theme.palette.primary.main : theme.palette.text.primary,
                            borderRadius: "50%"
                        }} />
                        <div style={{marginTop: 5, height: "max-content", width: "100%", alignItems: "center", display: "flex", justifyContent: "center"}}>
                            <div style={{width: 1, background: theme.palette.text.primary, marginTop: 0, height: 55}} />
                        </div>
                    </div>
                </Grid>
                <Grid item md={11.7}>
                    <Grid container item justifyContent={"space-between"}>
                        <Grid item paddingBottom={1}>
                            <Grid container spacing={2} alignItems={"center"}>
                                <Grid item>
                                    {milestoneLocal.name}
                                </Grid>
                                <Grid item>
                                    <Chip label={
                                        parse(milestone.complete_date, "y-MM-dd", new Date()).toDateString()
                                    } color="default"/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Grid container>
                                <Grid item padding={"5px"}>
                                    <Tooltip title={
                                        <div>
                                            <span>{milestoneLocal.remind_date || "no reminder"}</span>
                                            <br/>
                                            <span>{reminderLabel}</span>
                                        </div>
                                    }>
                                        <NotificationsRoundedIcon color={milestoneLocal.remind_date ? "success" : "default"}/>
                                    </Tooltip>
                                </Grid>
                                { readOnly ? null : (
                                    <Grid item>
                                        <IconButton size="small" onClick={handleMenuOpen}>
                                            <EditRoundedIcon />
                                        </IconButton>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                        <TextField 
                            size="small"
                            onChange={handleInput}
                            fullWidth
                            value={milestoneLocal.description}
                            onFocus={handleFocus("description")}
                            onBlur={handleBlur}
                            InputProps={{
                                readOnly: readOnly,
                            }}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </ShadedBox>
    )

}