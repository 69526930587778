import { useEffect } from "react"
import useInvitation from "./authHooks"
import { useSelector } from "react-redux"
import { selectIsLoggedIn } from "./authSlice"

export default function JoinOrganization(){
    const invitation = useInvitation()
    const isLoggedIn = useSelector(selectIsLoggedIn)

    useEffect(()=>{
        if (invitation){
            invitation.tryGoToSignUp()
            invitation.tryGoToLogin()
            if (invitation.existing_account_id && isLoggedIn){
                invitation.joinOrganization()
            }
        }
    }, [invitation])

    return (
        <>
            {
                !invitation && 
                <div style={{width: "100%", height: "100%", position: "relative"}}>
                    <h3 style={{position: "absolute", top: "40%", left: "50%", transform: "translate(-50%, -50%)"}}>
                        Invitation invalid, expired or fulfilled
                    </h3>
                </div>
            }
        </>
    )
}