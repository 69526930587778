import { useEffect, useRef, useState } from "react"
import { useParams, useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import { Avatar, Button, Chip, Grid, IconButton, Tooltip, useTheme, Link } from "@mui/material"

import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import VerifiedIcon from '@mui/icons-material/Verified';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import DeleteForeverRounded from "@mui/icons-material/DeleteForeverRounded";

import { DottedDiv, Heading } from "@/common/StyledComponents";
import { CopyLinkButton, MakersIcon } from "@/common/CommonComponents";

import { request } from "@/Api"
import ProtectedComponents from "@/auth/ProtectedComponents";
import { PersonProfilePopup } from "@/person_profile/PersonProfilePopup";
import { ProfileImagePopup } from "@/profiles_common/ProfileImagePopup";
import { ProjectProfilePopup } from "@/project_profiles/ProjectProfilePopup";
import { ProfileListItem, ProfileListItemLikeButton } from "@/profiles_common/CommonComponents";
import ProjectProfileThumbnail from "@/project_profiles/ProjectProfileThumbnail";
import ProfileAvatar from "@/common/ProfileAvatar";
import { ProfileStyle } from "@/profiles_common/profileStyle";
import { parseVideoUrlToEmbedUrl } from "@/utils";
import { setToken, selectHasPermission, selectIsSuperAdmin } from "@/auth/authSlice";

export function PersonProfile(){
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { profileHandle } = useParams()
    const [ personProfile, setPersonProfile ] = useState(null)
    const [ personPopupOpen, setPersonPopupOpen ] = useState(false)
    const [ projectPopupOpen, setProjectPopupOpen ] = useState(false)
    const [ profileImagePopupOpen, setProfileImagePopupOpen ] = useState(false)
    const [ profilePicUrl, setProfilePicUrl ] = useState(null)
    const [ bannerPicUrl, setBannerPicUrl ] = useState(null)
    
    const fileInputRef = useRef()

    const isAdmin = useSelector(state => selectHasPermission(state, "view_admin_panel"))
    const isSuperAdmin = useSelector(selectIsSuperAdmin)
    const isMyPage = personProfile?.is_owner
    const isCollegue = personProfile?.is_collegue
    
    const canEdit = isMyPage || (isAdmin && isCollegue) || isSuperAdmin

    const getPersonProfile = () => {
        request.get(profileHandle ? `person-profiles/${profileHandle}` : "my-profile")
        .then(response=>{
            const profileData = response.data
            if (!profileData){
                navigate("/login")
            }
            setPersonProfile(MapPersonProfile(profileData))
            setProfilePicUrl(profileData.image?.processed ? profileData.image.url_thumb : (profileData.image && profileData.image.url_orig))
            setBannerPicUrl(profileData.banner_image && profileData.banner_image.url_orig)
        })
    }

    useEffect(()=>{
        getPersonProfile()
    }, [profileHandle])
    
    const handleBannerFileSelect = () => {
        const file = fileInputRef.current.files[0]
        const formData = new FormData()
        formData.append("file", file, file.name)
        formData.append("person_profile_id", personProfile.id)
        request.post(`person-profiles/${personProfile.id}/banner_image`, formData)
        .then(response=>{
            setBannerPicUrl(response.data.banner_image ? response.data.banner_image.url_orig : null)
        })
    }

    const handleBannerFileDelete = () => {
        request.delete(`person-profiles/${personProfile.id}/banner_image`)
        .then(()=>{
            setBannerPicUrl(null)
        })
    }

    const updateProfilePic = (url) => {
        setProfilePicUrl(url)

        request.post(`/refresh-token`, {}).then(response => { 
            dispatch(setToken(response.data))
        }).catch(error => {
            console.log(error)
        })
    }

    if (!personProfile){
        return null
    }

    return (
        <ProfileStyle>
            <div style={{height: 195, marginTop: 40, position: "relative"}}>
                <DottedDiv backgroundColor={theme.palette.background.rainbow} dotColor={"#ffffff11"}>
                    <div style={{height: 250, objectFit: "cover", flex: "none"}}>
                        {bannerPicUrl && <img src={bannerPicUrl} style={{objectFit: "cover", height: "100%", width: "100%", borderRadius: "10px"}}/>}
                    </div>
                </DottedDiv>
                <div hidden={!canEdit}>
                    <ProtectedComponents>
                        <div style={{position: "absolute", top: 5, right: 5, background: theme.palette.background.paper, borderRadius: "50%"}}>
                            <IconButton size="small" onClick={()=>{fileInputRef.current.click()}}>
                                <CreateOutlinedIcon fontSize="tiny" />
                            </IconButton>
                            <input hidden type="file" ref={fileInputRef} onChange={handleBannerFileSelect} accept="image/png, image/jpeg, image/webp"/>
                        </div>
                        <div hidden={!bannerPicUrl} style={{position: "absolute", top: 5, right: 40, background: theme.palette.background.paper, borderRadius: "50%"}}>
                            <IconButton size="small" onClick={handleBannerFileDelete}>
                                <DeleteForeverRounded fontSize="tiny" color="error"/>
                            </IconButton>
                        </div>
                    </ProtectedComponents>
                </div>
            </div>
            <div style={{position: "relative", width: 120, height: 120, marginBottom: 20, marginLeft: 20}}>
                <Avatar sx={{width: "100%", height: "100%"}} src={profilePicUrl}/>
                <div hidden={!canEdit}>
                    <ProtectedComponents>
                        <IconButton onClick={()=>{setProfileImagePopupOpen(true)}} size="small" style={{
                            position: "absolute", width: 25, height: 25,
                            background: theme.palette.background.default,
                            border: `1px solid ${theme.palette.background.paper}`,
                            bottom: 3, left: 3, borderRadius: "50%"
                        }}>
                            <CreateOutlinedIcon fontSize="tiny"/>
                        </IconButton>
                    </ProtectedComponents>
                </div>
                <IconButton size="small" style={{
                    cursor: "default",
                    position: "absolute", width: 30, height: 30, 
                    background: theme.palette.background.default,
                    border: `1px solid ${theme.palette.background.paper}`,
                    bottom: 1, right: 1, borderRadius: "50%"
                }}>
                    {personProfile.is_from_makers ? <MakersIcon size={24}/> : <VerifiedIcon fontSize="tiny"/>}
                </IconButton>
            </div>
            <Grid container spacing={8}>
                <Grid item md={8}>
                    <Grid container alignItems={"center"} spacing={1} paddingBottom={1}>
                        <Grid item>
                            <Heading>
                                {`${personProfile.first_name} ${personProfile.last_name}`}
                            </Heading>
                        </Grid>
                        <Grid item>
                            {personProfile.pronouns && (
                                <span style={{color: "gray", fontSize: 14, paddingRight: 10}}>
                                    {`(${personProfile.pronouns})`}
                                </span>
                            )}
                            <span style={{color: "gray", fontSize: 14}}>
                                {`${personProfile.title} ${personProfile.org_profile_id ? "at" : ""} `}
                            </span>
                            {personProfile.org_profile_id ?
                            <Link sx={{fontSize: 14}} href={`/company-profile/${personProfile.org_profile.handle}`}>
                                {personProfile.org_profile.name}
                            </Link> : null}
                        </Grid>
                    </Grid>
                    <Grid container alignItems={"center"} spacing={1} justifyContent={"space-between"} paddingBottom={8}>

                        <Grid item md={12} xs={12} >
                            <div>
                                <span>{personProfile.headline}</span>
                            </div>
                        </Grid>
                        
                        <Grid item container alignItems={"center"} spacing={2} width={"fit-content"}>
                            <ProfileListItem icon={<AlternateEmailOutlinedIcon fontSize="16px"/>} text={personProfile.handle} />
                            {personProfile.location &&
                            <ProfileListItem 
                                icon={<FmdGoodOutlinedIcon fontSize="16px" />} 
                                text={personProfile.location.short_name} 
                            /> }
                            <ProfileListItem 
                                icon={<CalendarTodayOutlinedIcon fontSize="16px" />} 
                                text={`Joined ${new Date(personProfile.join_date).toLocaleDateString('en-US', { year: 'numeric', month: 'long' })}`} 
                            />
                            <Grid item>
                                <ProfileListItemLikeButton likableEntity={personProfile} setLikableEntity={setPersonProfile} modelType={"person_profile"}/>
                            </Grid>
                        </Grid>
                        
                        <Grid item container alignItems={"center"} spacing={1} width={"max-content"}>
                            {
                                canEdit &&
                                <>
                                    <Grid item>
                                        <Button
                                            variant="contained" color="secondary" size="small"
                                            sx={{boxShadow: "none"}} startIcon={<CreateOutlinedIcon />}
                                            onClick={()=>{setPersonPopupOpen(true)}}
                                        >
                                            <span style={{lineHeight: 1.5, fontSize: 12}}>Edit Profile</span>
                                        </Button>
                                    </Grid>
                                    <Grid item>
                                        <Button
                                            variant="contained" color="secondary" size="small"
                                            sx={{boxShadow: "none"}} startIcon={<AddRoundedIcon />}
                                            onClick={()=>{setProjectPopupOpen(true)}}
                                        >
                                            <span style={{lineHeight: 1.5, fontSize: 12}}>Add Project</span>
                                        </Button>
                                    </Grid>
                                </>
                            }
                            {/* TODO: implement report profile feature */}
                            {/* <Grid item>
                                <Button
                                    variant="contained" color="secondary" size="small"
                                    sx={{boxShadow: "none", minWidth: 0, width: "28px"}}
                                >
                                    <MoreVertRoundedIcon fontSize="small"/>
                                </Button>
                            </Grid> */}
                            <Grid item>
                                <CopyLinkButton />
                            </Grid>
                        </Grid>
                        
                    </Grid>
                    
                    <div style={{paddingBottom: 50}} hidden={!personProfile.description}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Background</Heading>
                        <span style={{overflowWrap: "anywhere", fontSize: 14, whiteSpace: "pre-line"}}>{personProfile.description}</span>
                    </div>

                    <div style={{paddingBottom: 50}} hidden={!personProfile.reel_url}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Reel</Heading>
                        {personProfile.reel_url ? 
                            <iframe 
                                style={{border: "none", borderRadius: "10px", width: "100%", aspectRatio: "16 / 9"}}
                                src={parseVideoUrlToEmbedUrl(personProfile.reel_url)}
                            />
                        : null}
                    </div>
                        
                    <div hidden={personProfile.project_profiles.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Projects</Heading>
                        {personProfile.project_profiles.map((project, index)=>{
                            return (
                                <ProjectProfileThumbnail key={index} project={project}/>
                            )
                        })}
                    </div>
                    
                </Grid>
                <Grid item md={4}>
                    <div style={{paddingBottom: 50}} hidden={personProfile.skills.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Skills</Heading>
                        <Grid container spacing={1}>
                            {personProfile.skills.map((skill, index)=>
                                <Grid item key={index}>
                                    <Chip label={skill.name}/>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                    <div style={{paddingBottom: 50}} hidden={personProfile.client_profiles.length == 0}>
                        <Heading style={{fontSize: 16, paddingBottom: 20}}>Clients</Heading>
                        <Grid container spacing={1.5}>
                            {personProfile.client_profiles.map((client_profile, index)=>
                                <Grid key={index} item>
                                     <Tooltip title={client_profile.name}>
                                        <div>
                                            <ProfileAvatar image={client_profile.image} name={client_profile.name} size={40} darkModeInvert />
                                        </div>
                                    </Tooltip>
                                </Grid>
                            )}
                        </Grid>
                    </div>
                </Grid>
            </Grid>

            <PersonProfilePopup 
                personProfile={personProfile} 
                setPersonProfile={setPersonProfile}
                personPopupOpen={personPopupOpen}
                closePopup={()=>{setPersonPopupOpen(false)}}
            />

            <ProjectProfilePopup
                projectProfile={null}
                projectPopupOpen={projectPopupOpen}
                closePopup={()=>{setProjectPopupOpen(false)}}
                addProjectProfile={(projectProfile)=>{
                    setPersonProfile({...personProfile, project_profiles: [projectProfile, ...personProfile.project_profiles]})
                }}
            />

            <ProfileImagePopup
                profileType={"person-profile"}
                profileId={personProfile.id}
                profileImagePopupOpen={profileImagePopupOpen}
                profilePicUrl={profilePicUrl}
                setProfilePicUrl={updateProfilePic}
                closePopup={()=>{setProfileImagePopupOpen(false)}}
            />
        </ProfileStyle>
    )
}


export const MapPersonProfile = (personProfile) => {
    return ({
        ...personProfile, 
        new_client_profiles: [], 
        new_skills: [],
        client_profile_ids: personProfile.client_profiles.map(personProfile=>personProfile.id),
        skill_ids: personProfile.skills.map(skill=>skill.id)
    })
}
