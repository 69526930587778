import { useState, useEffect, useRef } from "react";
import { useTheme } from "@mui/material";

export default function SheetInputField(props) {
    const { disabled, type, endadornment, align, value, error, formatter, onBlur, onChange, onFocus } = props
    const theme = useTheme()
    const inputRef = useRef(null);
    const [ focused, setFocused ] = useState(false)

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.select()
        }
    }, [focused])
    
    const handleFocus = (e) => {
        if (disabled) {
            return
        }
        setFocused(true)

        if (onFocus) {
            onFocus(e)
        }
    }
    
    const handleBlur = (e) => {
        setFocused(false)
        onBlur(e)
    }

    const handleMouseEnter = (e) => {
        e.currentTarget.style.border = `0.5px ${error ? theme.palette.error.dark : theme.palette.text.primary} solid`
    }

    const handleMouseLeave = (e) => {
        e.currentTarget.style.border = `0.5px ${error ? theme.palette.error.main : theme.palette.text.secondary} solid`
    }
    
    const style = {
        outlineWidth: 0,
        fontSize: 12,
        color: disabled ? "grey" : theme.palette.text.primary,
        textAlign: align ? align : type == "number" ? "right" : "left",
        borderRadius: 6,
        background: "transparent",
        border: `0.5px ${error ? theme.palette.error.main : theme.palette.text.secondary} solid`,
        width: "100%",
        padding: 8,
        height: 30,
        boxSizing: "border-box",
        paddingRight: endadornment ? (endadornment.length+2)+"ch" : 8,
    }

    return (
        <div style={{position: "relative"}}>
            <span style={{
                fontSize: 12, 
                position: "absolute", 
                right: "1px", 
                top: "50%",
                transform: "translate(0, -50%)",
                paddingRight: 5, 
                color: disabled ? "grey" : theme.palette.text.primary,
            }}>
                {endadornment}
            </span>

            { focused ? (
                <input
                    ref={inputRef}
                    type={type}
                    value={value}
                    onChange={onChange}
                    onBlur={handleBlur}
                    onFocus={handleFocus}
                    onWheel={(e)=>{e.target.blur()}}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    disabled={disabled}
                    style={style}
                />
            ) : (
                <div
                    onFocus = {handleFocus}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    style={{
                        ...style,
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        verticalAlign: "top",
                    }}
                    tabIndex={0}
                >
                    {value ? formatter ? formatter(value) : value : (<>&nbsp;</>)}
                </div>
            ) }
            
        </div>
    )
}
