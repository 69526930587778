import { useState } from 'react';

import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { ThemeProvider } from "@mui/material/styles"

import { baseTheme, darkTheme } from "@/themes"

export default function ConfirmationDialog(props) {
    const { open, closeDialog, title, children, callback, hideOk } = props
    const [loading, setLoading] = useState(false);
    const themeMode = localStorage.getItem("themeMode") || "light"

    const stopPropagation = (event) => {
        event.stopPropagation()
    }

    const handleCancel = (event) => {
        event.stopPropagation()
        closeDialog()
    }
    
    const handleOk = (event) => {
        event.stopPropagation()
        setLoading(true)
        callback()
        closeDialog()
        setLoading(false)
    }
    
    return (
        <ThemeProvider theme={themeMode == "dark" ? darkTheme : baseTheme}>
            <Dialog
                open={open}
                onClose={handleCancel}
            >
                <DialogTitle onClick={stopPropagation}>{ title }</DialogTitle>
                <DialogContent dividers onClick={stopPropagation}>{ children }</DialogContent>
                <DialogActions onClick={stopPropagation}>
                    { loading ? (
                        <CircularProgress size={30} sx={{ marginRight: "12px"}} />
                    ) :  null }
                    
                    <Button variant="contained" onClick={handleCancel} color="secondary">Cancel</Button>

                    { hideOk ? null : (
                        <Button variant="contained" onClick={handleOk} color="primary">Continue</Button>
                    ) }
                </DialogActions>
            </Dialog>
        </ThemeProvider>
    )
}
