import { Box, ButtonBase, Link, useTheme } from "@mui/material"
import { useHandlePageGoTo } from "@/nav/navHooks"

export default function LogoButton() {
    const theme = useTheme()
    const handlePageGoTo = useHandlePageGoTo()

    const handleButtonClick = (event) => {
        event.preventDefault()
        handlePageGoTo("dashboard")
    }
    
    return (
        <Link href="dashboard">
            <ButtonBase onClick={handleButtonClick} variant="default">
                <Box
                    component="img"
                    src={`/logo_${theme.palette.mode}.png`}
                    sx={{ width: "3em", cursor: "pointer"}}
                />
            </ButtonBase>
        </Link>
    )
}
