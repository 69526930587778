import { useState } from "react"

import TableCell from "@mui/material/TableCell"
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Unstable_Grid2'
import { Table, TableHead, TableRow, TableBody } from "@mui/material"

import CustomTableContainer from "@/table//CustomTableContainer"
import ApiAutocomplete from "@/common/ApiAutocomplete"
import { CustomRow } from "@/table/CustomTable"
import { formattedCurrency, queryToFilterValue } from "@/utils"
import { FormattedCurrency } from "@/common/StyledComponents"

export default function Invoices() {
    const [total, setTotal] = useState("-")

    return (
        <CustomTableContainer
            modelType="invoice"
            defaultOrder="-amount"
            title="Overdue Invoices"
            headCells={[
                {id: "quickbooks_transaction_id", label: "QB Id"}, 
                {id: "docket", label: "Docket"}, 
                {id: "project_id", label: "Project", orderable: true}, 
                {id: "client_name", label: "Client"}, 
                {id: "person_names", label: "Team"}, 
                {id: "amount", label: "Amount", orderable: true}, 
                {id: "due_date", label: "Days Late", orderable: true}, 
            ]}
            itemRowComponent={({ item }) => (
                <CustomRow>
                    <TableCell component="th" scope="row">{item.quickbooks_transaction_id}</TableCell>
                    <TableCell align="left">{item.docket}</TableCell>
                    <TableCell align="left">{item.project_name}</TableCell>
                    <TableCell align="left">{item.client_name}</TableCell>
                    <TableCell align="left">{item.person_names.join(", ")}</TableCell>
                    <TableCell align="left">
                        <FormattedCurrency currency={item.currency} number={item.amount}/>
                    </TableCell>
                    <TableCell align="left">{item.days_late}</TableCell>
                </CustomRow>
            )}
            filterComponents={({filters, handleInputChange, handleAutocompleteChange, handleAutocompleteClear}) => (
                <Grid container sx={{ paddingTop: 2 }} spacing={3}>
                    <Grid xs={6} md={2}>
                        <TextField value={queryToFilterValue(filters.docket) || ""} sx={{ width:"100%" }} onChange={handleInputChange("docket")} label="Docket" variant="outlined" />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <ApiAutocomplete
                            silentErrors
                            value={queryToFilterValue(filters.owner_id)}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            onChange={handleAutocompleteChange("owner_id")}
                            onInputChange={handleAutocompleteClear("owner_id")}
                            label={"Owner"}
                        />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <ApiAutocomplete
                            silentErrors
                            value={queryToFilterValue(filters.person_id)}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            onChange={handleAutocompleteChange("person_id")}
                            onInputChange={handleAutocompleteClear("person_id")}
                            label={"Producer"}
                        />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <ApiAutocomplete
                            silentErrors
                            value={queryToFilterValue(filters.client_id)}
                            apiRoute={"clients"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("client_id")}
                            onInputChange={handleAutocompleteClear("client_id")}
                            label={"Client"}
                        />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <ApiAutocomplete
                            silentErrors
                            value={queryToFilterValue(filters.legal_entity_id)}
                            apiRoute={"legal-entities"}
                            fields={["name"]}
                            onChange={handleAutocompleteChange("legal_entity_id")}
                            onInputChange={handleAutocompleteClear("legal_entity_id")}
                            label={"Entity"}
                        />
                    </Grid>
                </Grid>
            )}
            setAdditionalData={(data) => {
                setTotal(formattedCurrency(data.total_amount, "CAD"))
            }}
        >
            <Grid container sx={{marginBottom: "24px"}}>
                <Grid item xs={12} md={6}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Total</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell align="left">{total}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </CustomTableContainer>
    )
}
