import { useNavigate, useLocation } from "react-router-dom"

const useHandlePageGoTo = () => {
    const navigate = useNavigate()
    const handlePageGoTo = (route) => {
        if (route.startsWith('http')) {
            window.open(route, "_blank")
        } else {
            navigate(route)
        }
    }
    return handlePageGoTo
}

const useIsHidden = () => {
    const location = useLocation()
    const currentPage = location.pathname.split("/")[1]
    const hidden = ["login", "sign-up", "initializing", "set-up"].includes(currentPage)
    return hidden
}

export { useHandlePageGoTo, useIsHidden }
