import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { Grid, useTheme } from "@mui/material"
import { Heading } from "@/common/StyledComponents";
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { ProfileListItem } from "@/profiles_common/CommonComponents";
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { useMediaQuery } from "@mui/material";
import ProfileAvatar from "@/common/ProfileAvatar";
import LabelRoundedIcon from '@mui/icons-material/LabelRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import SellIcon from '@mui/icons-material/Sell';
import { CustomChip } from "@/profiles_common/CommonComponents";
import { MakersIcon } from "@/common/CommonComponents";
import { Link } from "@mui/material";

export default function ProjectProfileThumbnail({project}){
    const [ hovering, setHovering ] = useState(false)
    const image = project.flagged_image
    const imageUrl = image ? (image.processed ? image.url_large : image.url_orig) : null
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))
    const navigate = useNavigate() 

    return (
        <div 
            onClick={()=>{navigate(`/project-profile/${project.handle}`)}}
            onMouseOver={()=>{setHovering(true)}}
            onMouseLeave={()=>{setHovering(false)}} 
            style={{cursor: "pointer", background: theme.palette.background.paper, marginBottom: 40, borderRadius: 16, overflow: "hidden", padding: 20, paddingLeft: 25, position: "relative"}}
        >
            
            <Grid container flexDirection={isMobile ? "column-reverse" : "inherit"}>
                <Grid item md={6} xs={12}>
                    <ProfileListItem 
                        style={{paddingBottom: 5}} 
                        icon={<CalendarTodayOutlinedIcon fontSize="16px" sx={{color: theme.palette.text.secondary}}/>} 
                        text={
                            <span style={{color: theme.palette.text.secondary}}>{project.wrap_date}</span>
                        }
                    />
                    <Grid container alignItems={"center"} spacing={2}>
                        <Grid item>
                            <Heading>{project.name}</Heading>
                        </Grid>
                        {project.is_by_makers && <Grid item>
                            <div style={{background: theme.palette.background.rainbow, borderRadius: "50%", padding: 2}}>
                                <div style={{background: theme.palette.background.default, borderRadius: "50%", padding: 2}}>
                                    <MakersIcon />
                                </div>
                            </div>
                        </Grid>}
                    </Grid>
                    <p
                        style={{
                            paddingRight: 50, height: 60, minWidth: "200px",
                            display: "-webkit-box",
                            overflow: "hidden", textOverflow: "ellipsis",
                            WebkitLineClamp: 3, WebkitBoxOrient: "vertical"
                        }}
                    >
                        <span style={{fontSize: 14}}>{project.description}</span>
                    </p>
                    <Grid container spacing={1} paddingBottom={2}>
                        {project.role &&
                        <Grid item>
                            <CustomChip 
                                icon={SellIcon} 
                                text={project.role} 
                            />
                        </Grid>}
                        {project.budget && 
                        <Grid item>
                            <CustomChip icon={AccountBalanceWalletRoundedIcon} text={project.budget} />
                        </Grid>}
                        {project.project_profile_types.length > 0 && 
                        <Grid item>
                            <CustomChip 
                                icon={LabelRoundedIcon} 
                                text={project.project_profile_types[0].name} 
                            />
                        </Grid>}
                    </Grid>

                    <Grid container spacing={1} alignItems={"center"}>
                        <Grid item>
                            <ProfileAvatar size={30} image={project.client_profile?.image} name={project.client_profile?.name} darkModeInvert />
                        </Grid>
                        <Grid item>
                            <div style={{fontSize: 12}}>
                                <span style={{paddingRight: 3}}>{`for ${project.client_profile.name}`}</span>
                                {(project.is_by_makers && project.org_profile) && 
                                    <>
                                        <span style={{paddingRight: 3}}>by</span> 
                                        <Link href={`/company-profile/${project.org_profile.handle}`} onClick={(e)=>{e.stopPropagation()}} style={{fontSize: 12, textDecoration: "none"}}>Makers</Link>
                                    </>
                                }
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item md={6} xs={12}>
                    <div style={{height: isMobile ? 300 : "100%"}}>
                        <img src={imageUrl || "/project_profile_thumbnail_placeholder.png"} style={{
                            position: "absolute", 
                            top: 0, 
                            transform: isMobile ? "translateX(0%)" : "translateX(-100%)",
                            left: isMobile ? 0 : "100%", 
                            width: isMobile ? "100%" : "50%", 
                            maxWidth: isMobile ? "100%" : 500,
                            height: "100%", 
                            maxHeight: isMobile ? 300 : "100%", 
                            objectFit: "cover", 
                            clipPath: isMobile ? "circle(88% at 50% 0%)" : "circle(70% at 65% 50%)",
                        }}/>
                    </div>
                </Grid>
            </Grid>

            {project.locations.length > 0 ? (
                <div style={{position: "absolute", top: 20, right: 22, zIndex: 1}}>
                    <CustomChip icon={PlaceRoundedIcon} text={project.locations[0].name}/>
                </div>
            ) : null}

            <div style={{
                boxShadow: "#00000033 0px 0px 5px 0px",
                width: 32, height: 32, background: project.is_by_makers ? theme.palette.background.rainbow : theme.palette.background.paper , 
                position: "absolute", bottom: 20, right: 22, borderRadius: "50%",
                transform: `scale(${hovering ? 3 : 1})`, transformOrigin: "top left",
                transition: "all 0.5s"
            }}/>

            <div 
                style={{
                    width: 32, height: 32,
                    position: "absolute", borderRadius: "50%",
                    transformOrigin: "top left", transition: "all 0.5s", 
                    bottom: hovering ? 8 : 20, right: hovering ? 10 : 22
                }}
            >
                <div style={{textAlign: "center", lineHeight: 2.75, color: project.is_by_makers ? theme.palette.background.default : theme.palette.text.primary}}>
                    <ArrowForwardRoundedIcon />
                </div>
            </div>
        </div>
    )
}