import { useState, useEffect } from "react"
import { parse, format } from "date-fns"

import Grid from '@mui/material/Unstable_Grid2'
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import InputLabel from '@mui/material/InputLabel'
import InputAdornment from '@mui/material/InputAdornment'
import Autocomplete from '@mui/material/Autocomplete'
import TextField from '@mui/material/TextField'
import { DatePicker } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers'
import CircularProgress from '@mui/material/CircularProgress'
import { Button, Tooltip } from '@mui/material'
import Divider from '@mui/material/Divider'

import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import { request } from "@/Api"
import { formattedCurrency, getCurrencySymbol } from "@/utils"
import { NumericFormatCustom } from "@/common/StyledComponents"
import { billingSchedules, CUSTOM_BILLING_SCHEDULE } from "@/project/constants"

export default function ProjectBillsLegacy (props) {

    const {projectQuoted, currency, projectId, bills, disabled, setProjectBills} = props
    const [localBills, setLocalBills] = useState([])
    const [errors, setErrors] = useState({})
    const [isBillsPending, setIsBillsPending] = useState(false)

    useEffect(()=>{
        setLocalBills([...bills])
    }, [bills])

    const handleBillingScheduleChange = (event, newValue) => {
        const percentages = newValue == CUSTOM_BILLING_SCHEDULE ? [0] : newValue.replace("%", "").split(" / ")
        const newBills = []
        for (var i in percentages){
            const percentage = percentages[i] / 100
            newBills.push({
                "project_id": projectId,
                "quoted_percentage": percentage,
                "sent": false,
                "actual_amount": percentages * projectQuoted,
                "_is_new": true
            })
        }
        setLocalBills(newBills)
    }

    const handleBillAdd = () => {
        setErrors({})
        setLocalBills([...localBills, {
            "project_id": projectId,
            "sent": false,
            "_is_new": true
        }])
    }

    const handleBillCancel = (key) => () => {
        setErrors({})
        setLocalBills([...localBills.slice(0,key), ...localBills.slice(key+1)])
    }

    const handleCalendarChange = (fieldName, key) => (newValue) => {
        setLocalBills(localBills.map((bill, index) => {
            return (index == key) ? { ...bill, [fieldName]: newValue&&newValue!="Invalid Date" ? format(newValue, "y-MM-dd") : newValue } : bill
        }));
    }
    const handleInputChange = (fieldName, key) => (event) => {
        let value = event.target.value
        setLocalBills(localBills.map((bill, index) => {
            return (index == key) ? { ...bill, [fieldName]: value } : bill
        }));
    }

    const handleQuotedPercentageChange = (key) => (event) => {
        let value = !["", null].includes(event.target.value) ? event.target.value / 100 : event.target.value
        let updateBillData = {"quoted_percentage": value}
        setLocalBills(localBills.map((bill, index) => {
            if (bill._is_new){
                updateBillData["actual_amount"] = (value * projectQuoted).toFixed(2)
            }
            updateBillData["estimated_amount"] = (value * projectQuoted).toFixed(2)
            return (index == key) ? { ...bill, ...updateBillData } : bill
        }));
    }

    const handlePostAndPutBills = () => {
        const requiredFieldsBill = ["quoted_percentage", "bill_date", "notes", "actual_amount"]
        const requiredFieldsChangeOrder = ["actual_amount", "bill_date", "notes"]
        let currentErrors = {}
        for (var i in localBills){
            let requiredFields = localBills[i].is_legacy_change_order ? requiredFieldsChangeOrder : requiredFieldsBill
            for (var j in requiredFields){
                if(!(requiredFields[j] in localBills[i]) || [null, ""].includes(localBills[i][requiredFields[j]])){
                    currentErrors = {...currentErrors, [i]: {...currentErrors[i], [requiredFields[j]] : "field required"}}
                    console.log(currentErrors)
                }
            }
        }
        setErrors(currentErrors)
        if(Object.keys(currentErrors).length > 0){
            return
        }
        
        setIsBillsPending(true)
        let promises = []
        var newBills = []
        for (var i in localBills){
            if(localBills[i].id){
                promises.push(
                    request.put(`bills/${localBills[i].id}`, localBills[i])
                    .then((response)=>{
                        newBills.push(response.data)
                    })
                )
            }
            else{
                promises.push(
                    request.post("bills", localBills[i])
                    .then((response)=>{
                        newBills.push(response.data)
                    })
                )
            }
        }
        Promise.all(promises).then(()=>{
            setIsBillsPending(false)
            setErrors({})
            let orderedNewBills = newBills.sort((a,b) => (a.bill_date > b.bill_date) ? 1 : ((b.bill_date > a.bill_date) ? -1 : 0))
            setLocalBills(orderedNewBills)
            setProjectBills(orderedNewBills)
        }).catch((error)=>{
            setIsBillsPending(false)
            console.log(error)
        })
    }

    return(
        <>
            {localBills.length == 0 ?
            disabled ?
                <InputLabel>None</InputLabel>
                :
                <>
                    <InputLabel>Choose a billing schedule</InputLabel>
                    <Autocomplete
                        options={billingSchedules}
                        renderInput={(params) => <TextField {...params} label="Billing Schedule" />}
                        onChange={handleBillingScheduleChange}
                    />
                </> 
                
                :  

                <>
                    <TableContainer sx={{ marginTop: 1 }}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    <TableCell component="th" scope="row">ID</TableCell>
                                    <TableCell align="left">Quoted Percentage</TableCell>
                                    <TableCell align="left">Estimated Bill</TableCell>
                                    <TableCell align="left">Actual Bill</TableCell>
                                    <TableCell align="left">Bill Date</TableCell>
                                    <TableCell align="left">Bill Notes</TableCell>
                                    <TableCell align="left">Sent</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                            
                                {localBills.map((bill, index) => (
                                    <TableRow key={index} sx={{ "&:last-child td, &:last-child th": { border: 0 }}}>
                                        <TableCell width={1} align="center">{
                                            bill.id ? bill.id :
                                            <IconButton onClick={handleBillCancel(index)} aria-label="delete" size="large">
                                                <RemoveCircleIcon sx={{transform: "scale(1.5)"}} fontSize="large" />
                                            </IconButton>
                                        }</TableCell> 
                                        
                                        <TableCell width={100} align="left">
                                            {bill.is_legacy_change_order ? "-" : 
                                                <>
                                                    {bill.sent ? parseInt(bill.quoted_percentage * 100)+"%" : 
                                                        <TextField 
                                                            readOnly={ disabled }
                                                            type={"number"}
                                                            InputProps={{endAdornment: <InputAdornment position="end">%</InputAdornment>}}
                                                            fullWidth
                                                            error={errors[index] ? "quoted_percentage" in errors[index] : false} helperText={errors[index] ? errors[index]["quoted_percentage"]: ""}
                                                            onChange={handleQuotedPercentageChange(index)} 
                                                            value={![null, ""].includes(bill.quoted_percentage) ? Math.round(bill.quoted_percentage * 100) : ""}
                                                        /> 
                                                    }
                                                </>
                                            }
                                        </TableCell>
                                        
                                        <TableCell width={100} align="left">
                                            {bill.is_legacy_change_order ? "-" : formattedCurrency(bill.estimated_amount||0, currency)}
                                        </TableCell>
                                        <TableCell width={150} align="left">
                                            {bill.sent ?
                                                formattedCurrency(bill.actual_amount||0, currency)
                                                :
                                                <TextField
                                                    value={![null, ""].includes(bill.quoted_percentage) ? bill.actual_amount : ""}
                                                    error={errors[index] ? "actual_amount" in errors[index] : false} helperText={errors[index] ? errors[index]["actual_amount"]: ""}
                                                    onChange={handleInputChange("actual_amount", index)}
                                                    InputProps={{
                                                        startAdornment: getCurrencySymbol(currency),
                                                        inputComponent: NumericFormatCustom,
                                                    }}
                                                />
                                            }
                                        </TableCell>
                                        <TableCell width={180} component="th" scope="row">
                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                <DatePicker
                                                    disablePast
                                                    readOnly={ disabled }
                                                    value={bill.bill_date&&bill.bill_date!="Invalid Date" ? parse(bill.bill_date, "y-MM-dd", new Date()) : null}
                                                    onChange={handleCalendarChange("bill_date", index)}
                                                    renderInput={(params) => <TextField fullWidth {...params} error={errors[index] ? "bill_date" in errors[index] : false} helperText={errors[index] ? errors[index]["bill_date"] : ""}/>}
                                                />
                                            </LocalizationProvider>
                                        </TableCell>
                                        <TableCell width={600} align="left">
                                            {bill.notes?.includes("(imported)")?<>{bill.notes}</>:
                                            <TextField 
                                            placeholder="client email / terms / legal name / billing address"
                                            fullWidth 
                                            value={bill.notes || ""} 
                                            onChange={handleInputChange("notes", index)}
                                            error={errors[index] ? "notes" in errors[index] : false} helperText={errors[index] ? errors[index]["notes"]: ""}
                                            InputProps={{
                                                readOnly: disabled
                                            }}
                                            ></TextField>}
                                        </TableCell>
                                        <TableCell align="left">{bill.sent==undefined ? null : (bill.sent ? <CheckCircleOutlineRoundedIcon color="success"/> : <HighlightOffRoundedIcon color="error"/>)}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            }

            { disabled ? null : 
                <Grid container sx={{paddingLeft: "16px", justifyContent: "space-between"}}>
                    <Grid item xs={12} md={12}>
                        <Divider sx={{width: "100%", marginTop: 2, marginBottom: 2}}/>
                    </Grid>
                    <Grid container item xs={6}>
                        {localBills.length ? (
                            <Grid item xs={12} md={12}>
                                <Tooltip title="Add bill schedule to main bid">
                                    <IconButton onClick={handleBillAdd} aria-label="add" size="large" sx={{marginRight: 3}}>
                                        <AddCircleIcon sx={{transform: "scale(1.5)"}} fontSize="large" />
                                    </IconButton>
                                </Tooltip>
                                Add new bill
                            </Grid>
                        ) : null}
                    </Grid>

                    <Grid item xs={6} textAlign={"right"}>
                        {isBillsPending ? <CircularProgress/> : <Button sx={{height:"100%"}} onClick={handlePostAndPutBills}>SAVE BILLS</Button>}
                    </Grid>
                </Grid>
            }
        </>
    )
}
