import { useDispatch, useSelector } from 'react-redux'
import { Droppable } from "react-beautiful-dnd"
import styled from "styled-components"

import { request } from "@/Api";
import BidInsert from "@/bid/BidInsert"
import BidAccordion from "@/bid/BidAccordion"
import lineItemCells from "@/bid/lineItemCells"
import LineItemRow from "@/bid/LineItemRow";
import { BID_CATEGORY_PRODUCTION_SERVICES } from "@/bid/constants";
import { countryCurrencies } from "@/constants";
import { addLine, selectGroup, selectGroupIsVisible, getLineItemRate, searchLines } from "@/bid/bidSlice";

const Table = styled.div`
    width: 100%
    margin: auto;
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding-bottom: 12px;
`
const Row = styled.div`
    display: flex;
    align-items: center;
`

export default function BidCategory(props) {
    const { categoryId, draggableId, readOnly, canViewConfidentialData, serviceItems, officeCurrency, currency, isTemplate, sendSocketMessage } = props
    const category = useSelector(state => selectGroup(state, categoryId))
    const isVisible = useSelector(state => selectGroupIsVisible(state, categoryId))
    const dispatch = useDispatch()
    
    const filteredServiceItems = serviceItems.filter(item =>
        item.category == category.name || (category.is_makers_fees && item.category == BID_CATEGORY_PRODUCTION_SERVICES)
    ).map((item)=>item.name)

    const createLine = (value) => {
        if(!value){return}

        const baseData = serviceItems.find(item =>
            item.name == value && (item.category==category.name || (category.is_makers_fees && item.category == BID_CATEGORY_PRODUCTION_SERVICES))
        ) || {name: value}
        
        request.post("/line-items", {
            ...baseData, 
            bid_id: category.bid_id,
            line_group_id: category.id,
            service_item_id: baseData.id || null,
            vendor_currency: baseData.country_code ? countryCurrencies[baseData.country_code] : officeCurrency || "CAD",
            vendor_rate : getLineItemRate(0, baseData, category.rate_type),
            markup: category.markup,
            sort_order: category.line_items.length + 1,
            days: category.rate_type == "Flat rate" ? 1 : 0,
            units: 0
        })
        .then((response)=>{
            dispatch(addLine(response.data))
            dispatch(searchLines())
            sendSocketMessage({type: "addLine", lineId: response.data.id})
        })
    }

    if (!isVisible) {
        return null
    }

    return (
        <BidAccordion {...props} lineGroup={category} total={category.client_quoted} initialExpanded={category.initialExpanded ? true : false}>
            <Table>
                <Row>
                    {lineItemCells.map((col, index) => col.renderTitle({
                        key: `item_col_title_${index}`,
                        align: col.align,
                        minWidth: col.minWidth,
                        grow: col.grow,
                        rateType: category.rate_type,
                        canViewConfidentialData,
                        readOnly,
                    }))}
                </Row>
                   
                <Droppable type="line" droppableId={draggableId}>
                    {(provided)=>
                        <div {...provided.droppableProps} ref={provided.innerRef} style={{minHeight: "34px"}}>
                            {category.line_items.map((lineItemId, lineItemIndex)=>{
                                return (
                                    <LineItemRow
                                        key={`line_${lineItemId}`}
                                        lineItemId={lineItemId}
                                        index={lineItemIndex}
                                        serviceItems={serviceItems}
                                        currency={currency}
                                        readOnly={readOnly}
                                        isTemplate={isTemplate}
                                        canViewConfidentialData={canViewConfidentialData}
                                        draggableId={`line_${lineItemId}`}
                                        sendSocketMessage={sendSocketMessage}
                                    />
                                )
                            })}      
                            { provided.placeholder }
                        </div>
                    }
                </Droppable>
            </Table>

            {readOnly ? null : (
                <BidInsert
                    type="line"
                    options={filteredServiceItems}
                    createItem={createLine}
                />
            )}
        </BidAccordion>
    )
}
