import styled from "styled-components"

import { useTheme } from "@mui/material"
import { visuallyHidden } from '@mui/utils'
import Table from "@mui/material/Table"
import TableBody from "@mui/material/TableBody"
import TableCell from "@mui/material/TableCell"
import TableContainer from "@mui/material/TableContainer"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from '@mui/material/TableSortLabel'
import Box from '@mui/material/Box'

import { StickyCell } from "@/common/StyledComponents"

export const CustomRow = styled(TableRow)`
    &:last-child td, &:last-child th: { border: 0 }
` 

export default function CustomTable(props) {
    const { setOrder, headCells, order, sign, rows, rowComponent } = props
    const Row = rowComponent
    const theme = useTheme()

    const createOrderHandler = (newOrder) => (event) => {
        setOrder(((newOrder == order) ? (sign == "+" ? "-" : "+") : "+") + newOrder)
    }

    return (
        <TableContainer sx={{ maxHeight: "70vh" }}>
            <Table size="small" sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead sx={{
                    whiteSpace: "nowrap",
                    position: "sticky",
                    top: 0,
                    zIndex: 2}}
                >
                    <TableRow>
                        {headCells.map((headCell) => {
                            let CustomHeadCell = headCell.sticky ? StickyCell : TableCell
                            return (
                                <CustomHeadCell
                                    key={headCell.id}
                                    variant="head"
                                    align={headCell.numeric ? 'right' : 'left'}
                                    padding={headCell.disablePadding ? 'none' : 'normal'}
                                    sx={{ background: theme.palette.background.default }}
                                >
                                    {(headCell.orderable) ? (
                                        <TableSortLabel
                                            active={order === headCell.id}
                                            direction={order === headCell.id ? (sign == "+" ? "asc" : "desc") : "asc"}
                                            onClick={createOrderHandler(headCell.orderBy || headCell.id)}
                                        >
                                            {headCell.label}
                                            {order === headCell.id ? (
                                                <Box component="span" sx={visuallyHidden}>
                                                    {sign == "-" ? 'sorted descending' : 'sorted ascending'}
                                                </Box>
                                            ) : null}
                                        </TableSortLabel>
                                    ) : (
                                        <Box>{headCell.label}</Box>
                                    )}
                                </CustomHeadCell>
                            )})
                        }
                    </TableRow>
                </TableHead>
     
                <TableBody>
                    {rows.map((row, index) => (
                        <Row key={index} {...row} />
                    ))}
                </TableBody>

            </Table>
        </TableContainer>
    )
}
