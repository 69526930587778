import { useState, useRef } from "react"

import { Modal, Avatar, useTheme, IconButton, CircularProgress } from "@mui/material"
import CreateOutlinedIcon from "@mui/icons-material/CreateOutlined"
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded'

import { request } from "@/Api"
import { CustomBox, Heading } from "@/common/StyledComponents"

export function ProfileImagePopup({closePopup, profileType, profileId, profilePicUrl, setProfilePicUrl, profileImagePopupOpen}){
    const theme = useTheme()
    const fileUploaderRef = useRef()
    const [ loading, setLoading ] = useState(false)

    const handleFilesSelect = () => {
        
        const file = fileUploaderRef.current.files[0]
        if (!file) {
            return
        }
        
        const formData = new FormData()
        formData.append("file", file, file.name)
        formData.append("person_profile_id", profileId)

        setLoading(true)
        
        request.post(`${profileType}s/${profileId}/image`, formData)
        .then(response=>{
            setProfilePicUrl(response.data.image ? response.data.image.url_orig : null)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleDelete = () => {
        if (!profilePicUrl){
            return
        }
        
        setLoading(true)

        request.delete(`${profileType}s/${profileId}/image`)
        .then(response => {
            setProfilePicUrl(null)
        })
        .finally(() => {
            setLoading(false)
        })
    }

    return (
        <Modal open={profileImagePopupOpen} onClose={closePopup}>
            <CustomBox style={{width: 200, height: 200, background: theme.palette.background.paper, padding: 40, overflow: "auto"}}>
                <div style={{textAlign: "center"}}>
                    <Heading style={{paddingBottom: 10}}>Edit Profile Picture</Heading>
                    <div style={{width: "100%", textAlign: "center", display: "flex", justifyContent: "center"}}>
                        <div style={{position: "relative", width: 160, height: 160}}>
                            <Avatar sx={{width: "100%", height: "100%", opacity: loading ? 0.1 : 1 }} src={profilePicUrl}/>

                            <IconButton
                                onClick={handleDelete}
                                disabled={loading}
                                size="small"
                                style={{
                                    position: "absolute",
                                    width: 25,
                                    height: 25,
                                    background: theme.palette.background.paper,
                                    bottom: 12,
                                    left: 12,
                                    borderRadius: "50%"
                                }}
                            >
                                <DeleteForeverRoundedIcon fontSize="tiny" color="error"/>
                            </IconButton>

                            <IconButton
                                onClick={()=>{fileUploaderRef.current.click()}}
                                disabled={loading}
                                size="small"
                                style={{
                                    position: "absolute",
                                    width: 25,
                                    height: 25,
                                    background: theme.palette.background.paper,
                                    bottom: 12,
                                    right: 12,
                                    borderRadius: "50%"
                                }}
                            >
                                <CreateOutlinedIcon fontSize="tiny"/>
                            </IconButton>
                        </div>

                        {loading && (
                            <CircularProgress sx={{position: "absolute", top: "50%"}} />
                        )}
                    </div>
                </div>
                <input ref={fileUploaderRef} type="file" hidden onChange={handleFilesSelect} accept="image/png, image/jpeg, image/webp"/>
            </CustomBox>
        </Modal>
    )
}