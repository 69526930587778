import { parse } from "date-fns"
import { request } from "@/Api"

function clampNumber (number, min, max){
    return Math.min(Math.max(number, min), max)
}

function queryToFilterValue(query){
    const value = query ? query.split(":")[1] : null
    return value == null ? null : isNaN(Number(value)) ? value : Number(value)
}

function dateQueryToFilterValue(query){
    if (!query){
        return null
    }
    const date = parse(query.split(":")[1]+" 00:00:00", "y-MM-dd HH:mm:ss", new Date())
    return date == "Invalid Date" ? null : date
}

function formattedNumber(number){
    const options = {
        style: 'decimal',
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };
    return parseFloat(number).toLocaleString('en-US', options)
}

function formattedCurrency(number, currency, trimSymbol=false, digits=0){
    number = parseFloat(number)
    if(isNaN(number)) {
        return ""
    }
    
    if (!currency) {
        return number.toLocaleString()
    }

    if (trimSymbol) {
        let symbol = getCurrencySymbol(currency)
        symbol = symbol.charAt(symbol.length - 1)
        return symbol + number.toLocaleString(undefined, {
            minimumFractionDigits: digits,
            maximumFractionDigits: digits,
          })
    }

    return (currency=="CAD" ? "CA" : "") + new Intl.NumberFormat("en-CA", {
        style: "currency",
        currency: currency,
        maximumFractionDigits: digits,
    }).format(number)
}

function getCurrencySymbol(currency){
    if (!currency){
        return ""
    }
    try{
        return (currency=="CAD" ? "CA" : "") + (0).toLocaleString("en-CA", { style: 'currency', currency, minimumFractionDigits: 0, maximumFractionDigits: 0 }).replace(/\d/g, '').trim()
    }catch{
        return ""
    }
} 

function statusQueryToFilterValue(query) {
    return query ? query.split(":")[1].split(",") : []
}

function getDataFromQueryParams(defaults){
    const queryParams = window.location.search
    let parsedParams = new URLSearchParams(queryParams)

    let allDefault = false
    const page = parsedParams.has("offset") && parsedParams.has("limit") ? (parseInt(parsedParams.get("offset") / parsedParams.get("limit"))) : defaults.page   
    const limit = parsedParams.has("limit") ? parseInt(parsedParams.get("limit")) : defaults.limit
    const orders = parsedParams.has("orders") ? parsedParams.get("orders") : defaults.orders

    parsedParams.delete("orders")
    parsedParams.delete("offset")
    parsedParams.delete("limit")

    let filters = {}
    for(const [key, value] of parsedParams) {
        if(parsedParams.getAll(key).length > 1){
            filters[key] = parsedParams.getAll(key)
        }else{
            filters[key] = value
        }
    }

    if (page == defaults.page && limit == defaults.limit && orders == defaults.orders && Object.keys(filters).length == 0){
        allDefault = true
    }
    
    return {page: page, limit: limit, orders: orders, filters: filters, allDefault: allDefault}
}

const downloadPdfFromHtmlString = (htmlString, name) => {
    request.post("/pdf", {html_string: htmlString, name: name}, {responseType: 'blob'})
    .then((response)=>{
        downloadPdfFromResponse(response)
    })
}

const downloadFile = (url, params = {}, callback) => {
    request.get(url, {
        responseType: "blob",
        params: params,
    })
    .then((response)=>{
        downloadPdfFromResponse(response)
        if(callback) {
            callback()
        }
    })
    .catch(error => {
        error.defaultErrorResponse()
    })
}

const downloadPdfFromResponse = (response) => {
    const href = URL.createObjectURL(response.data)
    const filename = response.headers["content-disposition"].split("=")[1]

    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()

    // Cleanup
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
}

function generateRandomColor() {
    var minBrightness = 60
    var maxBrightness = 220
    var red = Math.floor(Math.random() * (maxBrightness - minBrightness + 1)) + minBrightness;
    var green = Math.floor(Math.random() * (maxBrightness - minBrightness + 1)) + minBrightness;
    var blue = Math.floor(Math.random() * (maxBrightness - minBrightness + 1)) + minBrightness;
    var colorHex = "#" + ((1 << 24) | (red << 16) | (green << 8) | blue).toString(16).slice(1);
    return colorHex;
}



function findCommonElements(arrays) {
    const referenceArray = arrays[0]
    if (!referenceArray){
        return
    }
    const commonElements = referenceArray.filter(element =>
        arrays.every(array => array.includes(element))
    )
    return commonElements;
}

function getNth(n){
    return["st","nd","rd"][((n+90)%100-10)%10-1]||"th"
}

function getDeltaDays(date1, date2) {
    if (!date1 || !date2){
        return null
    }
    var date1_ms = date1.getTime();
    var date2_ms = date2.getTime();
    var difference_ms = date2_ms - date1_ms;
    return Math.floor(difference_ms / (1000 * 60 * 60 * 24));
}

function abbreviateNumber(number){
    return Intl.NumberFormat('en-US', {
        notation: "compact"
    }).format(number);
}

function parseVideoUrlToEmbedUrl(url){
    if (url.includes("youtube")){
        if (url.includes("embed")){
            return url
        }else{
            return url.replace("watch?v=", "embed/").split("&")[0]
        }
    }
    else if (url.includes("youtu.be")){
        return url.replace("youtu.be", "youtube.com/embed")
    }
    else if (url.includes("vimeo")){
        if (url.includes("player.vimeo.com/video/")){
            return url + "?width=2000"
        }
        else{
            return url.replace("vimeo.com", "player.vimeo.com/video").replace("?share=copy", "")
        }
    }
    else if (url.includes("drive.google.com")){
        if (url.includes("preview")){
            return url
        }else{
            return url.replace("view", "preview")
        }
    }
    return url
}

function parseNameToInitials(name){
    const words = name.split(" ")
    const initialsNum = 2
    let initials = ""
    for (var i = 0; i < initialsNum; i ++){
        if (!words[i]){
            return initials
        }
        initials += words[i].substring(0, 1).toUpperCase();
    }
    return initials
}

export { 
    formattedCurrency,
    getCurrencySymbol,
    queryToFilterValue, 
    dateQueryToFilterValue, 
    statusQueryToFilterValue, 
    getDataFromQueryParams,
    downloadFile,
    downloadPdfFromHtmlString,
    generateRandomColor,
    formattedNumber,
    findCommonElements,
    getNth,
    getDeltaDays,
    clampNumber,
    abbreviateNumber,
    parseVideoUrlToEmbedUrl,
    parseNameToInitials
}
