import { useEffect, useState, useRef } from 'react'
import { format } from "date-fns"
import styled from 'styled-components'

import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import { Button, Divider } from '@mui/material'
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";

import { request } from "@/Api"

const CommentButtonContainer = styled.div`
    text-align: right;
    margin-top: 12px;
`

export default function Comments(props) {
    const {comments, modelType, modelInstanceId} = props

    const commentsRef = useRef()
    const autocompleteRef = useRef()
    const [comment, setComment] = useState("")
    const [localComments, setLocalComments] = useState([])
    const [autocompleteOpen, setAutocompleteOpen] = useState(false)
    const [isCommentPending, setIsCommentPending] = useState(false)
    const [persons, setPersons] = useState([])

    const getPersons = () => {
        request.get("persons", { 
            params: {
                offset: 0,
                limit: 10000,
            }
        })
        .then((response)=>{
            setPersons(response.data)
        })
    }

    const handleOnCommentChange = (e) => {
        const cursorPos = e.target.selectionStart
        const cursorChar = e.target.value.charAt(cursorPos - 1)
        if (cursorChar == "@"){
            setAutocompleteOpen(true)
        }
        setComment(e.target.value)
    }

    const handleAutocompleteChange = (event, id) => {
        commentsRef.current.focus()
        const cursorPos = commentsRef.current.selectionStart
        setComment([comment.slice(0, cursorPos), persons.find((person)=>person.id==id)?.email, " ", comment.slice(cursorPos)].join(''))
        setAutocompleteOpen(false)
    }

    useEffect(()=>{
        getPersons()
    }, [])
    useEffect(()=>{
        (autocompleteOpen && autocompleteRef.current) ? autocompleteRef.current.focus() : null
    }, [autocompleteOpen])
    
    useEffect(()=>{
        setLocalComments([...comments])
    }, [comments])

    const handleOnComment = () => {
        setIsCommentPending(true)
        request.post("/comments", {
            "description": comment,
            "model_type": modelType,
            "model_instance_id": modelInstanceId
        })
        .then((response)=>{
            setComment("")
            setLocalComments([...localComments, response.data])
            
        })
        .finally(() => {
            setIsCommentPending(false)
        })
    }

    const handleResolve = (id) => () => {
        request.delete("/comments/"+id)
        .then(()=>{
            setLocalComments(localComments.map(comment=>{
                return (comment.id==id) ? {...comment, "archived_at": true} : comment
            }))
        })
    }

    return(
        <div>
            {autocompleteOpen ?
                <Autocomplete
                    autoHighlight
                    open={true}
                    fullWidth
                    disablePortal
                    options={persons.map((person)=>person.id.toString())}
                    getOptionLabel={(id) => persons.find((person)=>person.id==id)?.email || ""}
                    renderInput={(params) => <TextField inputRef={autocompleteRef} {...params} label="Producer" />}
                    onChange={handleAutocompleteChange}
                />
            : null}

            <TextField
                placeholder='Comment...'
                onFocus={()=>{setAutocompleteOpen(false)}}
                inputRef={commentsRef}
                value={comment}
                onChange={handleOnCommentChange}
                fullWidth
                multiline
                rows={2}
            />
            <CommentButtonContainer>
                { isCommentPending ? <CircularProgress /> : <Button variant="contained" onClick={handleOnComment}>Comment</Button> }
            </CommentButtonContainer>

            <div>
                {localComments.map((comment, index) => (
                    <Comment key={index} comment={comment} handleResolve={handleResolve}/>
                ))}
            </div>
        </div>
    )
}

export function Comment({comment, handleResolve, showProjectLink}) {
    return (
        <Grid container key={comment.id} xs={12} md={12} spacing={1} sx={{ marginTop: "24px;"}}>  
            <Grid container sx={{width: "100%"}}>
                <Grid item md={2} maxWidth={65}>
                    <IconButton sx={{ height: '50px', width: '50px' }}>
                        <Avatar imgProps={{referrerPolicy:"no-referrer"}} />
                    </IconButton>
                </Grid>
                <Grid item md={10} container sx={{minWidth: 250}}>
                    <Grid item>{comment.person_name}</Grid>
                    <Grid item sx={{color: "grey"}}>{format(new Date(comment.created_at), "y-MM-dd")}</Grid>
                    <Grid item xs={12} md={12}>{comment.description}</Grid>
                </Grid>
            </Grid>

            <Grid xs={12} md={12} textAlign={"right"}>
                {
                    showProjectLink ? 
                    <Button href={`/${comment.model_type}s/${comment.model_instance_id}`}>{`Go to ${comment.model_type}`}</Button> :
                    <>{comment.archived_at ? <Button disabled>Resolved</Button>:<Button onClick={handleResolve(comment.id)}>Resolve</Button>}</>
                }
            </Grid>
            <Divider sx={{width:"100%"}}/>
        </Grid>
    )
}