export const BID_TYPE_MAIN = "Main"
export const BID_TYPE_ALT = "Alt"
export const BID_TYPE_RD = "R&D"
export const BID_TYPE_CO = "Change Order"

export const BID_TYPES = [
    BID_TYPE_MAIN,
    BID_TYPE_ALT,
    BID_TYPE_RD,
    BID_TYPE_CO,
]

export const BID_SECTION_PRODUCTION_SERVICES = "Production Services"
export const BID_SECTION_TALENT = "Talent"
export const BID_SECTION_TRAVEL = "Travel"
export const bidSections = [
    BID_SECTION_PRODUCTION_SERVICES,
    "Pre-Production",
    "Production",
    "Post-Production",
    BID_SECTION_TALENT,
    BID_SECTION_TRAVEL,
    "Hair, Make-Up, Wardrobe",
    "Stills",
    "Locations",
    "COVID-19",
    "Art",
    "Digital",
    "Events"
]

export const BID_CATEGORY_PRODUCTION_SERVICES = "Production Services"
export const bidCategories = [
    BID_CATEGORY_PRODUCTION_SERVICES,
    "Crew",
    "Gear",
    "Data Handling",
    "Talent",
    "Locations",
    "Expenses"
]

export const levelOfDetails = ["LOW", "MEDIUM", "HIGH"]
export const lineItemDetailsType = ["SHOW", "HIDE", "SHOW WITHOUT HOURS RATE"]
export const resourceTypes = ["External", "Internal"]

export const BID_STATUS_DRAFT = "Drafts"
export const BID_STATUS_PENDING_EP = "Pending EP Approval"
export const BID_STATUS_PENDING_FINANCE = "Pending Finance Approval"
export const BID_STATUS_APPROVED = "EP Approved"
export const BID_STATUS_PENDING_SIGNATURE = "Pending Signature"
export const BID_STATUS_SIGNED = "Signed by Client"
export const bidStatuses = [
    BID_STATUS_DRAFT, 
    BID_STATUS_PENDING_EP,
    BID_STATUS_PENDING_FINANCE,
    BID_STATUS_APPROVED,
    BID_STATUS_PENDING_SIGNATURE,
    BID_STATUS_SIGNED,
]

export const BID_ACTIVITY_SUBMITTED_EP = "submitted for EP approval"
export const BID_ACTIVITY_SUBMITTED_FINANCE = "submitted for Finance approval"
export const BID_ACTIVITY_CANCELLED = "cancelled"
export const BID_ACTIVITY_APPROVED = "approved"
export const BID_ACTIVITY_REJECTED = "rejected"
export const BID_ACTIVITY_REQUESTED_SIGNATURE = "requested signature"
export const BID_ACTIVITY_SIGNED = "signed"
export const BID_ACTIVITY_DOWNLOADED = "downloaded for signature"

export const RATE_TYPE_PERCENTAGE = "Percentage"
export const RATE_TYPE_DAYS = "Days"
export const RATE_TYPE_HOURS = "Hours"
export const RATE_TYPE_FLAT_RATE = "Flat rate"
export const rateTypes = [RATE_TYPE_HOURS, RATE_TYPE_DAYS, RATE_TYPE_FLAT_RATE, RATE_TYPE_PERCENTAGE]

export const INTERNAL_MARKUP = 0
export const HOURS_PER_DAY = 8

export const TOPSHEET_SIGNATURE_LOCATION_IDENTIFYER_STRING = "SIGNATURE"
export const TOPSHEET_NAME_LOCATION_IDENTIFYER_STRING = "________NAME________"
export const TOPSHEET_DATE_LOCATION_IDENTIFYER_STRING = "________DATE________"
