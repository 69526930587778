import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom" 

import { useTheme } from "@mui/material"
import Modal from "@mui/material/Modal"
import TextField from "@mui/material/TextField"
import Button from "@mui/material/Button"
import CircularProgress from "@mui/material/CircularProgress"

import { CustomBox } from "@/common/StyledComponents"
import { request } from "@/Api"

export default function BidCopyPopup({bid, setBid}){
    const theme = useTheme()
    const navigate = useNavigate()
    const [ bidName, setBidName ] = useState("")
    const [ isCopying, setIsCopying ] = useState(false)

    useEffect(()=>{
        if(bid){
            setBidName(`Copy of ${bid.name}`)
        }
    }, [bid])

    const copyBid = () => {
        setIsCopying(true)
        request.post("/bids/copy", {
            bid_name: bidName,
            project_id: bid.project_id,
            bid_id: bid.id,
            is_change_order: bid.is_change_order,
        })
        .then((response)=>{
            window.location = `/bids/${response.data.id}`
            setBid(null)
            navigate(`/bids/${response.data.id}`, 0)
            setIsCopying(false)
        })
    }
    const handleInput = (event) => {
        setBidName(event.target.value)
    }

    if (!bid){
        return null
    }

    return(
        <Modal
            sx={{overflow: "scroll"}}
            open={bid != null}
            onClose={()=>{setBid(null)}}
        >
            <CustomBox sx={{ background: theme.palette.background.default }}>
                <h1>Name your copy</h1>
                <TextField
                    fullWidth
                    defaultValue={`Copy of ${bid.name}`}
                    onInput={handleInput}
                    sx={{marginBottom: 3}}
                />
                <div style={{width: "100%", textAlign: "right"}}>
                    {isCopying ? <CircularProgress /> :
                    <Button variant="contained" onClick={copyBid}>Copy!</Button>}
                </div>
            </CustomBox>
        </Modal>
    )
}