import { useState, useEffect } from "react"

import { Grid, TextField } from "@mui/material"

import { request } from "@/Api"
import { AccountSectionRow, AccountSubtitle, AccountInput } from "@/account/StyledComponents"
import { SectionEdit } from "@/account/CommonComponents"

export default function PersonalSection({ data, setSaved, setError, setData }) {
    const [ editing, setEditing ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ firstName, setFirstName ] = useState(data.first_name)
    const [ lastName, setLastName ] = useState(data.last_name)
    const [ email, setEmail ] = useState(data.email)
    const [ handle, setHandle ] = useState(data.handle)

    const handleSave = () => {
        setError("")
        setLoading(true)

        request.patch('account', {first_name: firstName, last_name: lastName, email, handle}).then(response => {
            setEditing(false)
            setData({
                ...data,
                name: response.data.name,
                first_name: response.data.first_name,
                last_name: response.data.last_name,
                email: response.data.email,
                handle: response.data.handle,
            })
            setSaved(true)
        }).catch(error => {
            const msg = Array.isArray(error.response.data.detail) ? error.response.data.detail.map(e => e.msg).join(", ") : error.response.data.detail
            setError(msg || "Failed to save")
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setEditing(false)
        setFirstName(data.first_name)
        setLastName(data.last_name)
        setEmail(data.email)
        setHandle(data.handle)
    }

    useEffect(() => {
        setFirstName(data.first_name)
        setLastName(data.last_name)
        setEmail(data.email)
        setHandle(data.handle)
    }, [data])

    return (
        <Grid item>
            <SectionEdit
                title="Personal Info"
                editing={editing}
                loading={loading}
                handleEdit={() => setEditing(true)}
                handleSave={handleSave}
                handleCancel={handleCancel}
            />
            
            <AccountSectionRow>
                <AccountSubtitle>Name</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <Grid container spacing={2} justifyContent="flex-end" sx={{width:"100%", left: "16px", position: "relative"}}>
                            <Grid item flexGrow={1}>
                                <TextField
                                    value={firstName}
                                    onChange={event => setFirstName(event.target.value)}
                                    required
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                            <Grid item flexGrow={1}>    
                                <TextField
                                    value={lastName}
                                    onChange={event => setLastName(event.target.value)}
                                    required
                                    variant="filled"
                                    fullWidth
                                    InputProps={{
                                        disableUnderline: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                    ) : (
                        <p>{data.name}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            <AccountSectionRow>
                <AccountSubtitle>Email</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <TextField
                            value={email}
                            onChange={event => setEmail(event.target.value)}
                            type="email"
                            required
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p>{data.email}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            <AccountSectionRow>
                <AccountSubtitle>Handle</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <TextField
                            value={handle}
                            onChange={event => setHandle(event.target.value)}
                            required
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p>{data.handle}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
        </Grid>
    )
}
