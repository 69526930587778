export const PROJECT_STATUS_ON_RADAR = "On Radar"
export const PROJECT_STATUS_PITCHING = "Pitching"
export const PROJECT_STATUS_BRIEFED = "Briefed"
export const PROJECT_STATUS_IN_PRODUCTION = "In Production"
export const PROJECT_STATUS_ON_HOLD = "On Hold"
export const PROJECT_STATUS_ONGOING = "Ongoing"
export const PROJECT_STATUS_CANCEL_IN_REVIEW = "Cancel In Review"
export const PROJECT_STATUS_WRAP_IN_REVIEW = "Wrap In Review"
export const PROJECT_STATUS_WRAPPED = "Wrapped"
export const PROJECT_STATUS_CANCELLED = "Cancelled"

export const CUSTOM_BILLING_SCHEDULE = "Custom"
export const billingSchedules = [
    "100%",
    "90% / 10%",
    "75% / 25%",
    "50% / 50%",
    "50% / 25% / 25%",
    "60% / 30% / 10%",
    CUSTOM_BILLING_SCHEDULE,
]

export const forecastCertainty = ["Confident", "Stretch"]

export const milestoneReminderOptions = [
    {
        label: "1 day before",
        delta: 1
    },
    {
        label: "1 week before",
        delta: 7
    },
]