import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import styled from 'styled-components'

import { useTheme } from '@mui/material'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done'

import { request } from "@/Api"
import { addActivity, selectInsightGoals, selectInsights, selectBillValidations } from "@/bid/bidSlice"
import { TargetsPass, TargetsWarning, TargetsError } from "@/common/StyledComponents"
import * as constants from "@/bid/constants"

const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    padding: 24px;
    border-radius: 20px;
`
const Title = styled.h2`
    margin: 0 0 24px 0;
    font-size: 28px;
    font-weight: normal;
`
const Subtitle = styled.h3`
    margin: 2px 0 24px 0;
    font-size: 14px;
    font-weight: 600;
`
const B = styled.span`
    font-weight: 600;
`

export default function BidActionPopup( props ){
    const { bidId, action, isFinance, open, setOpen } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const goals = useSelector(selectInsightGoals)
    const insights = useSelector(selectInsights)
    const billValidations = useSelector(selectBillValidations)
    const [ loading, setLoading ] = useState(false)
    const [ note, setNote ] = useState("")

    const targets = [
        {
            title: "producer fee portion",
            value: insights.producer_fee_quoted_percentage,
            goal: goals.producer_fee_quoted_goal,
            financeApproval: false,
            hidePass: false,
        },
        {
            title: "gross profit margin",
            value: insights.gross_profit_margin,
            goal: goals.gross_profit_goal,
            financeApproval: true,
            hidePass: false,
        },
        {
            title: "net profit margin",
            value: insights.net_profit_margin,
            goal: goals.net_profit_goal,
            financeApproval: true,
            hidePass: false,
        },
        {
            title: "insurance",
            value: insights.insurance,
            goal: goals.default_insurance,
            financeApproval: false,
            hidePass: true,
            failMessage: "Insurance costs have been modified and are less than expected.",
        },
        {
            title: "billing schedule",
            value: billValidations.billsLength,
            goal: 1,
            financeApproval: false,
            hidePass: true,
            failMessage: "Billing schedule is missing.",
            required: true,
        },
        {
            title: "billing schedule dates",
            value: billValidations.hasDates,
            goal: 1,
            financeApproval: false,
            hidePass: true,
            failMessage: "Billing schedule has missing dates.",
            required: true,
        },
        {
            title: "billing schedule actuals",
            value: billValidations.hasActuals,
            goal: 1,
            financeApproval: false,
            hidePass: true,
            failMessage: "Billing schedule has missing actuals.",
            required: true,
        },
        {
            title: "billing schedule actuals",
            value: billValidations.actualMatchesQuoted,
            goal: true,
            financeApproval: false,
            hidePass: true,
            failMessage: "Billing total doesn't match quoted amount.",
            required: true,
        },
        {
            title: "billing schedule total",
            value: billValidations.totalPercentage,
            goal: 100,
            financeApproval: false,
            hidePass: true,
            failMessage: "Billing schedule does not add up to 100%.",
            required: true,
        },
    ]

    // Seperate targets into pass/fail
    const pass = targets.filter(x => x.value >= x.goal && !x.hidePass)
    const fail = targets.filter(x => x.value < x.goal)
    const finanaceApprovalRequired = action == constants.BID_ACTIVITY_APPROVED && fail.filter(x => x.financeApproval).length > 0 && !isFinance
    const warnings = fail.filter(x => !x.required)
    const errors = fail.filter(x => x.required)
    
    const handleClose = () => {
        setOpen(false)
    }

    const handleActivity = (action) => () => {
        setLoading(true)
        request.post(`/bids/${bidId}/activity`, {action: action, note: note})
        .then(response => {
            setNote("")
            dispatch(addActivity(response.data))
        })
        .finally(() => {
            setOpen(false)
            setLoading(false)
        })
    }

    return (
        <Modal
            sx={{overflow: "scroll"}}
            open={open}
            onClose={handleClose}
        >
            <CustomBox sx={{backgroundColor: theme.palette.background.default}}>
                <Title>
                    {
                        action == constants.BID_ACTIVITY_SUBMITTED_EP ? `Submit to EP for Approval` :
                        action == constants.BID_ACTIVITY_REJECTED ? `Reject bid` :
                        finanaceApprovalRequired ? `Submit to Finance for Approval` :
                        action == constants.BID_ACTIVITY_APPROVED ? 'Approve bid' :
                        `Invalid action`
                    }
                </Title>
                
                { errors.length > 0 ? (   
                    <TargetsError>
                        <Grid container spacing={1}>
                            <Grid item><ErrorIcon color="error" /></Grid>
                            <Grid item>
                                <Subtitle>Required</Subtitle>
                                { errors.map((x, i) => (x.failMessage ? <p key={i}>{x.failMessage}</p> :
                                    <p key={i}>
                                        {`The bid's ${x.title} is `}
                                        <B>{Math.round(x.value * 100)}%</B>
                                        {` which is below Makers' target of ${Math.round(x.goal * 100)}%.`}
                                    </p>
                                )) }
                            </Grid>
                        </Grid>
                    </TargetsError>
                ) : null }

                { warnings.length > 0 ? (   
                    <TargetsWarning>
                        <Grid container spacing={1}>
                            <Grid item><ErrorIcon color="warning" /></Grid>
                            <Grid item>
                                <Subtitle>Attention</Subtitle>
                                { warnings.map((x, i) => (x.failMessage ? <p key={i}>{x.failMessage}</p> :
                                    <p key={i}>
                                        {`The bid's ${x.title} is `}
                                        <B>{Math.round(x.value * 100)}%</B>
                                        {` which is below Makers' target of ${Math.round(x.goal * 100)}%.`}
                                    </p>
                                )) }
                                { action == constants.BID_ACTIVITY_SUBMITTED_EP || (action == constants.BID_ACTIVITY_APPROVED && finanaceApprovalRequired) ? (
                                    <p>Please include a brief note about this project before submitting.</p>
                                ) : null }
                            </Grid>
                        </Grid>
                    </TargetsWarning>
                ) : null }

                { pass.length > 0 ? (   
                    <TargetsPass>
                        <Grid container spacing={1}>
                            <Grid item><CheckCircleIcon color="success" /></Grid>
                            <Grid item>
                                <Subtitle>Good stuff</Subtitle>
                                { pass.map((x, i) => (
                                    <p key={i}>
                                        {`The bid's ${x.title} is `}
                                        <B>{Math.round(x.value * 100)}%</B>
                                        {` which is above Makers' target of ${Math.round(x.goal * 100)}%.`}
                                    </p>
                                )) }
                            </Grid>
                        </Grid>
                    </TargetsPass>
                ) : null }

                { action != constants.BID_ACTIVITY_APPROVED || finanaceApprovalRequired ? (
                    <TextField
                        label={action == constants.BID_ACTIVITY_REJECTED ? `Include note for producer...` : `Include note for approver...`}
                        fullWidth
                        multiline
                        rows={3}
                        value={note}
                        onInput={(event) => setNote(event.target.value)}
                    />
                ) : null }

                <Grid container spacing={2} alignItems="center" sx={{marginTop: "12px"}}>
                    <Grid item flexGrow={1}>
                        <Button
                            fullWidth
                            sx={{backgroundColor: "white", color: "black"}}
                            variant="contained"
                            onClick={handleClose}
                        >
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item flexGrow={1}>
                        { action == constants.BID_ACTIVITY_APPROVED ? (
                            <Button
                                fullWidth
                                color="primary"
                                sx={{color: "black"}}
                                variant="contained"
                                onClick={handleActivity(constants.BID_ACTIVITY_APPROVED)}
                                startIcon={<DoneIcon />}
                                disabled={loading || errors.length > 0}
                            >
                                Approve
                            </Button>
                        ) : action == constants.BID_ACTIVITY_REJECTED ? (
                            <Button
                                fullWidth
                                color="error"
                                sx={{color: "black"}}
                                variant="contained"
                                onClick={handleActivity(constants.BID_ACTIVITY_REJECTED)}
                                startIcon={<CloseIcon />}
                                disabled={loading}
                            >
                                Reject
                            </Button>
                        ) : (
                            <Button
                                fullWidth
                                color="primary"
                                sx={{color: "black"}}
                                variant="contained"
                                onClick={handleActivity(constants.BID_ACTIVITY_SUBMITTED_EP)}
                                startIcon={<SendIcon />}
                                disabled={loading || errors.length > 0}
                            >
                                Send for Approval
                            </Button>
                        ) }
                    </Grid>
                </Grid>
            </CustomBox>
        </Modal>
    )
}
