import { selectOrganizationId } from "@/auth/authSlice"
import { useSelector } from "react-redux"
import { useLocation, useNavigate, useParams } from "react-router-dom"

export function useSetOrgId() {
    const { organizationId } = useParams()

    const location = useLocation()
    const navigate = useNavigate()

    const currentOrganizationid = useSelector(selectOrganizationId)

    if (!organizationId) {
        const newPath = `${location.pathname}/${currentOrganizationid}`;
        navigate(newPath);
    }

    return currentOrganizationid
}