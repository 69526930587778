import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import styled from 'styled-components'

import { useTheme } from "@mui/material"
import Grid from "@mui/material/Grid"
import Box from '@mui/material/Box'
import { Tabs, Tab } from "@mui/material";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import { request } from "@/Api";
import ApiAutocomplete from "@/common/ApiAutocomplete";
import { Subtitle, Title } from "@/common/StyledComponents";
import { BID_STATUS_DRAFT } from "@/bid/constants";

import { selectBidPopupStatus, closeBidPopup } from "@/nav/navSlice";
import { CircularProgress } from "@mui/material";

const CustomBox = styled(Box)`
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 20px;
    width: 60%
`

export default function BidPopup(){
    const theme = useTheme()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const popupStatus = useSelector(selectBidPopupStatus)

    const bidPopupOpenType = popupStatus.type
    const project = popupStatus.project

    const [bidTemplates, setBidTemplates] = useState({})
    const [popupState, setPopupState] = useState("templates")
    const [template, setTemplate] = useState(null)
    const [tabValue, setTabValue] = useState(0)
    const [creating, setCreating] = useState(false)

    const createBid = (project) => {
        request.post("/bids", {
            name: `${project.name} ${bidPopupOpenType}`,
            project_id: project.id,
            is_change_order: bidPopupOpenType == "Change Order",
            status: BID_STATUS_DRAFT
        })
        .then((response)=>{
            const newBidId = response.data.id
            navigate("/bids/"+newBidId)
        })
    }

    const createBidFromTemplate = (template, projectId) => {
        request.post("/bids/copy", {
            bid_name: template.name,
            project_id: projectId,
            bid_id: template.bid_id,
            is_change_order: bidPopupOpenType == "Change Order",
            public: template.public
        })
        .then((response)=>{
            const newBidId = response.data.id
            navigate("/bids/"+newBidId)
            dispatch(closeBidPopup())
            setCreating(false)
        })
    }

    const getBidTemplates = () => {
        request.get("/bid-templates")
        .then((response)=>{
            const bid_templates_raw = response.data;
            setBidTemplates(bid_templates_raw.reduce((acc, template) => {
                const category = template.category || "My Templates";
                const tab = template.category ? "Default" : "Custom"
                acc[tab] = acc[tab] || {}
                acc[tab][category] = acc[tab][category] || [];
                acc[tab][category].push(template);
                return acc;
            }, {}))
        })
    }

    useEffect(()=>{
        getBidTemplates();
    }, [])

    const handleAutocompleteChange = (event, newValue) => {
        if (template){
            createBidFromTemplate(template, newValue.id)
        }else{
            createBid(newValue)
        }
    }

    const handleConfirmTemplate = (template) => {
        setCreating(true)
        if(project){
            createBidFromTemplate(template, project.id)
        }else{
            setTemplate(template)
            setPopupState("projects")
        }
    }
    
    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    }
    
    return(
        <CustomBox
            overflow={popupState=="templates" ? "scroll" : ""}
            height={popupState=="templates" ? "80vh" : "auto"}
            sx={{ background: theme.palette.background.default, outline: 0 }}
        >
            {popupState == "projects" ?
            <>
                <Title style={{marginBottom: 30}}>
                    Choose a Project
                </Title>
                <ApiAutocomplete
                    value={null}
                    apiRoute={"projects"}
                    fields={["name"]}
                    onChange={handleAutocompleteChange}
                    label="Project"
                    simple={true}
                    fullOptionData
                />
            </> : popupState == "templates" ?
            <>
                <div style={{marginBottom: 10}}>
                    <Title style={{margin: 0}}>
                        Choose a Template
                    </Title>
                    <Subtitle>
                        {`to create a ${bidPopupOpenType}`}
                    </Subtitle>
                </div>

                <Tabs value={tabValue} onChange={handleTabChange}>
                    {Object.entries(bidTemplates).map((type)=>{
                        return(<Tab label={type[0]} key={type[0]}/>)
                    })}
                </Tabs>
                
                {creating ? <CircularProgress sx={{padding: 2}} /> :
                    <>
                        {Object.entries(bidTemplates).map((type, index)=>{
                            return(<div hidden={index!=tabValue} key={index}>
                                {Object.entries(type[1]).map((category)=>{
                                    return(
                                        <div key={category[0]}>
                                            <p>{category[0]}</p>
                                            <Grid container spacing={2}>
                                                {
                                                    category[1].map((template)=>{
                                                        return(
                                                            <Grid item key={template.id}>
                                                                <Box
                                                                    borderRadius={5}
                                                                    sx={{background: theme.palette.background.paper, height: 150, width: 170, cursor: "pointer"}} // overflow auto resulting blurrieness
                                                                    onClick={()=>{handleConfirmTemplate(template)}}
                                                                >
                                                                    <div style={{padding: 30}}>
                                                                        <ArrowCircleRightIcon fontSize="large"/>
                                                                        <br/>
                                                                        {template.name}
                                                                        <br/>
                                                                    </div>
                                                                </Box>
                                                            </Grid>
                                                        )
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                    )})
                                }
                            </div>)
                        })}
                    </>
                }
            </>: <>{popupState}</>}
        </CustomBox>
    )
}