import { useState } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import { useTheme } from '@mui/material/styles'
import useMediaQuery from "@mui/material/useMediaQuery"

import IconButton from "@mui/material/IconButton"
import Typography from "@mui/material/Typography"
import Menu from "@mui/material/Menu"
import Avatar from "@mui/material/Avatar"
import Tooltip from "@mui/material/Tooltip"
import MenuItem from "@mui/material/MenuItem"
import Grid from "@mui/material/Grid"

import CreateButton from "@/nav/CreateButton"
import ThemeSwitch from "@/nav/ThemeSwitch"
import GlobalSearch from "@/nav/GlobalSearch"
import MobileNavButton from "@/nav/MobileNavButton"
import LogoButton from "@/nav/LogoButton"
import OrganizationMenu from "@/nav/OrganizationMenu"
import { getMenuItemsUser } from "@/nav/menuItems"
import { selectNavTokenContext, selectProfileImage } from "@/auth/authSlice"
import { useIsHidden, useHandlePageGoTo } from "@/nav/navHooks"
import { selectNavOpen } from "@/nav/navSlice"
import { sideNavWidth } from "@/nav/navConstants"

const NavBar = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    padding: ${props => props.isSmall ? "20px 20px 0px 20px" : "20px 40px 0px 40px"};
`

export default function TopNav({ toggleTheme }) {
    const [anchorElUser, setAnchorElUser] = useState(null)
    
    const profileImage = useSelector(selectProfileImage)
    const navTokenContext = useSelector(selectNavTokenContext)
    const isNavOpen = useSelector(selectNavOpen)

    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isHidden = useIsHidden()
    const handlePageGoTo = useHandlePageGoTo()

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget)
    }

    const handleMenuClick = (route) => (event) => {
        event.preventDefault()
        handlePageGoTo(route)
        handleCloseUserMenu()
    }

    const handleCloseUserMenu = () => {
        setAnchorElUser(null)
    }

    if (isHidden) {
        return null
    }

    return isSmall ? (
        <MobileTopNav />
    ) : (
        <NavBar>    
            <div
                style={{
                    float: "left",
                    marginLeft: isNavOpen ? `${sideNavWidth}px` : 0,
                    transition: isNavOpen ? theme.transitions.createMarginOpen(theme) : theme.transitions.createMarginClose(theme),
                }}
            >
                <OrganizationMenu />
            </div>

            <div style={{float: "right"}}>
                <Grid container spacing={2} alignItems={"center"}>
                    <Grid item>
                        <CreateButton />
                    </Grid>
                    <Grid item>
                        <ThemeSwitch
                            checked={theme.palette.mode != "dark"}
                            onChange={toggleTheme}
                        />
                    </Grid>
                    <Grid item>
                        <Tooltip title="Open settings">
                            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                                <Avatar imgProps={{referrerPolicy:"no-referrer"}} src={ profileImage } />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Menu
                    sx={{ mt: "45px" }}
                    id="menu-appbar"
                    anchorEl={anchorElUser}
                    anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    {getMenuItemsUser(navTokenContext).map((item, i) => (
                        <MenuItem key={i} onClick={handleMenuClick(item.url)}>
                            <Typography textAlign="center">{item.title}</Typography>
                        </MenuItem>
                    ))}
                </Menu>
            </div>

            <GlobalSearch />
        </NavBar>
    )
}

function MobileTopNav() {
    return (
        <NavBar isSmall={true}>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                    <LogoButton />
                </Grid>
                <Grid item>
                    <MobileNavButton />
                </Grid>
            </Grid>
        </NavBar>
    )
}
