import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"

import Chip from "@mui/material/Chip"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined' // project
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined' // bid
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined'

import { openProjectPopup, openBidPopup } from "@/nav/navSlice"
import { selectHasPermission } from "@/auth/authSlice"

export default function CreateButton({}){
    const buttonRef = useRef()
    const dispatch = useDispatch()

    const [open, setOpen] = useState(false)

    const canCreateProject = useSelector(state => selectHasPermission(state, "post_projects"))
    const canCreateBid = useSelector(state => selectHasPermission(state, "post_bids"))
    
    const handleProjectPopupOpen = () => {
        dispatch(openProjectPopup())
        setOpen(false)
    }
    const handleBidPopupOpen = () => {
        dispatch(openBidPopup({
            type: "Bid Sheet",
        }))
        setOpen(false)
    }
    const handleChangeOrderPopupOpen = () => {
        dispatch(openBidPopup({
            type: "Change Order",
        }))
        setOpen(false)
    }

    if (!canCreateProject && !canCreateBid){
        return null
    }

    return (
        <>
            <div ref={buttonRef}>
                <Chip
                    sx={{paddingLeft: 3, paddingRight: 3, width: "100%"}}
                    variant="outlined"
                    clickable
                    onClick={()=>{setOpen(true)}}
                    label={"Create +"}
                />
            </div>
            <Menu 
                sx={{marginTop: "10px"}}
                onClose={()=>{setOpen(false)}} 
                open={open} 
                anchorEl={buttonRef.current}   
            >
                <MenuItem sx={{display: canCreateProject ? "auto" : "none"}} onClick={handleProjectPopupOpen}>
                    <WorkOutlineOutlinedIcon /> 
                    <span style={{paddingLeft: 10}}>Project</span>
                </MenuItem>
                <MenuItem sx={{display: canCreateBid ? "auto" : "none"}} onClick={handleBidPopupOpen}>
                    <ArticleOutlinedIcon />
                    <span style={{paddingLeft: 10}}>Bid Sheet</span>
                </MenuItem>
                <MenuItem sx={{display: canCreateBid ? "auto" : "none"}} onClick={handleChangeOrderPopupOpen}>
                    <EmailOutlinedIcon />
                    <span style={{paddingLeft: 10}}>Change Order</span>
                </MenuItem>
            </Menu>
        </>
    )
}
