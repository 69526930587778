import { useTheme } from '@mui/material/styles'
import { useSelector } from "react-redux"
import { useMediaQuery } from '@mui/material'

import { selectNavOpen } from "@/nav/navSlice"
import { selectIsLoggedIn } from "@/auth/authSlice"
import { useIsHidden } from '@/nav/navHooks'
import { sideNavWidth } from "@/nav/navConstants"

export const BaseContainer = ({ children }) => {
    const theme = useTheme()
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isNavOpen = useSelector(selectNavOpen)
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const isNavHidden = useIsHidden()
    
    const marginSize = isSmall ? 20 : 40

    return (
        <div
            style={{
                width: `calc(100% - ${sideNavWidth + 80}px)`,
                flexGrow: 1,
                transition: isNavOpen ? theme.transitions.createMarginOpen(theme) : theme.transitions.createMarginClose(theme),
                marginLeft: isSmall || isNavHidden || isNavOpen ? marginSize : `-${sideNavWidth - 40}px`,
                marginRight: marginSize,
                minHeight: "100vh",
                paddingTop: isNavHidden ? "60px" : isLoggedIn ? "80px" : "100px",
                paddingBottom: "128px",
                boxSizing: "border-box",
            }}
        >
            {children}
        </div>
    )

}

export default BaseContainer
