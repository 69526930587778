import { useEffect, useState } from 'react';
import { format } from "date-fns"

import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Slider from '@mui/material/Slider';
import Grid from '@mui/material/Unstable_Grid2';
import Autocomplete from '@mui/material/Autocomplete';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import ApiAutocomplete from '@/common/ApiAutocomplete';
import { queryToFilterValue, dateQueryToFilterValue, statusQueryToFilterValue } from "@/utils";

const ProjectSliderGrid = (props) => {
    const maxValue = 1000000
    const [sliderValue, setSliderValue] = useState([0,0])

    useEffect(() => {
        setSliderValue(props.value)
    }, [props.value])

    const handleAmountOnChange = (event, newValue) => {
        setSliderValue(newValue)
    }

    return (
        <Grid xs={6} md={2}>
            <Typography gutterBottom>
                {props.label}
            </Typography>
            <Slider 
                step={1000}
                value={sliderValue} 
                min={0} 
                max={maxValue} 
                onChange={handleAmountOnChange} 
                onChangeCommitted={props.handleAmountChanges(props.id)} 
                valueLabelDisplay="auto" 
                valueLabelFormat={(value)=>{return value.toLocaleString()}}
            />
        </Grid>
    )
}

export default function ProjectFilters(props) {
    const {filters, setFilters, handleInputChange, handleAutocompleteChange, handleAutocompleteClear} = props

    const handleMultiAutoCompleteChange = (filterName) => (event, newValue) => {
        if (newValue && newValue.length > 0) {
            setFilters({...filters, [filterName]: "in:" + newValue.join(",")})
        }
    }
    const handleAmountChanges = (filterName) => (event, newValue) => {
        setFilters({...filters, [filterName]: [
            "gte:"+newValue[0],
            "lte:"+newValue[1]
        ]})
    }
    const handleCalendarChange = (filterName) => (newValue) => {
        if(new Date(newValue) != "Invalid Date" && newValue != null){
            setFilters({...filters, [filterName]: "gte:"+format(newValue,"y-MM-dd")})
        }else if (newValue == null){
            setFilters({...filters, [filterName]: null})
        }
    }

    return (
        <>
            <Grid container sx={{ paddingTop: 2 }} spacing={3}>
                <Grid xs={6} md={2}>
                    <TextField value={queryToFilterValue(filters.docket) || ""} sx={{ width:"100%" }} onChange={handleInputChange("docket")} label="Docket" variant="outlined" />
                </Grid>
                <Grid xs={6} md={2}>
                    <TextField value={queryToFilterValue(filters.name) || ""} sx={{ width:"100%" }} onChange={handleInputChange("name")} label="Name" variant="outlined" />
                </Grid>
                <Grid xs={6} md={2}>
                    <ApiAutocomplete
                        silentErrors
                        value={queryToFilterValue(filters.owner_id)}
                        apiRoute={"persons"}
                        fields={["first_name", "last_name"]}
                        onChange={handleAutocompleteChange("owner_id")}
                        onInputChange={handleAutocompleteClear("owner_id")}
                        label={"Owner"}
                    />
                </Grid>
                <Grid xs={6} md={2}>
                    <ApiAutocomplete
                        silentErrors
                        value={queryToFilterValue(filters.person_id)}
                        apiRoute={"persons"}
                        fields={["first_name", "last_name"]}
                        onChange={handleAutocompleteChange("person_id")}
                        onInputChange={handleAutocompleteClear("person_id")}
                        label={"Producer"}
                    />
                </Grid>
                <Grid xs={6} md={2}>
                    <ApiAutocomplete
                        silentErrors
                        value={queryToFilterValue(filters.client_id)}
                        apiRoute={"clients"}
                        fields={["name"]}
                        onChange={handleAutocompleteChange("client_id")}
                        onInputChange={handleAutocompleteClear("client_id")}
                        label={"Client"}
                    />
                </Grid>
                <Grid xs={6} md={2}>
                    <ApiAutocomplete
                        silentErrors
                        value={queryToFilterValue(filters.legal_entity_id)}
                        apiRoute={"legal-entities"}
                        fields={["name"]}
                        onChange={handleAutocompleteChange("legal_entity_id")}
                        onInputChange={handleAutocompleteClear("legal_entity_id")}
                        label={"Entity"}
                    />
                </Grid>
                <Grid xs={6} md={4}>
                    <ApiAutocomplete
                        silentErrors
                        value={statusQueryToFilterValue(filters.status_id)}
                        apiRoute={"statuses"}
                        fields={["name"]}
                        onChange={handleMultiAutoCompleteChange("status_id")}
                        onInputChange={handleAutocompleteClear("status_id")}
                        isOptionEqualToValue={(option, values) => values.includes(option)}
                        label={"Status"}
                        multiple
                    />
                </Grid>
                <Grid xs={6} md={2}>
                    <Autocomplete
                        value={queryToFilterValue(filters.signed)}
                        disablePortal
                        options={["true", "false"]}
                        getOptionLabel={(option) => option == "true" ? "yes" : "no"}
                        renderInput={(params) => <TextField {...params} label="Signed" />}
                        onChange={handleAutocompleteChange("signed")}
                        onInputChange={handleAutocompleteClear("signed")}
                    /> 
                </Grid>

                <LocalizationProvider dateAdapter={AdapterDateFns}>    
                    <Grid xs={6} md={2}>
                        <DatePicker
                            value={dateQueryToFilterValue(filters.start_date)}
                            label="Start Date is After"
                            onChange={handleCalendarChange("start_date")}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <DatePicker
                            value={dateQueryToFilterValue(filters.end_date)}
                            label="End Date is After"
                            onChange={handleCalendarChange("end_date")}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                    <Grid xs={6} md={2}>
                        <DatePicker
                            value={dateQueryToFilterValue(filters.live_date)}
                            label="Live Date is After"
                            onChange={handleCalendarChange("live_date")}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </Grid>
                </LocalizationProvider>
            </Grid>

            <Grid sx={{ paddingTop: 2 }} container spacing={5}>
                <ProjectSliderGrid
                    value={filters.forecast_revenue?[+queryToFilterValue(filters.forecast_revenue[0]), +queryToFilterValue(filters.forecast_revenue[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"Rev. Forecast"}
                    id={"forecast_revenue"}
                />
                <ProjectSliderGrid
                    value={filters.bid_quoted?[+queryToFilterValue(filters.bid_quoted[0]), +queryToFilterValue(filters.bid_quoted[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"Bid Quoted"}
                    id={"bid_quoted"}
                />
                <ProjectSliderGrid
                    value={filters.bid_hard_cost?[+queryToFilterValue(filters.bid_hard_cost[0]), +queryToFilterValue(filters.bid_hard_cost[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"Bid Hard Cost"}
                    id={"bid_hard_cost"}
                />
                <ProjectSliderGrid
                    value={filters.bid_gp?[+queryToFilterValue(filters.bid_gp[0]), +queryToFilterValue(filters.bid_gp[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"Bid GP"}
                    id={"bid_gp"}
                />
                <ProjectSliderGrid
                    value={filters.accounting_rev?[+queryToFilterValue(filters.accounting_rev[0]), +queryToFilterValue(filters.accounting_rev[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"BILLED (QB)"}
                    id={"accounting_rev"}
                />
                <ProjectSliderGrid
                    value={filters.accounting_cost?[+queryToFilterValue(filters.accounting_cost[0]), +queryToFilterValue(filters.accounting_cost[1])]:[0,0]}
                    handleAmountChanges={handleAmountChanges}
                    label={"SPENT (QB)"}
                    id={"accounting_cost"}
                />
            </Grid>
        </>
    );
}