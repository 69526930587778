import React from "react";
import ReactDOMServer from 'react-dom/server';
import { ServerStyleSheet } from "styled-components";
import { TopSheetPreview } from "./TopSheetPreview";
import { Provider } from "react-redux";
import store from "@/store";
import { downloadPdfFromHtmlString } from "@/utils";

const sheet = new ServerStyleSheet()

export function getHtmlString(){
    const htmlString = ReactDOMServer.renderToString(
        sheet.collectStyles(
            <Provider store={store}>
                <TopSheetPreview printMode />
            </Provider>
        )
    )
    const styleTags = sheet.getStyleTags();
    return htmlString + styleTags
}

export function downloadTopsheet(fileName) {
    downloadPdfFromHtmlString(getHtmlString(), fileName)
}