import { Box } from "@mui/material"
import { useTheme } from "@mui/material"

export default function Confirmation({description}){
    const theme = useTheme()
    return (
        <div style={{position: "relative", width: "100%", height: "100vh", textAlign: "center"}}>
            <div style={{position: "absolute", left: "50%", top: "50%", transform: "translate(-50%, -100%)"}}>
                <p>{description}</p>
            </div>
        </div>
    )
}