import { forwardRef, useState } from "react"
import { NumericFormat } from 'react-number-format';
import styled from "styled-components";
import { keyframes, css } from "styled-components";

import { useTheme } from "@mui/material";
import TableCell from "@mui/material/TableCell"
import TextField from "@mui/material/TextField";
import Box from '@mui/material/Box'
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import SearchIcon from '@mui/icons-material/Search';

import { getCurrencySymbol, formattedNumber } from "@/utils";
import { baseTheme } from '@/themes'

export const Heading = styled.h1`
    font-size: x-large;
    font-weight: 600;
    margin: 0;
`
export const Title = styled.h2`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: ${props => props.margin || "0"};
    font-size: 16px;
    font-weight: ${props => props.bold ? "600" : "400"};
    padding-bottom: ${props => props.noSpacing ? "0" : "16px"};
    border-bottom: ${props => `2px solid ${props.borderColor || 'transparent'}`};
`
export const Subtitle = styled.h4`
    color: grey;
    margin: 6px 0 0 0;
    font-weight: normal;
`
export const BoldInline = styled.span`
  font-weight: bold;
`
export const ErrorMessage = styled.p`
    color: #ff6654;
    font-size: 14px;
    margin: 7px auto;
`
export const Form = styled.div`
    margin: 24px 0;
`
export const PageMessage = styled.h2`
    margin: 36px auto;
    text-align: center;
`
export const StickyCell = styled(TableCell)`
    position: sticky;
    left: 0;
    z-index: 1;
`
export const CustomBox = styled(Box)`
    max-height: 80vh;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 24px;
    border-radius: 20px;
    width: 60%
`
export const ShadedBox = styled.div`
  background-color: ${props => props.backgroundColor};
  border-radius: 10px;
  padding: 30px 20px;
  margin: 48px auto;
  box-sizing: border-box;
`

export const StickyFooter = styled.div`
  position: fixed;
  z-index: 2;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100px;
  padding: 0px 40px;
  background-color: ${props => props.backgroundColor};
  box-sizing: border-box;
  border-top: ${props => `1px solid ${props.borderColor}`};
`

export const FlexTable = styled.div`
    display: flex;
    flex-direction: column;
`

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${({padding}) => padding || "0"};
    gap: 20px;
`

export const FlexCell = styled.div`
    font-size: 12px;
    text-align: ${({align}) => align || "left"};
    flex-grow: ${({grow}) => grow || 1};
    flex-basis: ${({minWidth}) => minWidth ? minWidth + "px" : "60px"};
    min-width: ${({minWidth}) => minWidth ? minWidth + "px" : "60px"};
    padding: ${({padding}) => padding || "0"};
`

export const FlexTitleCell = styled(FlexCell)`
    font-size: 10px;
    padding: 0 3px;
    box-sizing: border-box;
`

export const NumericFormatCustom = forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;
    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        thousandSeparator
        valueIsNumericString
      />
    );
});

export const SocketDiv = (props) => {
  const {children, highlights, minWidth, maxWidth} = props
  return (
      <div style={{outline: highlights.length>0 ? `${highlights[0].color} 3px solid` : "none", borderRadius: 5, position: "relative", display: "inline-block", width: "100%", marginRight: 0, verticalAlign: "bottom"}}>
          {children}
          {highlights.length>0 ? <p style={{fontSize: 10, transform: "translateY(-100%)", margin: 0, top: 0, right: 0, position: "absolute", background: highlights[0].color, padding: 2, borderRadius:5}}>{highlights.map(highlight=>highlight.personName).join(", ")}</p> : null}
      </div>
  )
}

export const TableCellSmall = (props) => {
  const {children} = props
  return (
    <TableCell  {...props} sx={{fontSize: 11, borderBottom:"none", padding: 0.5}}>
      {children}
    </TableCell>
  )
}

export const TextFieldSmallAutoHighlight = (props) => {
  const { InputProps } = props
  return (
    <TextField 
      size="small"
      {...props} 
      InputProps={{
        ...InputProps,
        style: {fontSize: 11, borderRadius: 8},
      }} 
      inputProps={{
        ...props.inputProps,
        style:{borderRadius: 50},
        onClick: (e)=>{e.target.select()}
      }}
    />
  )
}

export const TextFieldNumberSmallAutoHighlight = (props) => {
  const { InputProps, customcolor } = props
  return (
    <TextField 
      size="small"
      {...props} 
      InputProps={{
        ...InputProps,
        style: {fontSize: 11, color: customcolor || "auto"},
        inputComponent: NumericFormatCustom,
      }} 
      inputProps={{
        ...props.inputProps,
        onClick: (e)=>{e.target.select()},
        style: {textAlign: "right"}
      }}
    />
  )
}

const Targets = styled.div`
    font-size: 14px;
    padding: 18px 12px;
    margin-bottom: 24px;
`
export const TargetsPass = styled(Targets)`
    background-color: #44B39C1A;
    border-left: 1px solid #44B39C;
`
export const TargetsWarning = styled(Targets)`
    background-color: #D7BB721A;
    border-left: 1px solid #D7BB72;
`
export const TargetsError = styled(Targets)`
    background-color: #F8D0CD;
    border-left: 1px solid #E34336;
`

export const BoxButton = (props) => {
  const { theme, icon, text, onClick } = props
  return (
      <Box
          borderRadius={5}
          sx={{background: theme.palette.background.paper, height: "100%", cursor: "pointer"}}
          onClick={onClick}
      >
          <div style={{padding: 30}}>
              {icon}
              <br/>
              {text}
              <br/>
              <ArrowCircleRightIcon fontSize="large"/>
          </div>
      </Box>
  )
}

export const SearchBox = (props) => {
  const { handleSearch } = props
  const theme = useTheme()
  return (
    <Box sx={{borderRadius: 10, background: theme.palette.background.paper, height: "100%"}}>
      <TextField 
        placeholder="Search..."
        size="small"
        onChange={handleSearch}
        defaultValue=""
        InputProps={{endAdornment: <InputAdornment position="end"><SearchIcon/></InputAdornment>}}
        sx={{"& fieldset": { border: 'none' }, width: "100%"}} 
      />
    </Box>
  )
}

export const FormattedCurrency = ({ number, currency, noMinWidth }) => (
  <Grid container justifyContent={"right"} minWidth={noMinWidth ? "none" : 100}>
    <span style={{color: "grey"}}>{getCurrencySymbol(currency)}</span>
    &nbsp;
    <span>{formattedNumber(number)}</span>
  </Grid>
)


var scroll = keyframes`
  0% { background-position: 0% 0%; }
  100% { background-position: 100% 0%; }
`
const scrollAnimation = css`
  ${scroll} 120s linear infinite
`
const ScrollingAnimationContainer = styled.div`
  animation: ${scrollAnimation};
  animation-fill-mode: forwards;
`
export const DottedDiv = (props) => {
  const { children, backgroundColor, dotColor, overlay, borderRadius } = props
  const theme = useTheme()
  return(
    <div 
      style={{
        overflow: "hidden",
        borderRadius: borderRadius || 10,
        background: backgroundColor, 
      }}
    >
      <ScrollingAnimationContainer
        style={{
          background: `radial-gradient(circle at center, ${dotColor} 40%, transparent 40%)`,
          backgroundRepeat: "repeat",
          backgroundSize: "20px 20px",
          transition: "background-position 2s ease",
        }}
      >
        <div
          style={{
            borderRadius: borderRadius || 10,
            height: "100%",
            background: overlay ? `linear-gradient(90deg, ${theme.palette.background.paper} 0%, #00000000 100%)` : null,
          }}
        >
          {children}
        </div>
      </ScrollingAnimationContainer>
    </div>
  )
}

export const HighlightedIcon = (props) => {
  const Icon = props.icon
  return (
    <div style={{background: props.highlighted ? baseTheme.palette.primary.light : "none", display: "flex", padding: 3, borderRadius: 5}}>
      <Icon sx={{color: props.highlighted ? baseTheme.palette.text.primary : props.theme.palette.text.primary }}/>
    </div>
  )
}
