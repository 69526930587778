import styled from "styled-components"

export const AccountSectionTitle = styled.h3`
   margin-top: 26px; 
`
export const AccountSectionRow = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d9d9d9;

    &:last-child {
        border-bottom: none;
    }
`
export const AccountSubtitle = styled.h4`
    color: grey;
    margin: 22px 22px 22px 0;
    font-weight: normal;
    text-align: left;
    width: 100px;
    flex-grow: 1;
`

export const AccountInput = styled.div`
    flex-grow: 2;
    text-align: right;
`
