import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { useTheme, Button, Grid } from "@mui/material"

import { useIsHidden } from "@/nav/navHooks"
import { selectIsLoggedIn } from "@/auth/authSlice"

export default function PublicNav() {
    const navigate = useNavigate()
    const theme = useTheme()
    const isHidden = useIsHidden()
    const isLoggedIn = useSelector(selectIsLoggedIn)

    if (isLoggedIn || isHidden) {
        return null
    }

    return (
        <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{position: "absolute", zIndex: 2, padding: "20px 40px 0 40px"}}>
            <Grid item>
                <div style={{paddingTop: 10, paddingBottom: 20}}>
                    <Grid container alignItems={"center"}>
                        <Grid item>
                            <img width={40} style={{paddingRight: 10}} src={`/logo_${theme.palette.mode}.png`}/>
                        </Grid>
                        <Grid item>
                            <img width={140} src={`/central-wordmark-bold-${theme.palette.mode}.svg`}/>
                        </Grid>
                    </Grid>
                </div>
            </Grid>
            <Grid item>
                <Grid container>
                    <Grid item>
                        <Button onClick={()=>{navigate("login")}} sx={{borderRadius: 2, minWidth: 100, padding: 1.4, marginRight: 2, color: theme.palette.primary.contrastText}}>
                            Sign In
                        </Button>
                    </Grid>
                    <Grid item>
                        <Button onClick={()=>{navigate("sign-up")}} variant="contained" sx={{background: theme.palette.background.rainbow, color: "white", borderRadius: 2, minWidth: 135, padding: 1.4}}>
                            Join the Beta
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}