import { useState } from "react";

import { useTheme } from "@mui/material";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import Button from '@mui/material/Button';
import IconButton from "@mui/material/IconButton";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';

export default function BidInsert(props) {
    const { type, options, createItem } = props
    const theme = useTheme()
    const [inputValue, setInputValue] = useState('')
    const [highlightedValue, setHighlightedValue] = useState('')
    const [focused, setFocused] = useState(false)

    const focus = () => {
        setFocused(true)
    }

    const unfocus = () => {
        setFocused(false)
        setInputValue('')
        setHighlightedValue('')
    }

    const handleCreateItem = (value) => () => {
        createItem(value)
        unfocus()
    }

    return (
        <Grid marginTop={1} item xs={12} md={12}>
            <>
                {focused ? (
                    <Grid container>
                        <Grid item>
                            <Autocomplete
                                value={inputValue}
                                sx={{width: 400}}
                                freeSolo
                                disableClearable
                                size="small"
                                options={options}
                                renderInput={(params) => <TextField autoFocus {...params} />}
                                onInputChange={(e, v)=>{setInputValue(v)}}
                                onHighlightChange={(e, v)=>{setHighlightedValue(v)}}
                                onKeyPress={(e) => {e.key!="Enter" || handleCreateItem(highlightedValue || e.target.value)()}}
                            />
                        </Grid>
                        <Grid item>
                            <IconButton onClick={handleCreateItem(highlightedValue || inputValue)}>
                                <CheckCircleIcon />
                            </IconButton>
                            <IconButton onClick={unfocus}>
                                <CancelIcon />
                            </IconButton>
                        </Grid>
                    </Grid>
                ) : (
                    <Button
                        onClick={focus}
                        size="large"
                        sx={{color: theme.palette.text.primary}}
                        startIcon={<AddCircleIcon />}
                    >
                        {`Add ${type}`}
                    </Button>
                )}
            </>
        </Grid>
    )
}
