import { useState, useEffect } from "react"

import TablePagination from '@mui/material/TablePagination'
import Grid from "@mui/material/Grid"

import CustomTable from "@/table/CustomTable"
import CustomFilters from "@/table/CustomFilters"
import CsvExportButton from "@/common/CsvExportButton"
import { getDataFromQueryParams } from "@/utils"
import { request } from "@/Api"

export default function CustomTableContainer(props) {
    const {
        modelType, defaultOrder,
        title, headCells,
        itemRowComponent, itemRowProps,
        filterComponents, setAdditionalData,
        connectedComponents, children,
        prefixedFilters
    } = props
    const ItemRow = itemRowComponent
    const defaultParams = {page: 0, limit: 100, orders: defaultOrder, filters: {}}

    const [order, setOrder] = useState(defaultParams.orders)
    const [page, setPage] = useState(defaultParams.page)
    const [rowsPerPage, setRowsPerPage] = useState(defaultParams.limit)
    const [filters, setFilters] = useState(defaultParams.filters)
    const [activeCustomFilter, setActiveCustomFilter] = useState(null)
    const [customFilters, setCustomFilters] = useState([])
    const [isReady, setIsReady] = useState(false)
    const [isActive, setIsActive] = useState(false)
    
    const [items, setItems] = useState([])
    const [totalCount, setTotalCount] = useState(0)

    const getCustomFilters = () => {
        request.get(`custom_filters`)
        .then(function (response) { 
            const filteredFilters = response.data.filter((filter)=>(filter.model_type == modelType))
            setCustomFilters([...filteredFilters])
            
            const parsedQueryParams = getDataFromQueryParams(defaultParams)
            const queryParamFilterString = JSON.stringify(parsedQueryParams.filters)
            const defaultCustomFilter = filteredFilters.find((filter)=>(filter.is_default))
            const matchingFilter = filteredFilters.find((filter)=>(filter.filters == queryParamFilterString))

            if (defaultCustomFilter && parsedQueryParams.allDefault) {
                setActiveCustomFilter(defaultCustomFilter)
                setFilters(JSON.parse(defaultCustomFilter.filters))
                setOrder(defaultCustomFilter.orders)
            } else if(matchingFilter && matchingFilter.filters == queryParamFilterString) {
                setActiveCustomFilter(matchingFilter)
                setFilters(JSON.parse(matchingFilter.filters))
                setOrder(matchingFilter.orders)
            } else {
                setFilters(parsedQueryParams.filters)
                setOrder(parsedQueryParams.orders)   
            }

            setPage(parsedQueryParams.page)
            setRowsPerPage(parsedQueryParams.limit)
            setIsReady(true)
        })
    }

    const getItems = () => {
        if (!isActive) {
            return;
        }

        request.get(`/${modelType}s`, {
            params: {
                offset: page * rowsPerPage,
                limit: rowsPerPage,
                orders: order,
                ...filters,
                ...prefixedFilters,
            }
        })
        .then((response)=>{
            setTotalCount(response.data.total_count)
            setItems(response.data.items)

            if (setAdditionalData) {
                setAdditionalData(response.data)
            }

            const query_params = response.request.responseURL.split("?")[1]
            history.replaceState(null, "", `${location.pathname}?${query_params}`)
        })
    }

    useEffect(getCustomFilters, [])

    useEffect(() => {
        if (isReady) {
            setIsActive(true)
        }
    }, [isReady])
    
    useEffect(() => {
        if (isActive) {
            getItems()
        }
    }, [isActive])

    useEffect(() => {
        getItems()
    }, [order, page, rowsPerPage])

    useEffect(() => {
        if(page > 0) {
            setPage(0)
        } else {
            const debounceTimeout = setTimeout(() => {
                getItems()
            }, 250);

            return () => clearTimeout(debounceTimeout)
        }
    }, [filters])

    const handleChangePage = (event, newPage) => {
        setPage(newPage)
    }
    
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    const deleteItem = (itemId) => {
        setItems(items.filter(item => item.id != itemId))
    }

    return(
        <>
            {prefixedFilters ? null : <CustomFilters
                title={title}
                modelType={modelType}
                filterComponents={filterComponents}
                filters={filters}
                setFilters={setFilters}
                order={order}
                setOrder={setOrder}
                defaultOrder={defaultOrder}
                customFilters={customFilters}
                setCustomFilters={setCustomFilters}
                activeCustomFilter={activeCustomFilter}
                setActiveCustomFilter={setActiveCustomFilter}
            />}

            {children}
            {connectedComponents ? connectedComponents(items, setItems) : null}

            <CustomTable
                headCells={headCells}
                setOrder={setOrder}
                order={order.substring(1)}
                sign={order.substring(0,1)}
                rows={ items }
                rowComponent={(item) => (
                    <ItemRow 
                        item={item} 
                        items={items} 
                        setItems={setItems} 
                        deleteItem={deleteItem}
                        {...itemRowProps} 
                    />
                )}
            />

            {prefixedFilters ? null : (
                <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item xs={12} sm={2}>
                        <CsvExportButton model={modelType} filters={filters} />
                    </Grid>
                    <Grid item xs={12} sm={10}>
                        <TablePagination
                            rowsPerPageOptions={[10,50,100]}
                            component="div"
                            count={totalCount}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Grid>
                </Grid>
            )}
        </>
    )
}
