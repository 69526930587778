import { useState, useRef } from "react"
import { useSelector, useDispatch } from "react-redux"
import { useNavigate } from "react-router-dom"
import styled from "styled-components"

import { useMediaQuery, useTheme, ButtonBase, Grid, Menu, MenuItem } from "@mui/material"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'

import { OrgImage } from "@/account/CommonComponents"
import { selectOrganizations, selectActiveOrganization, setToken } from "@/auth/authSlice"
import { request } from "@/Api"


const MenuTitle = styled.h4`
    margin-top: 0;

    & span {
        font-weight: 400;
        font-size: 12px;
        color: ${props => props.color};
        margin-left: 8px;
    }
`

export default function OrganizationMenu() {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const menuAnchorEl = useRef()
    const theme = useTheme()
    
    const [ menuOpen, setMenuOpen ] = useState(false)
    
    const activeOrganization = useSelector(selectActiveOrganization)
    const organizations = useSelector(selectOrganizations)
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))

    const toggleMenu = () => {
        setMenuOpen(!menuOpen)
    }

    const selectOrganization = (organizationId) => {
        request.post(`enter-organization/${organizationId}`).then((response) => {
            dispatch(setToken(response.data))
            navigate("dashboard", { state: organizationId }) // passing unique data here to trigger dashboard useEffect
        })
    }

    if (!activeOrganization || organizations.length < 2) {
        return null
    }

    return (
        <ButtonBase onClick={toggleMenu} ref={menuAnchorEl}>
            <Grid container alignItems="center" spacing={2}>
                <Grid item>
                    <OrgImage image={activeOrganization.logo} size={20} />
                </Grid>
                <Grid item>
                    {activeOrganization.name}
                </Grid>
                <Grid item>
                    {menuOpen ? (
                        <KeyboardArrowUpIcon />
                    ) : (
                        <KeyboardArrowDownIcon />
                    )}
                </Grid>
            </Grid>

            <Menu 
                open={menuOpen}
                onClose={()=>{setMenuOpen(false)}}
                anchorEl={menuAnchorEl.current}
                anchorOrigin={{vertical: 50, horizontal: "center"}}
                transformOrigin={{vertical: "top", horizontal: "center"}}
                slotProps={{
                    paper: {
                        sx: {
                            backgroundColor: theme.palette.background.default,
                            borderRadius: "20px",
                            padding: "24px",
                            width: isSmall ? "100%" : "360px",
                            boxSizing: "border-box",
                        },
                    }
                }}
                sx={{
                    "& .MuiMenuItem-root:hover": {
                        borderRadius: "14px",
                    },
                    "& .MuiMenuItem-root.Mui-selected": {
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: "14px",
                    }
                }}
            >
                <MenuTitle color={theme.palette.text.secondary}>
                    Workspaces
                    <span>{`(${organizations.length})`}</span>
                </MenuTitle>
                {organizations.map((organization, index) => 
                    <MenuItem
                        key={index}
                        onClick={()=>{selectOrganization(organization.id)}}
                        selected={organization.id == activeOrganization.id}
                        disabled={organization.paused}
                        sx={{
                            justifyContent: "space-between",
                        }}
                    >
                        <span>
                            <OrgImage image={organization.logo} size={32} />
                        </span>
                        <span>{organization.name}</span>
                        {organization.id == activeOrganization.id ? (
                            <CheckCircleIcon />
                        ) : organization.paused ? null : (
                            <KeyboardArrowRightIcon />
                        )}
                    </MenuItem>
                )}
            </Menu>
        </ButtonBase>
    )
}
