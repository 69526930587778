import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { request } from "@/Api"
import { Heading } from "@/common/StyledComponents"
import { CopyLinkButton, MakersIcon } from "@/common/CommonComponents"
import { ProfileListItem, ProfileListItemLikeButton } from "@/profiles_common/CommonComponents"
import { Chip , Grid, Button, useTheme } from "@mui/material"

import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import CalendarMonthRoundedIcon from '@mui/icons-material/CalendarMonthRounded';
import AccountBalanceWalletRoundedIcon from '@mui/icons-material/AccountBalanceWalletRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import LanguageRoundedIcon from '@mui/icons-material/LanguageRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';
import SwippableView from "../common/SwippableView"
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { ProjectProfilePopup } from "./ProjectProfilePopup"
import { parseVideoUrlToEmbedUrl } from "@/utils"
import { useMediaQuery } from "@mui/material"
import ProfileAvatar from "@/common/ProfileAvatar"
import { ProfileStyle } from "@/profiles_common/profileStyle"
import { Link } from "@mui/material"
import PersonProfileThumbnail from "@/person_profiles/PersonProfileThumbnail"
import { selectHasPermission } from "@/auth/authSlice"


export function ProjectProfile(){
    const theme = useTheme()
    const { profileHandle } = useParams()
    const [ projectProfile, setProjectProfile ] = useState()

    const [ imageViewerOpen, setImageViewerOpen ] = useState(false)
    const [ defaultImageIndex, setDefaultImageIndex ] = useState(0)
    const [ popupOpen, setPopupOpen ] = useState(false) 

    const isAdmin = useSelector(state => selectHasPermission(state, "view_admin_panel"))
    const isOwner = projectProfile ? projectProfile.is_owner : false

    const handleImageOpen = (index) => {
        setImageViewerOpen(true)
        setDefaultImageIndex(index)
    }
    const getProjectProfile = () => {
        request.get(`project-profiles/${profileHandle}`)
        .then(response=>{
            setProjectProfile(response.data)
        })
    }
    useEffect(()=>{
        getProjectProfile()
    }, [])
    const handlePopupOpen = () => {
        setPopupOpen(true)
    }

    if (!projectProfile){
        return
    }

    return (
        <ProfileStyle>
            {projectProfile.is_by_makers && <Grid container spacing={1.5} alignItems={"center"}>
                <Grid item>
                    <div style={{background: theme.palette.background.rainbow, borderRadius: "50%", padding: 2}}>
                        <div style={{background: theme.palette.background.default, borderRadius: "50%", padding: 2}}>
                            <MakersIcon />
                        </div>
                    </div>
                </Grid>
                <Grid item>
                    <span style={{fontSize: 12}}> by <Link style={{textDecoration: "none", fontSize: 12, lineHeight: 2}}>Makers</Link></span>
                </Grid>
            </Grid>}
            <Grid container spacing={3} paddingTop={2.5} paddingBottom={2}>
                <Grid item>
                    <Heading>{projectProfile.name}</Heading>
                </Grid>
                <Grid item>
                    <Chip color="success" label="Project"/>
                </Grid>
            </Grid>

            <Grid container alignItems={"center"} paddingBottom={3} justifyContent={"space-between"}>
                <Grid item container spacing={2} alignItems={"center"} md={8} paddingBottom={2}>
                    <ProfileListItem
                        icon={
                            <div style={{paddingRight: 6}}>
                                <ProfileAvatar image={projectProfile.client_profile?.image} name={projectProfile.client_profile?.name} size={28} darkModeInvert />
                            </div>
                        }
                        text={`for ${projectProfile.client_profile.name}`}
                    />
                    {projectProfile.locations.length > 0 ? (
                        <ProfileListItem
                            icon={<PlaceRoundedIcon fontSize="16px"/>}
                            text={projectProfile.locations[0].short_name}
                        />
                    ) : null}
                    <ProfileListItem
                        icon={<CalendarMonthRoundedIcon fontSize="16px"/>}
                        text={projectProfile.wrap_date}
                    />
                    <ProfileListItemLikeButton likableEntity={projectProfile} setLikableEntity={setProjectProfile} modelType={"project_profile"}/>
                </Grid>


                <Grid item container spacing={2} md={4} justifyContent={"right"} width={"max-content"}>
                    <Grid item>
                        <CopyLinkButton />
                    </Grid>
                    {isOwner || isAdmin ? 
                    <Grid item>
                        <Button
                            sx={{boxShadow: "none"}}
                            size="small" variant="contained" color="secondary"
                            startIcon={<EditOutlinedIcon fontSize="small"/>}
                            onClick={handlePopupOpen}
                        >
                            Edit
                        </Button>
                    </Grid> : null}
                </Grid>

            </Grid>

            {projectProfile.reel_url ? 
            <iframe 
                style={{border: "none", borderRadius: "10px", width: "100%", aspectRatio: "16 / 9"}}
                src={parseVideoUrlToEmbedUrl(projectProfile.reel_url)}
            /> : null}

            <Grid container paddingBottom={6} spacing={6}>
                <Grid item md={8} sm={12} xs={12}>
                    <h3>Brief</h3>
                    <span style={{fontSize: 14}}>{projectProfile.description ? projectProfile.description : "this project doesn't have a brief yet"}</span>
                </Grid>
                <Grid item md={4} sm={12} xs={12}>
                    <h3>Details</h3>
                    <ProfileListItem 
                        icon={<AccountBalanceWalletRoundedIcon/>}
                        text={projectProfile.budget}
                        fontSize={14}
                        secondary
                    />
                    <ProfileListItem 
                        icon={<LocalOfferRoundedIcon/>}
                        text={projectProfile.project_profile_types.map(pt=>pt.name).join(", ")}
                        fontSize={14}
                        secondary
                    />
                    {projectProfile.locations.length > 0 ? (
                        <ProfileListItem 
                            icon={<LanguageRoundedIcon/>}
                            text={projectProfile.locations[0].full_name}
                            fontSize={14}
                            secondary
                        />
                    ) : null}
                    <ProfileListItem 
                        icon={<StarRoundedIcon/>}
                        text={projectProfile.highlight}
                        fontSize={14}
                        secondary
                    />
                </Grid>
            </Grid>

            <div hidden={projectProfile.images.length == 0}>
                <h3>Gallery</h3>
                <Grid container spacing={3} marginBottom={10}>
                    {projectProfile.images.map((image, index)=>
                    <ImageGridItem
                        handleClick={()=>{
                            handleImageOpen(index)
                        }}
                        key={index}
                        url={image.processed ? image.url_thumb : image.url_orig}
                    />
                )}
                </Grid>
            </div>

            <div hidden={projectProfile.person_profiles.length < 2}>
                <Grid container alignItems={"center"} paddingBottom={2}>
                    <h3>Team</h3>
                </Grid>
                <Grid container spacing={"25px"}>
                    {projectProfile.person_profiles.map((personProfile, index) =>
                        <PersonProfileThumbnail key={index} personProfile={personProfile}/>
                    )}
                </Grid>
            </div>
            

            <ProjectProfilePopup 
                projectProfile={projectProfile}
                projectPopupOpen={popupOpen}
                closePopup={()=>{setPopupOpen(false)}}
                updateProjectProfile={(projectProfile)=>{setProjectProfile(projectProfile)}}
                orgProfileId={projectProfile.org_profile_id}
            />

            <SwippableView 
                close={()=>{setImageViewerOpen(false)}}
                viewOpen={imageViewerOpen}
                defaultIndex={defaultImageIndex}
                images={projectProfile.images} 
            />
        </ProfileStyle>
    )
}

const ImageGridItem = ({url, handleClick}) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md")) 
    const [ hovering, setHovering ] = useState(false)
    return(
        <Grid item md={"auto"} xs={6} sx={{cursor: "pointer"}} onClick={handleClick}>
            <div onMouseEnter={()=>{setHovering(true)}} onMouseLeave={()=>{setHovering(false)}}>
                <img src={url} style={{
                    transition: "all 0.3s",
                    transform: `scale(${hovering ? 1.02 : 1})`,
                    width: isMobile ? "100%" : 200, height: 200, objectFit: "cover", borderRadius: 10
                }}/>
            </div>
        </Grid>
    )
}