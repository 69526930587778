import { useSelector } from "react-redux";

import { useTheme } from "@mui/material";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link"

import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import WorkIcon from '@mui/icons-material/Work';
import FolderIcon from '@mui/icons-material/Folder';
import WalletIcon from '@mui/icons-material/Wallet';
import PersonIcon from '@mui/icons-material/Person';
import EventIcon from '@mui/icons-material/Event';

import BidInsights from "@/bid/BidInsights";
import { countryCurrencies } from "@/constants";
import { selectProject } from "@/bid/bidSlice";
import { selectActiveOrganization, selectHasPermission } from "@/auth/authSlice";

export default function BidHeader() {
    const theme = useTheme()
    const project = useSelector(selectProject)
    const organization = useSelector(selectActiveOrganization)
    const multiLegalEntity = useSelector(state => selectHasPermission(state, "multi_legal_entity"))
    const canViewConfidentialData = useSelector(state => selectHasPermission(state, "view_confidential_data"))

    return (
        <Grid container spacing={6}>
            <Grid item flexGrow={1}>
                <div style={{background: theme.palette.background.paper, height: '100%', width: '100%', borderRadius: 20}}>
                    <div style={{padding: 20}}>
                        <div style={{marginBottom: 10}}>
                            <Grid container spacing={1}>
                                <Grid item>Project</Grid>
                            </Grid>
                        </div>
                        <Grid container spacing={1}><Grid item><LocalOfferIcon/></Grid><Grid item><Link href={`/projects/${project.id}`}>{project.name}</Link></Grid></Grid>
                        <Grid container spacing={1}><Grid item><WorkIcon/></Grid><Grid item>{project.client.name}</Grid></Grid>
                        <Grid container spacing={1}><Grid item><FolderIcon/></Grid><Grid item>{project.docket}</Grid></Grid>
                        <Grid container spacing={1}><Grid item><WalletIcon/></Grid><Grid item>{project.currency}</Grid></Grid>
                        <Grid container spacing={1}><Grid item><EventIcon/></Grid><Grid item>{(project.start_date || "no start date")}</Grid></Grid>

                    </div>
                </div>
            </Grid>
            {multiLegalEntity && <Grid item flexGrow={1}>
                <div style={{background: theme.palette.background.paper, height: '100%', width: '100%', borderRadius: 20}}>
                    <div style={{padding: 20}}>
                        <div style={{marginBottom: 10}}>{organization.name}</div>
                        <Grid container spacing={1}><Grid item><PersonIcon/></Grid><Grid item>{`${project.owner.first_name} ${project.owner.last_name}`}</Grid></Grid>
                        <Grid container spacing={1}><Grid item><WorkIcon/></Grid><Grid item>{project.legal_entity.name}</Grid></Grid>
                        <Grid container spacing={1}><Grid item><WalletIcon/></Grid><Grid item>{countryCurrencies[project.legal_entity.country.code]}</Grid></Grid>
                    </div>
                </div>
            </Grid>}
            {canViewConfidentialData ? (
                <BidInsights currency={project.legal_entity.country.currency} />
            ) : null}
        </Grid>
    )
}
