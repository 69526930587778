import styled from "styled-components"

const StyledAuthGallery = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 50%;
    height: 100%;
    padding: 0 40px;
    box-sizing: border-box;
    overflow: hidden;
`

const StyledSlidingGallery = styled.div`
    position: relative;
    top: ${props => props.offset};
    width: 30.3%;
    margin: 0 1.5%;
    animation: ${props => `${props.reverse ? 'scrollDown' : 'scrollUp'} 300s linear infinite`};
    
    @keyframes scrollDown {
        from {
            transform: translateY(-50%);
        }
        to {
            transform: translateY(0%);
        }
    }

    @keyframes scrollUp {
        from {
            transform: translateY(0%);
        }
        to {
            transform: translateY(-50%);
        }
    }
`

const GalleryImage = styled.div`
    width: 100%;
    margin: 16% 0;
    box-sizing: border-box;
    aspect-ratio: 7 / 10;
    border-radius: 20px;
    background-position: center center;
    background-size: cover;
    background-image: ${props => `url('${props.url}')`};
`

const GalleryOverlay = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    
    padding: ${props => props.condensed ? "40px 88px" : "40px"};
    border-radius: ${props => props.condensed ? "1000px" : "50px"};
    
    h3 {
        display: inline-block;
        font-size: 22px;
        font-weight: 600;
        margin: 0;
        background: linear-gradient(to right, #66BDFF, #7DDDB2, #FFCD83, #EE8E86);
        background-clip: text;
        -webkit-text-fill-color: transparent;
    }
`

function SlidingGallery({ row, offset, reverse }) {
    const images = Array.from(Array(20), (e,i)=>(i % 10) + 1)

    if (reverse) {
        images.reverse()
    }

    return (
        <StyledSlidingGallery offset={offset} reverse={reverse || false}>
            {images.map((i, index) => <GalleryImage key={index} url={`/auth/${i}.jpg`} />)}
        </StyledSlidingGallery>
    )
}

export default function AuthGallery({ isMobile, children, condensedOverlay }) {
    if (isMobile) {
        return null
    }
    return (
        <StyledAuthGallery>
            <SlidingGallery row={0} offset="0%" reverse />
            <SlidingGallery row={1} offset="-48%" />
            <SlidingGallery row={2} offset="-104%" reverse />

            <GalleryOverlay condensed={condensedOverlay}>
                {children}
            </GalleryOverlay>
        </StyledAuthGallery>
    )
}
