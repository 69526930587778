import { useEffect, useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Draggable } from "react-beautiful-dnd";
import styled from "styled-components";

import { useTheme } from "@mui/material";

import { request } from "@/Api";
import lineItemCells from "@/bid/lineItemCells"
import { selectLineHighlights, selectLine, selectGroup, updateWorkingLine, clearWorkingLine, deleteLine, updateLine, insertLine, selectLineIsVisible, updateLineCalculatedFields } from "@/bid/bidSlice"

const Row = styled.div`
    display: flex;
    align-items: center;
`

export default function LineItemRow(props){
    const {lineItemId, index, currency, readOnly, canViewConfidentialData, draggableId, sendSocketMessage, isTemplate} = props
    const [localLineItem, setLocalLineItem] = useState({})
    const lineItem = useSelector(state => selectLine(state, lineItemId))
    const isVisible = useSelector(state => selectLineIsVisible(state, lineItemId))
    const highlights = useSelector(state => selectLineHighlights(state, lineItemId))
    const group = useSelector(state => selectGroup(state, lineItem?.line_group_id))
    const theme = useTheme()
    const dispatch = useDispatch()
    
    useEffect(() => {
        setLocalLineItem({
            ...lineItem
        })
    }, [lineItem])

    const handleFocus = (field) => (event) => {
        event.preventDefault()
        sendSocketMessage({type: "highlight", data: {lineId: lineItem.id, field: field}})
    }  

    const handleBlur = (field) => (event) => {
        let value = event.target.value

        if (event.target.type == "number") {
            value = parseFloat(value) || 0

            if (field == "markup" || (group?.rate_type == "Percentage" && field == "units")) {
                value = value / 100
            }
        }
                
        if (lineItem[field] == value) {
            return
        }

        // Need to update redux with local value before updating from server (in api response)
        // Otherwise, if server reverts a change, no change in state is detected and the
        // field input does not re-render and still shows the inputted value instead of
        // the reverted one
        dispatch(updateLine({...localLineItem, [field]: value}))
        dispatch(clearWorkingLine())
        
        request.put(`/line-items/${lineItem.id}`, {...localLineItem, [field]: value})
        .then((response)=>{
            const newLine = response.data
            dispatch(updateLineCalculatedFields(newLine))
            sendSocketMessage({type: "updateLine", lineId: lineItem.id})
        })
    }

    const handleInputChange = (field) => (event) => {
        let value = event.target.value

        if (event.target.type == "number") {
            value = parseFloat(value) || 0  

            if (field == "markup" || (group?.rate_type == "Percentage" && field == "units")) {
                value = value / 100
            }
        }

        dispatch(updateWorkingLine({lineId: lineItem.id, field: field, value: value}))
        setLocalLineItem({...localLineItem, [field]: value})
    }

    const handleDeleteConfirm = () => {
        request.delete(`/line-items/${lineItem.id}`)
        .then(()=>{
            dispatch(deleteLine(lineItem))
            sendSocketMessage({type: "deleteLine", line: lineItem})
        })
    }
    
    const handleCopy = () => {
        request.post("/line-items", localLineItem)
        .then(response => {
            dispatch(insertLine({ line: response.data, index: index+1 }))
            sendSocketMessage({type: "addLine", lineId: response.data.id, index: index+1 })
        })
    }

    if (!lineItem || Object.keys(localLineItem).length == 0 || !isVisible){
        return null
    }
    
    return (
        <Draggable draggableId={draggableId} index={index} key={draggableId}>
            {(provided)=>
                <Row {...provided.draggableProps} ref={provided.innerRef}>
                    {lineItemCells.map((col, index) => col.renderComponent({
                        key: `item_col_${index}`,
                        align: col.align,
                        minWidth: col.minWidth,
                        grow: col.grow,
                        dragHandleProps: provided.dragHandleProps,
                        theme,
                        canViewConfidentialData,
                        readOnly,
                        isTemplate,
                        localLineItem,
                        highlights,
                        rateType: group.rate_type,
                        isMakersFees: group.is_makers_fees,
                        currency,
                        handleCopy,
                        handleDeleteConfirm,
                        handleFocus,
                        handleBlur,
                        handleInputChange,
                    }))}
                </Row>
            }
        </Draggable>
    )
}
