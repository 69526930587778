import { request } from "@/Api";
import { Switch } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectTemplate } from "./bidSlice";

export default function BidTemplatePublishingSwitch(){
    const template = useSelector(selectTemplate)
    const [bidTemplate, setBidTemplate] = useState({})
    useEffect(()=>{
        setBidTemplate(template)
    }, [])
    const updateBidTemplate = () => {
        const updatedBidTemplate = {...bidTemplate, public: !bidTemplate.public}
        request.put(`/bid-templates/${template.id}`, updatedBidTemplate)
        .then(resposne=>{
            setBidTemplate(resposne.data)
        })
    }
    if (!template){
        return null
    }
    return (
        <div>Public <Switch checked={bidTemplate.public || false} onChange={updateBidTemplate}></Switch></div>
    )
}