import { useState } from 'react'

import Button from "@mui/material/Button"
import CircularProgress from '@mui/material/CircularProgress'
import Grid from '@mui/material/Grid'

import CloudDownloadIcon from '@mui/icons-material/CloudDownload'

import { downloadFile } from "@/utils"

export default function CsvExportButton(props) {
    const { model, filters, variant, size } = props
    const [ loading, setLoading ] = useState(false)

    const handleExportToCsv = (e) => {
        setLoading(true)
        e.stopPropagation()
        downloadFile(`${model}s/csv-export`, { ...filters }, () => setLoading(false))
    }

    return (
        <Grid container alignItems="center" columnSpacing={2}>
            <Grid item>
                <Button variant={variant || "text"} size={size || "small"} endIcon={<CloudDownloadIcon />} onClick={handleExportToCsv} disabled={loading}>
                    Export
                </Button>
            </Grid>
            {loading ? (
                <Grid item>
                    <CircularProgress size={16} />
                </Grid>
            ) : null}
        </Grid>
    )
}
