import { useNavigate } from "react-router-dom"
import { useSelector } from "react-redux"
import { selectHasPermission, selectIsLoggedIn } from "@/auth/authSlice"

export default function ProtectedComponents({permission, children}){
    const navigate = useNavigate() // need this instance for this component to work
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const hasPermission = useSelector(state => selectHasPermission(state, permission))

    if (!isLoggedIn || (permission && !hasPermission)){
        return null
    }

    return children
}
