import { useEffect, useRef, useState } from "react"

import Chip from "@mui/material/Chip"
import Divider from '@mui/material/Divider'
import parse from "date-fns/parse"
import { Title } from "@/common/StyledComponents"
import Milestone from "@/milestone/Milestone"
import MilestonePopup from "@/milestone/MilestonePopup"

export default function ProjectMilestones({projectId, milestones, setMilestones, readOnly, defaultMilestone}){
    const [ popupOpen, setPopupOpen ] = useState(false)
    const [ milestone, setMilestone ] = useState({})
    const milestonesRef = useRef()

    const setPopupMilestone = (milestone) => {
        setMilestone(milestone)
        setPopupOpen(true)
    }

    useEffect(()=>{
        if (defaultMilestone){
            milestonesRef.current.scrollIntoView()
        }
    }, [])

    return (
        <div style={{marginTop: 50}} ref={milestonesRef}>
            <Divider sx={{marginTop: 5, marginBottom: 5}}/>
            <Title bold>Milestones</Title>

            {milestones.length > 0 ? milestones.map((milestone, index)=>
                <Milestone 
                    key={index} 
                    milestone={milestone} 
                    milestones={milestones}
                    setMilestones={setMilestones}
                    setPopupMilestone={setPopupMilestone}
                    readOnly={readOnly}
                />    
            ) : (
                <p>None</p>
            )}

            {readOnly ? null : (
                <Chip label="+ Milestone" clickable onClick={()=>{setPopupOpen(true)}}/>
            )}

            <MilestonePopup 
                milestone={milestone}
                setMilestone={setMilestone}
                milestones={milestones}
                setMilestones={setMilestones}
                projectId={projectId}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
            />
        </div>
    )

}