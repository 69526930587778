import { Alert, Button, Grid, InputLabel, Modal, Snackbar, TextField, useTheme } from "@mui/material"
import { useSelector } from "react-redux"
import { cancelRefutingBill, selectRefutingBill } from "./actualSlice"
import { CustomBox } from "@/common/StyledComponents"
import { useDispatch } from "react-redux"
import { request } from "@/Api"
import { useState } from "react"
import { updateBill } from "./actualSlice"

export default function AccountingBillRefutePopup({getProject}){
    const [ refutalMessage, setRefutalMessage ] = useState("")
    const [ snackBarOpen, setSnackBarOpen ] = useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()
    const refutingBill = useSelector(selectRefutingBill)

    const handleClose = () => {
        dispatch(cancelRefutingBill())
    }

    const handleRefutalMessageInput = (event) => {
        setRefutalMessage(event.target.value)
    }

    const handleSubmit = () => {
        request.post(`accounting-bills/${refutingBill.id}/refute`, null, {params: {message: refutalMessage}})
        .then(response=>{
            dispatch(updateBill(response.data))
            setSnackBarOpen(true)
            handleClose()
            getProject()
        })
    }

    return(
        <>
            {refutingBill &&
            <Modal onClose={handleClose} open={refutingBill != null}>
                <CustomBox sx={{background: theme.palette.background.default, padding: 4}}>
                    <Grid container alignItems={"center"} spacing={3}>
                        <Grid item>
                            <h1>Refute Bill</h1>
                        </Grid>
                        <Grid item>
                            <span>{`${refutingBill.vendor_name} ${refutingBill.accounting_bill_number}`}</span>
                        </Grid>
                    </Grid>
                    <InputLabel sx={{paddingBottom: 1}}>
                        Please provide a reason for this refutal
                    </InputLabel>
                    <TextField
                        multiline
                        minRows={4}
                        fullWidth
                        sx={{marginBottom: 2}}
                        value={refutalMessage}
                        onInput={handleRefutalMessageInput}
                    />
                    <Grid container spacing={2} justifyContent={"right"}>
                        <Grid item>
                            <Button variant="contained" color="secondary" onClick={handleClose}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button variant="contained" onClick={handleSubmit}>Submit</Button>
                        </Grid>
                    </Grid>
                </CustomBox>
            </Modal>}

            <Snackbar
                open={snackBarOpen}
                autoHideDuration={2000}
                onClose={() => {setSnackBarOpen(false)}}
            >
                <Alert severity="success">Bill refutal requested!</Alert>
            </Snackbar>
        </>
    )
}