import { Navigate, useNavigate, useParams } from "react-router-dom"
import { useSelector } from "react-redux"

import { selectIsLoggedIn, selectHasPermission, selectIsSuperAdmin, selectIsOrganizationInitializing, selectOrganizationId, setToken } from "@/auth/authSlice"
import { CustomBox, PageMessage } from "@/common/StyledComponents"
import { useState } from "react"
import { Button, Grid, Modal, useTheme } from "@mui/material"
import { request } from "@/Api"
import { useDispatch } from "react-redux"
import { setError } from "@/nav/navSlice"

export default function ProtectedContainer({ container, permission, requireSuperAdmin }){
    const { organizationId } = useParams()

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const currentOrganizationId = useSelector(selectOrganizationId)
    const isLoggedIn = useSelector(selectIsLoggedIn)
    const isSuperAdmin = useSelector(selectIsSuperAdmin)
    const hasPermission = useSelector(state => selectHasPermission(state, permission))
    const isOrganizationInitializing = useSelector(selectIsOrganizationInitializing)

    const enterOrganization = () => {
        request.post(`enter-organization/${organizationId}`)
        .then((response) => {
            dispatch(setToken(response.data))
        })
        .catch(()=>{
            dispatch(setError({message: "You don't have access to this organization, going back to dashboard"}))
            navigate("/dashboard")
        })
    }

    if (isOrganizationInitializing){
        return (<Navigate to={"/initializing"}></Navigate>)
    }

    if (!isLoggedIn){
        localStorage.setItem("savedPath", window.location.pathname)
        return (<Navigate to={"/login"}></Navigate>)
    }

    if ((permission && !hasPermission) || (requireSuperAdmin && !isSuperAdmin)) {
        return (
            <PageMessage>You do not have permission to view this page.</PageMessage>
        )
    }

    if (organizationId && organizationId != currentOrganizationId){
        return (
            <EnterOrganizationPopup 
                enterOrganization={enterOrganization}
                closePopup={()=>{setPopupOpen(false)}}
            />
        )
    }

    return ( 
        <>
            {container}
        </> 
    )
}

const EnterOrganizationPopup = ({ enterOrganization }) => {
    const [ open, setOpen ] = useState(true) 
    const navigate = useNavigate()
    const theme = useTheme()
    const close = () => {
        setOpen(false)
        navigate("/dashboard")
    }
    const confirm = () => {
        enterOrganization()
        setOpen(false)
    }
    return (
        <>
            <Modal open={open} onClose={close}>
                <CustomBox style={{background: theme.palette.background.paper, padding: 40, paddingBottom: 0, overflow: "auto"}}>
                    <h1>This is a URL to another organization</h1>
                    <p>Are you sure you wish to switch your active organization to this one?</p>
                    <Grid container spacing={2} marginBottom={8} justifyContent={"right"}>
                        <Grid item><Button variant="contained" color="success" onClick={confirm}>Yes</Button></Grid>
                        <Grid item><Button variant="contained" color="secondary" onClick={close}>No</Button></Grid>
                    </Grid>
                </CustomBox>
            </Modal>
        </>
    )
}