import { CustomBox } from "@/common/StyledComponents";
import { Button, Modal, useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { closeLinesPopup, selectChecksAreValid, selectLinesPopupOpen, startAllocation } from "./actualSlice";
import { useSelector } from "react-redux";
import { ActualLines, ActualLinesWithHeader } from "./ActualLines";
import { useEffect, useState } from "react";

export default function ActualLinesPopup({bids, customLinesGroup}){
    const [keepMounted, setKeepMounted] = useState(false)
    const dispatch = useDispatch()
    const open = useSelector(selectLinesPopupOpen)
    const checksAreValid = useSelector(selectChecksAreValid)
    const theme = useTheme()
    const handleAllocate = () => {
        dispatch(startAllocation())
    }
    const handleClose = () => {
        dispatch(closeLinesPopup())
    }

    useEffect(()=>{
        // NOTE: this prevents blocking the parent from rendering while this component is mounting
        setKeepMounted(true)
    },[])

    return (
    <Modal keepMounted={keepMounted} open={open} onClose={handleClose}>
        <CustomBox sx={{ 
            maxHeight: "90vh",
            padding: 0,
            paddingLeft: 4,
            paddingRight: 4,
            background: theme.palette.background.default, 
            overflow: "scroll",
            width: "80%",
        }}>
            <ActualLinesWithHeader 
                selectable={true}
                bids={bids}
                customLinesGroup={customLinesGroup}
            />
            <div style={{
                width: "100%", textAlign: "right", 
                position: "sticky", bottom: -1, 
                background: theme.palette.background.default,
                paddingBottom: 20,
                paddingTop: 20
            }}>
                <Button disabled={!checksAreValid} onClick={handleAllocate} variant="contained">Assign to Bill(s)</Button>
            </div>
        </CustomBox>
    </Modal>
    )
}