import { useDispatch, useSelector } from "react-redux"

import IconButton from "@mui/material/IconButton"
import Modal from "@mui/material/Modal"
import ListItem from "@mui/material/ListItem"
import List from "@mui/material/List"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import Divider from "@mui/material/Divider"
import Accordion from "@mui/material/Accordion"
import AccordionSummary from "@mui/material/AccordionSummary"
import AccordionDetails from "@mui/material/AccordionDetails"
import Grid from "@mui/material/Grid"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material"

import CloseRoundedIcon from '@mui/icons-material/CloseRounded'
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined'
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined'

import { getMenuItemsMain, getMenus } from "@/nav/menuItems"
import { useHandlePageGoTo, useIsHidden } from "@/nav/navHooks"
import { selectNavOpen, setNavOpen } from "@/nav/navSlice"
import { selectNavTokenContext } from "@/auth/authSlice"

import CreateButton from "@/nav/CreateButton"
import ThemeSwitch from "@/nav/ThemeSwitch"
import LogoButton from "@/nav/LogoButton"
import OrganizationMenu from "@/nav/OrganizationMenu"

export default function MobileNav(props) {
    const { toggleTheme } = props
    const theme = useTheme()
    const dispatch = useDispatch()
    const open = useSelector(selectNavOpen)
    const isSmall = useMediaQuery(theme.breakpoints.down("md"))
    const isHidden = useIsHidden()
    const token = useSelector(selectNavTokenContext)

    const menuItemsMain = getMenuItemsMain(token)
    const menus = getMenus(token)

    const handleClose = () => {
        localStorage.setItem("navOpen", false)
        dispatch(setNavOpen(false))
    }

    const handlePageGoTo = useHandlePageGoTo()

    const handleMenuItemClick = (route) => () => {
        handleClose()
        handlePageGoTo(route)
    }

    if (!isSmall || isHidden){
        return null
    }

    return (
        <Modal open={open}>
            <Box 
                sx={{
                    width: "100%", height: "100%", 
                    background: theme.palette.mode == "light" ? "white" : theme.palette.background.paper, 
                    overflow: "auto"
                }}
            >
                <Grid container justifyContent={"space-between"} alignItems={"center"}>
                    <Grid item padding={"20px"}>
                        <div onClick={handleClose}>
                            <LogoButton />
                        </div>
                    </Grid>
                    <Grid item width={"3em"}>
                        <IconButton onClick={handleClose}>
                            <CloseRoundedIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <List sx={{paddingLeft: 3, paddingRight: 3}}>
                    <NavListItem 
                        item={{     
                            mobile: true,
                            title: "Profile",
                            url: "/profile",
                            icon: AccountCircleOutlinedIcon   
                        }} 
                        handleMenuItemClick={handleMenuItemClick}
                    />
                    {menuItemsMain.map((item, index)=>
                        <NavListItem key={index} item={item} handleMenuItemClick={handleMenuItemClick}/>
                    )}
                    {menus.map(menu => { menu.items.length == 0 ? null : (
                        <div key={menu.title}>
                            <Accordion sx={{width: "100%", boxShadow: "none", background: "none"}}>
                                <AccordionSummary
                                    sx={{
                                        '.MuiAccordionSummary-content': {
                                            margin: 0,
                                        },
                                    }}
                                    expandIcon={<ExpandMoreRoundedIcon />}
                                >
                                    <ListItem disableGutters disablePadding>
                                        <ListItemIcon>
                                            <menu.icon />
                                        </ListItemIcon>
                                        <ListItemText primary={menu.title}/>
                                    </ListItem>
                                </AccordionSummary>
                                <AccordionDetails sx={{padding: 0, paddingBottom: 3}}>
                                    {menu.items.map(item=>
                                        <ListItem key={item.url} disablePadding>
                                            <ListItemButton onClick={handleMenuItemClick(item.url)} sx={{paddingTop: 0, paddingBottom: 0}}>
                                                <ListItemIcon></ListItemIcon>
                                                <ListItemText primary={item.title}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                </AccordionDetails>
                            </Accordion>
                            <Divider />
                        </div> 
                    )})}
                    <NavListItem 
                        item={{     
                            mobile: true,
                            title: "Logout",
                            url: "/logout",
                            icon: ExitToAppOutlinedIcon   
                        }} 
                        handleMenuItemClick={handleMenuItemClick}
                    />
                    <br/>
                    <div style={{width: "100%", textAlign: "center", marginTop: 12, marginBottom: 12}}>
                        <OrganizationMenu />
                    </div>
                    <br />
                    <CreateButton />
                    <div style={{width: "100%", textAlign: "center", marginTop: 20}}>
                        <ThemeSwitch
                            checked={theme.palette.mode != "dark"}
                            onChange={toggleTheme}
                        />
                    </div>
                </List>
            </Box>
        </Modal>
    )
}

const NavListItem = ({item, handleMenuItemClick}) => {
    return (
        <div key={item.url}>
            <ListItem disablePadding>
                <ListItemButton onClick={handleMenuItemClick(item.url)}>
                    <ListItemIcon>
                        <item.icon/>
                    </ListItemIcon>
                    <ListItemText primary={item.title}/>
                </ListItemButton>
            </ListItem>
            <Divider />
        </div>
    )
}