import { useState, useEffect, useMemo } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Chip from "@mui/material/Chip";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";

import { request } from "@/Api";
import { BID_ACTIVITY_SIGNED, BID_STATUS_SIGNED, bidStatuses } from "@/bid/constants";
import { Heading, Title } from "@/common/StyledComponents";
import { formattedCurrency } from "@/utils";
import BidRowButtons from "@/bids/BidRowButtons";

export default function Bids(){
    const statuses = useMemo(() => bidStatuses.toReversed(), [])
    const [bids, setBids] = useState([])

    const handleBidFileUploaded = (bidId, files) => {
        request.post(`/bids/${bidId}/activity`, {action: BID_ACTIVITY_SIGNED, note: "Confirm client signed by uploading existing signature"})
        .then(()=>{
            setBids([...bids].map(bid => {
                return (bid.id != bidId) ? bid : {...bid, status: BID_STATUS_SIGNED, files: files}
            }))
        })
    }

    const getMyBids = () =>{
        request.get(`/bids`).
        then((response)=>{
            setBids(response.data)
        })
    }

    const handleBidDeleteConfirm = (bidId, status) => {
        request.delete(`/bids/${bidId}`)
        .then(()=>{
            setBids(bids.filter((bid)=>{return bid.id != bidId}))
        })
    }

    useEffect(getMyBids, [])
    return(
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"}>
                <Heading style={{paddingTop: 12}}>Bids Sheets</Heading>
            </Grid>
            {statuses.map((status)=>{
                return(
                <div key={status}>
                    {MappedBids(bids, statuses)[status]?.length > 0 ?
                    <div key={status}>
                        <Title style={{margin: 15, marginTop: 65}}>{status}</Title>
                        <TableContainer>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Name</TableCell>
                                        <TableCell align="left">Project</TableCell>
                                        <TableCell align="left">Client</TableCell>
                                        <TableCell align="right">Quoted</TableCell>
                                        <TableCell align="right">Margin</TableCell>
                                        <TableCell align="right"></TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {MappedBids(bids, statuses)[status].map((bid)=>{
                                        return(
                                            <TableRow key={bid.id}>
                                                <CustomTableCell widthauto width={150}>
                                                    <Link href={`/bids/${bid.id}`}>
                                                        {bid.name}
                                                    </Link>
                                                </CustomTableCell>
                                                <CustomTableCell width={220} align="left">
                                                    <Link href={`/projects/${bid.project.id}`}>
                                                        {bid.project.name}
                                                    </Link>
                                                </CustomTableCell>
                                                <CustomTableCell width={220} align="left">{bid.project.client.name}</CustomTableCell>
                                                <CustomTableCell width={80} align="right">{formattedCurrency(bid.client_quoted, bid.project.currency)}</CustomTableCell>
                                                <CustomTableCell width={80} align="right">{(bid.gross_profit_margin*100).toFixed(2)+"%"}</CustomTableCell>
                                                <CustomTableCell width={150} align="right">
                                                    <Chip label={bid.is_change_order ? "Change Order" : "Bid Sheet"} variant="filled" ></Chip>
                                                </CustomTableCell>
                                                <CustomTableCell width={200} align="right">
                                                    <BidRowButtons 
                                                        bid={bid} 
                                                        handleDelete={()=>handleBidDeleteConfirm(bid.id, bid.status)}
                                                        handleBidFileUploaded={handleBidFileUploaded}
                                                    />
                                                </CustomTableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div> : null}
                </div>)
            })}
        </>
    )

    function CustomTableCell(props){
        const {width, children, widthauto} = props
        return (
            <TableCell 
                {...props}
                sx={{
                    minWidth: width, 
                    maxWidth: width, 
                    width: widthauto ? "auto" : width,
                    whiteSpace: "nowrap", 
                    overflow: "hidden", 
                    textOverflow: "ellipsis",
                }} 
            >    
                {children}
            </TableCell>
        )
    }
}

const MappedBids = (bids, statuses) => {
    return statuses.reduce((obj, status) => {
        return {
            ...obj,
            [status]: bids.filter((bid) => bid.status == status && bid.project_id)
        }
    }, {})
}