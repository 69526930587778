import styled from "styled-components"

import { Grid, IconButton, Button, CircularProgress } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import CorporateFareIcon from '@mui/icons-material/CorporateFare';

import { baseTheme } from "@/themes"
import { AccountSectionTitle } from "@/account/StyledComponents"

function SectionEdit({ title, editing, loading, handleEdit, handleSave, handleCancel }) {
    return (
        <Grid container justifyContent="space-between" alignItems="center">
            <Grid item><AccountSectionTitle>{ title }</AccountSectionTitle></Grid>
            <Grid item>
                {editing ? (
                    <Grid container spacing={2} justifyContent="flex-end" alignItems="center">
                        {loading ? (
                            <Grid item>
                                <CircularProgress size={24} />
                            </Grid>
                        ): null} 
                        <Grid item>
                            <Button
                                onClick={handleSave}
                                disabled={loading}
                                variant="contained"
                                color="primary"
                            >
                                Save
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                onClick={handleCancel}
                                disabled={loading}
                                variant="contained"
                                color="secondary"
                            >
                                    Cancel
                            </Button>
                        </Grid>
                    </Grid>
                ) : (
                    <IconButton size="small" onClick={handleEdit}><EditIcon /></IconButton>
                ) }
            </Grid>
        </Grid>
    )
}

const Square = styled.div`
    background: white;
    border: 1px solid #D3D3D3;
    border-radius: 8px;
    padding: 6px;

    > img {
        display: block;
        width: ${props => props.size+"px" || "36px"};
        height: ${props => props.size+"px" || "36px"};
    }
`

function OrgImage({image, size}){
    const imageUrl = image ? (image.processed ? image.url_icon : image.url_orig) : null
    return (
        <Square size={size}>
            {imageUrl ? (
                <img src={imageUrl} />
            ) : (
                <CorporateFareIcon style={{ display: "block", fontSize: size-6, color: baseTheme.palette.text.primary }} />
            )}
        </Square>
    )
}

export { SectionEdit, OrgImage }
