import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import styled from "styled-components"

import IconButton from '@mui/material/IconButton'
import Link from "@mui/material/Link"
import Grid from "@mui/material/Grid"
import Chip from "@mui/material/Chip"

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import { request } from "@/Api"
import { Heading, Title } from "@/common/StyledComponents"
import BidTemplatePopup from "@/bids/BidTemplatePopup"
import DeleteRowButton from "@/common/DeleteRowButton"
import { selectHasPermission, selectIsBaseOrg } from "@/auth/authSlice"
import { MakersIcon } from "@/common/CommonComponents"

const BidType = styled.div`
    margin-top: 48px;
`
const Indented = styled.div`
    padding-left: 30px;
`

export default function TemplateBids(){
    const canViewGlobalTemplates = useSelector(state => selectHasPermission(state, "create_global_bid_templates"))
    const isBaseOrg = useSelector(selectIsBaseOrg)
    const [templates, setTemplates] = useState({})
    const [bidTemplatePopupOpen, setBidTemplatePopupOpen] = useState(false)

    const getTemplateBids = () => {
        request.get("/bid-templates")
        .then((response)=>{
            const bid_templates_raw = response.data;
            setTemplates(bid_templates_raw.reduce((acc, template) => {
                if(template.person_id || canViewGlobalTemplates){
                    const category = template.category || "My Templates"
                    const tab = template.person_id ? "Custom" : "Makers"
                    acc[tab] = acc[tab] || {}
                    acc[tab][category] = acc[tab][category] || []
                    acc[tab][category].push(template)
                }
                return acc
            }, {}))
        })
    }
    useEffect(()=>{
        getTemplateBids()
    }, [])

    const removeTemplateAndTemplateBid = (templateId, bidId, type, category) => {
        request.delete(`/bid-templates/${templateId}`)
        .then(()=>{
            request.delete(`/bids/${bidId}`)
            .then(()=>{
                const newTemplates = templates[type][category].filter((template)=>{return template.id != templateId})
                setTemplates({...templates, [type]: {...templates[type], [category]: newTemplates}})
            })
        })
    }

    return (
        <>
            <Grid container justifyContent={"space-between"} alignItems={"center"} sx={{ marginBottom: "36px" }}>
                <Grid item>
                    <Heading style={{paddingTop: 12}}>{`${isBaseOrg ? "Global" : ""} Template Bids`}</Heading>
                </Grid>
                <Grid item>
                    <Chip
                        clickable
                        onClick={()=>{setBidTemplatePopupOpen(true)}}
                        label={"Create Template"}
                        variant={"outlined"}
                    />
                </Grid>
            </Grid>
            {Object.entries(templates).map((type)=>{return(
                <BidType key={type[0]}>
                    <Title>{type[0]}</Title>
                    {Object.entries(type[1]).map((category)=>{return(
                        <Indented key={category[0]}>
                            <p>{category[0]}</p>
                            {category[1].map((template)=>{return(
                            <Indented key={template.id}>
                                <Grid container justifyContent={"space-between"} borderBottom={"1px solid grey"} alignItems={"center"} paddingTop={2} paddingBottom={2}>
                                    <Grid item>
                                        <Link href={template.public && !isBaseOrg ? "#" : `/bids/${template.bid_id}`} >
                                            {template.name}
                                        </Link>
                                    </Grid>
                                    {template.public && !isBaseOrg ? 
                                        <Grid item padding={1}>
                                            <MakersIcon />
                                        </Grid>
                                        :
                                        <Grid item>
                                            <Grid container justifyContent={"end"}>
                                                <Grid item>
                                                    <DeleteRowButton handleDeleteConfirm={()=>{removeTemplateAndTemplateBid(template.id, template.bid_id, type[0], category[0])}}/>
                                                </Grid>
                                                <Grid item>
                                                    <Link href={`/bids/${template.bid_id}`} color="inherit" >
                                                        <IconButton>
                                                            <ArrowCircleRightIcon />
                                                        </IconButton>
                                                    </Link>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    }
                                </Grid>
                            </Indented>
                            )})}
                        </Indented>
                    )})}
                </BidType>
            )})}

            <BidTemplatePopup 
                bidTemplatePopupOpen={bidTemplatePopupOpen} 
                setBidTemplatePopupOpen={setBidTemplatePopupOpen}
            />
        </>
    )
}