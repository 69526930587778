import { useSelector, useDispatch } from "react-redux"

import Alert from "@mui/material/Alert"
import IconButton from "@mui/material/IconButton"
import Snackbar from "@mui/material/Snackbar"

import CloseIcon from "@mui/icons-material/Close"

import { selectError, clearError } from "@/nav/navSlice"

export default function Error() {
    const error = useSelector(selectError)
    const dispatch = useDispatch()

    const handleClose = () => {
        dispatch(clearError())
    }
    
    return (
        <Snackbar
            open={error.open}
            autoHideDuration={error.autoHideDuration}
            onClose={handleClose}
        >
            <Alert
                variant="filled"
                severity="error"
                action={
                    <IconButton
                        size="small"
                        aria-label="close"
                        color="inherit"
                        onClick={handleClose}
                    >
                        <CloseIcon fontSize="small" />
                    </IconButton>
                }
            >
                {error.message}
            </Alert>
        </Snackbar>
    )
}
