import { useState } from 'react';
import { useSelector } from 'react-redux';

import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Badge from "@mui/material/Badge"
import IconButton from "@mui/material/IconButton"
import FilterListIcon from '@mui/icons-material/FilterList';
import Grid from '@mui/material/Unstable_Grid2';

import CustomFiltersDropdown from '@/table/CustomFiltersDropdown';
import CustomFiltersSavePopup from '@/table/CustomFiltersSavePopup';
import { Heading } from '@/common/StyledComponents';
import { selectPersonId } from '@/auth/authSlice';

export default function CustomFilters(props) {
    const {
        title, modelType, filterComponents,
        filters, setFilters,
        order, setOrder, defaultOrder,
        customFilters, setCustomFilters,
        activeCustomFilter, setActiveCustomFilter,
    } = props
    const Filters = filterComponents
    const [expanded, setExpanded] = useState(false)
    const personId = useSelector(selectPersonId)

    const handleExpanded = (event, value) => {
        setExpanded(value)
    }

    const handleInputChange = (filterName) => (event) => {
        setFilters({...filters, [filterName]: "like:"+event.target.value})
    }

    const handleAutocompleteChange = (filterName) => (event, newValue) => {
        if(newValue){
            setFilters({...filters, [filterName]: "eq:"+newValue})
        }
    }

    const handleAutocompleteClear = (filterName) => (event, newValue, reason) => {
        if (reason == "clear"){
            let newFilters = {...filters}
            delete newFilters[filterName]
            setFilters(newFilters)
        }
    }

    return (
        <Accordion sx={{background: "transparent", boxShadow: "none", marginBottom: "12px", '&::before': {content: 'unset'}}} expanded={expanded} onChange={handleExpanded}>
            <AccordionSummary
                expandIcon={
                    <Badge
                        color="primary"
                        variant="dot"
                        invisible={Object.keys(filters).length == 0}
                        anchorOrigin={expanded ?
                            {
                                vertical: 'bottom',
                                horizontal: 'left',
                            } : 
                            {
                                vertical: 'top',
                                horizontal: 'right',
                            }
                        }
                    >
                        <IconButton sx={{background:"#ffffff22"}}>
                            <FilterListIcon />
                        </IconButton>
                    </Badge>
                }
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{ padding: 0 }}
            >
                <Heading>{title}</Heading>
            </AccordionSummary>

            <AccordionDetails sx={{ padding: "8px 0" }}>
                <CustomFiltersDropdown
                    modelType={modelType}
                    setFilters={setFilters}
                    defaultOrder={defaultOrder}
                    setOrder={setOrder}
                    customFilters={customFilters}
                    setCustomFilters={setCustomFilters}
                    activeCustomFilter={activeCustomFilter}
                    setActiveCustomFilter={setActiveCustomFilter}
                />
                
                <Filters
                    filters={filters}
                    setFilters={setFilters}
                    handleInputChange={handleInputChange}
                    handleAutocompleteChange={handleAutocompleteChange}
                    handleAutocompleteClear={handleAutocompleteClear}
                />

                <Grid sx={{ paddingTop: 2 }}>
                    <CustomFiltersSavePopup
                        customFilters={customFilters}
                        setCustomFilters={setCustomFilters}
                        setActiveCustomFilter={setActiveCustomFilter}
                        filters={filters}
                        orders={order}
                        personId={personId}
                        modelType={modelType}
                    />
                </Grid>

            </AccordionDetails>
        </Accordion>
    )
}
