import { useState } from "react"
import { useNavigate } from "react-router-dom"

import { Grid, Button } from "@mui/material"

import { request } from "@/Api"
import { AccountSectionTitle, AccountSectionRow, AccountSubtitle } from "@/account/StyledComponents"
import ConfirmationDialog from "@/common/ConfirmationDialog"

export default function ManagementSection({ setError }) {
    const navigate = useNavigate()
    const [ closeConfirm, setCloseConfirm ] = useState(false)

    const close = () => {
        request.delete("account").then(response => {
            navigate("/logout")
        }).catch(error => {
            console.log(error)
            setError("Failed to close account. Please contact support at central@makers.to")
        })
    }

    return (
        <Grid item>
            <AccountSectionTitle>Account Management</AccountSectionTitle>

            <AccountSectionRow>
                <AccountSubtitle>Close account</AccountSubtitle>
                <Button
                    onClick={() => setCloseConfirm(true)}
                    color="error"
                    variant="contained"
                >
                    Close account
                </Button>
            </AccountSectionRow>
            
            <ConfirmationDialog
                open={closeConfirm}
                closeDialog={() => setCloseConfirm(false)}
                title="Are you sure?"
                callback={close}
            >
                {`This will remove all your data and is irreversible.`}
            </ConfirmationDialog>
        </Grid>
    )
}
