import { CustomBox, Heading } from "@/common/StyledComponents";
import { Autocomplete, Grid, InputLabel, Modal, TextField, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { closeLinePopup, selectLinePopupOpen, updateLine } from "./actualSlice";
import { Button } from "@mui/material";
import { request } from "@/Api";
import { useDispatch } from "react-redux";

export function ActualLinePopup({ projectId, getProject }){
    const [ actualLine, setActualLine ] = useState({})
    const dispatch = useDispatch()
    const theme = useTheme()
    const open = useSelector(selectLinePopupOpen)

    useEffect(()=>{
        setActualLine({...actualLine, "project_id": projectId})
    }, [])

    const createActualLine = () => {
        request.post("actual-lines", actualLine)
        .then(response=>{
            getProject()
            dispatch(updateLine(response.data))
            dispatch(closeLinePopup())
            setActualLine({"project_id": projectId})
            document.documentElement.scrollTo({
                top: document.body.scrollHeight,
                left: 0,
                behavior: "smooth",
            })
        })
    }
    
    const handleInput = (field) => (event) => {
        setActualLine({...actualLine, [field]: event.target.value})
    }

    const handleCancel = () => {
        dispatch(closeLinePopup())
        setActualLine({})
    }

    return (
        <Modal open={open} onClose={()=>{dispatch(closeLinePopup())}}>
            <CustomBox style={{background: theme.palette.background.paper, width: 400, padding: 40}}>
                <Heading style={{paddingBottom: 20}}>Create an actual line</Heading>
                <InputLabel>Name</InputLabel>
                <TextField 
                    sx={{marginBottom: 3}}
                    fullWidth
                    value={actualLine.name || ""}
                    onInput={handleInput("name")}
                />
                <Grid container justifyContent={"right"} spacing={2}>
                    <Grid item><Button variant="contained" color="secondary" onClick={handleCancel}>Cancel</Button></Grid>
                    <Grid item><Button variant="contained" onClick={createActualLine}>Create</Button></Grid>
                </Grid>
            </CustomBox>
        </Modal>
    )
}