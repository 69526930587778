import { Button, Grid, Paper, useTheme } from "@mui/material";
import { AccountingBill, AccountingBillHeaderLine } from "./AccountingBill";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SearchBox } from "@/common/StyledComponents";
import { openLinesPopup, searchBills } from "./actualSlice";
import { useDispatch } from "react-redux";

export default function AccountingBills({bills, selectable}){
    return (
        <>
            <AccountingBillHeaderLine selectable={selectable}/>
            {bills.length > 0 ? 
            <>
                {bills.map((bill, index)=>
                    <AccountingBill
                        key={index}
                        billId={bill.id}
                        selectable={selectable}
                    />
                )}
            </> : 
            <>
                <Paper sx={{textAlign: "center", boxShadow: "none", padding: 4}}>
                    <span style={{fontSize: 14, color: "gray"}}>No bills here</span>
                </Paper>
            </>
            }
        </>
    )
}

export const AccountingBillsHeader = () => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const handleSearch = (event) => {
        const value = event.target.value
        dispatch(searchBills(value))
    }
    return (
        <Grid 
            container justifyContent={"space-between"} 
            alignItems={"center"} paddingBottom={2} 
            position={"sticky"} top={-1} zIndex={1} padding={1}
            sx={{background: theme.palette.background.default}}
        >
            <Grid item>
                <h4>Bills</h4>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button onClick={()=>{dispatch(openLinesPopup())}} variant="contained" startIcon={<EditOutlinedIcon/>}>
                            Assign to Line Item(s)
                        </Button>
                    </Grid>
                    <Grid item>
                        <SearchBox handleSearch={handleSearch} />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}