import { useSelector } from 'react-redux';
import { selectAddons, selectClientCurrency, selectOfficeCurrency } from "@/bid/bidSlice";
import { selectInsights } from "@/bid/bidSlice";
import { formattedCurrency } from "@/utils";

const Addon = ({ label, number, currency, percentageExplicitZero }) => {
    return number > 0 || percentageExplicitZero ? (
        <p style={{color: "gray", fontSize: "12px"}}>
            {`${label}: ${formattedCurrency(number || 0, currency)}`}
        </p>
    ) : null
}

export default function BidTotals() {
    const insights = useSelector(selectInsights)
    const addons = Object.values(useSelector(selectAddons))
    const clientCurrency = useSelector(selectClientCurrency)
    return (
        <div style={{textAlign: "right", borderTop: "solid 1px grey"}}>
            <Addon label="Insurance" number={insights.insurance} currency={clientCurrency} />
            {addons.map((addon)=>{
                const percentageQuotedExplicitZero = 
                    addon.type == "percentage" && 
                    addon.amount != 0 && 
                    addon.quoted == 0
                return (
                    <Addon 
                        key={addon.name} label={addon.name} 
                        number={addon.quoted} currency={clientCurrency} 
                        percentageQuotedExplicitZero={percentageQuotedExplicitZero} 
                    />
                )
            })}
        </div>
    )
}
