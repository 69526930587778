import { configureStore } from "@reduxjs/toolkit"
import authReducer from "@/auth/authSlice"
import navReducer from "@/nav/navSlice"
import bidReducer from "@/bid/bidSlice"
import actualReducer from "@/actuals/actualSlice"

export default configureStore({
  reducer: {
    auth: authReducer,
    nav: navReducer,
    bid: bidReducer,
    actual: actualReducer
  },
})
