import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";

import { Grid } from "@mui/material";
import { Autocomplete, Button, CircularProgress, Divider, InputLabel, Modal, TextField, useTheme } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';

import { request } from "@/Api";
import { CustomBox, Heading } from "@/common/StyledComponents";
import { LimitedTextField } from "@/common/CommonComponents";
import ApiAutocomplete from "@/common/ApiAutocomplete";
import { roles } from "@/project_profiles/constants";
import { ProjectProfileImageUploader } from "@/project_profiles/ProjectProfileImageUploader";
import ProtectedComponents from "@/auth/ProtectedComponents";
import { selectIsLoggedIn, selectPersonProfileId } from "@/auth/authSlice";

export function ProjectProfilePopup({projectProfile, projectPopupOpen, closePopup, addProjectProfile, updateProjectProfile, orgProfileId}){
    const theme = useTheme()

    const [ projectProfileLocal, setProjectProfileLocal ] = useState()
    const [ errors, setErrors ] = useState({})
    const [ images, setImages ] = useState([])
    const [ updating, setUpdating ] = useState(false)

    const isLoggedIn = useSelector(selectIsLoggedIn)
    const personProfileId = useSelector(selectPersonProfileId)

    useEffect(()=>{
        if (!isLoggedIn){
            return
        }
        if (projectProfile){
            const mappedProjectProfile = mapProjectProfile(projectProfile)
            setProjectProfileLocal(mappedProjectProfile)
            setImages(mappedProjectProfile.images)
        }else{
            setProjectProfileLocal({
                owner_profile_id: personProfileId,
                person_profile_ids: [personProfileId],
                new_project_profile_types: [],
                project_profile_type_ids: []
            })
        }
    }, [projectProfile])

    const handleAddProjectProfile = () => {
        setUpdating(true)
        request.post(`project-profiles`, { ...projectProfileLocal, org_profile_id: orgProfileId })
        .then(response=>{
            setErrors({})
            if (addProjectProfile){
                addProjectProfile(response.data)
            }
            uploadImages(response.data.id)
            closePopup()
        })
        .catch(error=>{
            if (error.response.status == 422){
                const details = error.response.data.detail
                setErrors(Object.fromEntries(details.map(detail => [
                    detail.loc[detail.loc.length-1], detail.msg 
                ])))
            }
        })
        .finally(()=>{
            setUpdating(false)
        })
    }

    const handleUpdateProjectProfile = () => {
        setUpdating(true)
        console.log(projectProfileLocal)
        request.put(`project-profiles/${projectProfile.id}`, projectProfileLocal)
        .then(updateResponse=>{
            setErrors({})
            let promises = [...uploadImages(updateResponse.data.id), ...deleteImages(updateResponse.data.id)]
            Promise.all(promises)
            .then((imageUpdateResponses)=>{
                const response = imageUpdateResponses[0] ? imageUpdateResponses[0] : updateResponse
                addProjectProfile ? addProjectProfile(response.data) : updateProjectProfile(response.data)
                closePopup()
                setUpdating(false)
            })
        })
        .catch(error=>{
            if (error.response.status == 422){
                const details = error.response.data.detail
                setErrors(Object.fromEntries(details.map(detail => [
                    detail.loc[detail.loc.length-1], detail.msg 
                ])))
            }
            setUpdating(false)
        })
    }

    const deleteImages = (projectProfileId) => {
        let imageIdsMarkedDelete = []
        images.forEach(image=>{
            if (image.id && image.markedDelete){
                imageIdsMarkedDelete.push(image.id)
            }
        })
        if (imageIdsMarkedDelete.length == 0){
            return []
        }
        return [request.delete(`project-profiles/${projectProfileId}/image`, {data: imageIdsMarkedDelete})]
    }

    const uploadImages = (projectProfileId) => {
        const promises = []
        const newImages = images.filter(image=>image.file)
        if (newImages.length == 0){
            return promises
        }
        newImages.forEach(image => {
            const formData = new FormData()
            formData.append("file", image.file, image.file.name)
            formData.append("is_flagged", image.is_flagged ? "True" : "False")
            formData.append("project_profile_id", projectProfileId)
            promises.push(
                request.post(`project-profiles/${projectProfileId}/image`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                })
            )
        })
        return promises
    }

    const handleAutocompleteInput = (newField) => (event) => {
        setProjectProfileLocal({...projectProfileLocal, [newField]: {name: event.target.value}})
    }

    const handleLocationsChange = (event, value) => {
        setProjectProfileLocal({
            ...projectProfileLocal,
            locations: value,
        })
    }

    // TODO: move to seperate file as it's being reused in all profiles
    const handleAutocompleteChange = (field, idField, newField) => (event, value) => {
        let ids = null
        let newObjects = null
        if (Array.isArray(value)){
            ids = []
            for(var i = 0; i < value.length; i ++){
                const obj = value[i]
                if (typeof obj == "string"){
                    value[i] = {"name": obj, "id": `new-${obj}`}
                }else if (typeof obj == "object" && typeof obj.id != "string"){
                    ids.push(obj.id)
                }else if (typeof obj == "number"){
                    ids.push(obj)
                }
            }
            newObjects = value.filter(obj => obj?.id && obj.id.toString().includes("new-"))
        } else {
            if (typeof value == "object"){
                ids = value.id
            }
            else if (typeof value == "number"){
                ids = value
            } 
            else if (typeof value == "string"){
                // pass
            }
        }
        setProjectProfileLocal({
            ...projectProfileLocal, 
            [idField]: ids, 
            [field]: value, 
            [newField]: newObjects
        })
    }

    const handleDateChange = (field) => (val) => {
        if (!isNaN(Date.parse(val))){
            setProjectProfileLocal({...projectProfileLocal, [field]: format(val, "y-MM-dd")})
        }
    }

    const handleInputChange = (field) => (event) => {
        setProjectProfileLocal({...projectProfileLocal, [field]: event.target.value})
    }

    if (!projectProfileLocal){
        return
    }

    return (
        <>
            <Modal open={projectPopupOpen} onClose={closePopup}>
                <CustomBox style={{background: theme.palette.background.paper, padding: 40, paddingBottom: 0, overflow: "auto"}}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"} paddingBottom={2}>
                        <Grid item>
                            <Heading>
                                {addProjectProfile ? "Add Project" : "Edit Project"}
                            </Heading>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={0.5} onClick={closePopup} sx={{cursor: "pointer"}}>
                                <Grid item>
                                    <span>Close</span>
                                </Grid>
                                <Grid item>
                                    <CloseRoundedIcon fontSize="small"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />

                    <h3>General</h3>
                    {orgProfileId &&
                        <div style={{background: "#ffffff11", borderRadius: 10, padding: 20, marginBottom: 20, border: "1px red dashed"}}>
                            <ProtectedComponents permission="view_admin_panel">
                                <InputLabel required>Owner (Admin)</InputLabel>
                                <ApiAutocomplete
                                    size="small"
                                    error={errors.owner_profile_id ? true : false}
                                    helperText={errors.owner_profile_id}
                                    sx={{paddingBottom: 2}}
                                    value={projectProfileLocal.owner_profile_id}
                                    apiRoute={"persons"}
                                    fields={["first_name", "last_name"]}
                                    onChange={handleAutocompleteChange("owner_profile", "owner_profile_id")}
                                />
                                <InputLabel required>Producers (Admin)</InputLabel>
                                <ApiAutocomplete
                                    multiple
                                    size="small"
                                    error={errors.person_profile_ids ? true : false}
                                    helperText={errors.person_profile_ids}
                                    sx={{paddingBottom: 2}}
                                    value={projectProfileLocal.person_profile_ids || []}
                                    apiRoute={"persons"}
                                    fields={["first_name", "last_name"]}
                                    onChange={handleAutocompleteChange("person_profiles", "person_profile_ids")}
                                />
                            </ProtectedComponents>
                        </div>
                    }

                    <InputLabel required>Project Name</InputLabel>
                    <TextField 
                        error={errors.name ? true : false}
                        helperText={errors.name}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.name || ""}
                        onInput={handleInputChange("name")}
                    />
                    <InputLabel required>Client</InputLabel>
                    <ApiAutocomplete
                        error={(errors.new_client_profile) ? true : false}
                        helperText={errors.new_client_profile}
                        size="small"
                        sx={{paddingBottom: 2}}
                        fullOptionData
                        value={projectProfileLocal.client_profile || ""}
                        apiRoute={"client-profiles"}
                        fields={["name"]}
                        freeSolo
                        onChange={handleAutocompleteChange("client_profile", "client_profile_id", "new_client_profile")}
                        onInput={handleAutocompleteInput("new_client_profile")}
                    />
                    <InputLabel>Role</InputLabel>
                    <Autocomplete
                        size="small"
                        sx={{paddingBottom: 2}}
                        value={projectProfileLocal.role || null}
                        disablePortal
                        options={roles}
                        renderInput={(params) => <TextField {...params} />}
                        onChange={handleAutocompleteChange("role")}
                    /> 
                    <InputLabel required>Locations</InputLabel>
                    <ApiAutocomplete
                        multiple
                        size="small"
                        error={errors.locations ? true : false}
                        helperText={errors.locations}
                        sx={{paddingBottom: 2}}
                        fullOptionData
                        value={projectProfileLocal.locations || []}
                        apiRoute={"locations"}
                        fields={["full_name"]}
                        onChange={handleLocationsChange}
                        onInputResponseLimit={30}
                    />
                    <InputLabel required>Wrap Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat={"yyyy-MM-dd"}
                            value={projectProfileLocal.wrap_date || null}
                            onChange={handleDateChange("wrap_date")}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    sx={{paddingBottom: 2}}
                                    fullWidth
                                    {...params}
                                    error={errors.wrap_date ? true : false}
                                    helperText={errors.wrap_date}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <InputLabel>Reel URL (Vimeo or YouTube)</InputLabel>
                    <TextField 
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.reel_url || ""}
                        onInput={handleInputChange("reel_url")}
                    />

                    <h3>Info</h3>
                    <InputLabel>Brief</InputLabel>
                    <LimitedTextField 
                        multiline
                        rows={4}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.description || ""}
                        onInput={handleInputChange("description")}
                        maxLength={1000}
                    />
                    <InputLabel>Budget</InputLabel>
                    <TextField 
                        error={errors.budget ? true : false}
                        helperText={errors.budget}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.budget || ""}
                        onInput={handleInputChange("budget")}
                    />
                    <InputLabel>Project Types</InputLabel>
                    <ApiAutocomplete 
                        freeSolo
                        fullOptionData
                        multiple
                        onChange={handleAutocompleteChange("project_profile_types", "project_profile_type_ids", "new_project_profile_types")}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.project_profile_types}
                        fields={["name"]}
                        apiRoute={"project-profile-type"}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <InputLabel>Market</InputLabel>
                    <TextField 
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.market || ""}
                        onInput={handleInputChange("market")}
                    />
                    <InputLabel>Highlight</InputLabel>
                    <LimitedTextField 
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={projectProfileLocal.highlight || ""}
                        onInput={handleInputChange("highlight")}
                        maxLength={40}
                    />
                    <InputLabel sx={{paddingBottom: 2}}>Images (8 max)</InputLabel>
                    <ProjectProfileImageUploader 
                        images={images}
                        setImages={setImages}
                        setFlaggedImageId={(imageId)=>{
                            setProjectProfileLocal({...projectProfileLocal, flagged_image_id: imageId})
                        }}
                    />

                    <div style={{position: "sticky", bottom: 0, paddingBottom: 20, marginTop: 50, background: theme.palette.background.paper}}>
                        <Grid container justifyContent={"right"} spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained" color="secondary"
                                    size="small" onClick={closePopup}
                                >Cancel</Button>
                            </Grid>
                            {
                                updating ?
                                <CircularProgress /> :
                                <Grid item>
                                <Button
                                    variant="contained" color="primary"
                                    size="small" endIcon={<CheckRoundedIcon/>
                                } onClick={()=>{
                                    if (addProjectProfile){
                                        handleAddProjectProfile()
                                    }else{
                                        handleUpdateProjectProfile()
                                    }
                                }}>
                                    {addProjectProfile ? "Create" : "Save"}
                                </Button>
                            </Grid>}
                        </Grid>
                    </div>
                </CustomBox>
            </Modal>
        </>
    )
}

const mapProjectProfile = (projectProfile) => {
    return {
        ...projectProfile, 
        person_profile_ids: projectProfile.person_profiles.map(person_profile=>person_profile.id),
        images: projectProfile.images.map(image=>{
            return {...image, is_flagged: projectProfile.flagged_image && image.id == projectProfile.flagged_image.id}
        }),
        flagged_image_id: projectProfile.flagged_image?.id,
        new_project_profile_types: [],
        project_profile_type_ids: projectProfile.project_profile_types.map(pt=>pt.id)
    }
}