import { useEffect, useState } from "react"

import { useTheme } from '@mui/material/styles'
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Grid from "@mui/material/Grid"
import ClickAwayListener from "@mui/material/ClickAwayListener"

import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined' // project
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined' // bid
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined' // team
import FolderOpenIcon from '@mui/icons-material/FolderOpen' // client
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'

import { formattedCurrency } from "@/utils"
import { SearchBox } from "@/common/StyledComponents"
import { request } from "@/Api"

export default function GlobalSearch() {
    const [isActive, setIsActive] = useState(false)
    const [searchString, setSearchString] = useState("")
    const [searchResult, setSearchResult] = useState(null)

    const search = () => {
        request.get("/search", {params: {search_string: searchString}})
        .then((response)=>{
            setSearchResult(response.data)
        })
    }

    useEffect(()=>{
        if (!isActive){
            setIsActive(true)
        }else{
            const debounceTimeout = setTimeout(()=>{search()}, 250);
            return () => clearTimeout(debounceTimeout)
        }
    }, [searchString])

    return (
        <div style={{margin: "auto", width: "30%"}} >
            <SearchBox handleSearch={(e)=>{setSearchString(e.target.value)}}/>
            {searchString ?
                <GlobalSearchResult searchResult={searchResult} />
            : null}
        </div>
    )
}

const GlobalSearchResult = ({searchResult}) => {
    const [open, setOpen] = useState(true)
    const theme = useTheme()
    useEffect(()=>{
        setOpen(true)
    }, [searchResult])

    const hasResults = searchResult ? searchResult.projects.length>0 || searchResult.bids.length>0 || searchResult.persons.length>0 : false
    if (!hasResults){
        return null
    }
    const handleClickAway = () => {
        setOpen(false)
    }
    return(
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box
                zIndex={3}
                display={open ? "block" : "none"}
                borderRadius={5}
                marginTop={"10px"}
                position={"absolute"}
                width={400} minHeight={100}
                sx={{
                    background: theme.palette.mode=="dark" ? theme.palette.background.paper : "white",
                    boxShadow: "0px 0px 30px 3px rgba(0,0,0,0.2)"
                }}
            >
                <ResultGroup
                    route={"projects"}
                    title={"Projects"}
                    entities={searchResult.projects}
                    icon={WorkOutlineOutlinedIcon}
                    iconColor={"#A9E8CB"}
                />
                <ResultGroup
                    route={"bids"}
                    title={"Bid Sheets"}
                    entities={searchResult.bids}
                    icon={ArticleOutlinedIcon}
                    iconColor={"#FFD597"}
                />
                <ResultGroup
                    route={"profile"}
                    title={"People"}
                    entities={searchResult.persons}
                    icon={PersonOutlineOutlinedIcon}
                    iconColor={"#80C8FF"}
                />
                <ResultGroup
                    route={"clients"}
                    title={"Clients"}
                    entities={searchResult.clients}
                    icon={FolderOpenIcon}
                    iconColor={"#80C8FF"}
                />
            </Box>
        </ClickAwayListener>
    )
}

const ResultGroup = ({route, entities, title, icon, iconColor}) => {
    const theme = useTheme()
    const Icon = icon
    if (entities.length == 0){
        return null
    }
    return (
        <div style={{padding: 16}}>
            <div style={{marginBottom: 10, fontSize: 14}}>
                <span style={{color: "grey"}}>{title}</span>
            </div>
            {entities.map(entity=>
                <Button disableRipple 
                    sx={{pointerEvents: entity.id ? "all" : "none", color: theme.palette.text.primary, "&:hover": { backgroundColor: theme.palette.text.primary+"11" }}} 
                    href={`/${route}/${entity.id}`}
                >
                    <Grid key={entity.name} container alignItems={"center"}>
                        <Grid item sx={{background: iconColor, padding: 1, borderRadius: 2, marginRight: 1, lineHeight: 0}}>
                            <Icon sx={{color: "#232323"}} />
                        </Grid>
                        <Grid item>
                            <Grid item width={280} whiteSpace={"nowrap"} overflow={"hidden"} textOverflow={"ellipsis"}>
                                <span style={{fontSize: 14}}>
                                    {entity.name}
                                </span>
                            </Grid>
                            <Grid item sx={{color: "grey", fontSize: 11}}>
                                <span>
                                    {typeof entity.data1 == "number" ? formattedCurrency(entity.data1, entity.currency) : entity.data1}
                                </span>
                                {entity.data2 ? " | " : null}
                                <span>
                                    {entity.data2}
                                </span>
                            </Grid>
                        </Grid>
                        {entity.id ? <Grid item>
                                <ArrowCircleRightIcon />
                        </Grid> : null}
                    </Grid>
                </Button>
            )}
        </div>
    )
}
