import { useState } from "react"

import { Grid, TextField, Alert } from "@mui/material"

import { request } from "@/Api"
import { AccountSectionRow, AccountSubtitle, AccountInput } from "@/account/StyledComponents"
import { SectionEdit } from "@/account/CommonComponents"

export default function SecuritySection({ showGoogleAlert, setSaved, setError }) {
    const [ editing, setEditing ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ password, setPassword ] = useState("")
    const [ password2, setPassword2 ] = useState("")

    const handleSave = () => {
        if (password != password2) {
            setError("Passwords do not match")
            return
        }

        setError("")
        setLoading(true)

        request.patch('account', {password}).then(response => {
            setSaved(true)
            setEditing(false)
            setPassword("")
            setPassword2("")
        }).catch(error => {
            const msg = Array.isArray(error.response.data.detail) ? error.response.data.detail.map(e => e.msg).join(", ") : error.response.data.detail
            setError(msg || "Failed to save")
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleCancel = () => {
        setEditing(false)
        setPassword("")
        setPassword2("")
    }

    return (
        <Grid item>
            <SectionEdit
                title="Security"
                editing={editing}
                loading={loading}
                handleEdit={() => setEditing(true)}
                handleSave={handleSave}
                handleCancel={handleCancel}
            />
            
            {!editing && showGoogleAlert && (
                <Alert
                    variant="outlined"
                    severity="warning"
                    sx={{ width: '100%' }}
                >
                    This account has an associated Google account for login. Setting a password here will allow login with both Google and email/password options.
                </Alert>
            )}
            
            <AccountSectionRow>
                <AccountSubtitle>Password</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <TextField
                            value={password}
                            onChange={event => setPassword(event.target.value)}
                            type="password"
                            required
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p>********</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            {editing ? (
                <AccountSectionRow>
                    <AccountSubtitle>Confirm Password</AccountSubtitle>
                    <AccountInput>
                        <TextField
                            value={password2}
                            onChange={event => setPassword2(event.target.value)}
                            type="password"
                            required
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    </AccountInput>
                </AccountSectionRow>
            ) : null}
        </Grid>
    )
}