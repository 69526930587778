import { CustomBox, Heading } from "@/common/StyledComponents";
import { LimitedTextField } from "@/common/CommonComponents";
import { Divider, InputLabel, Modal, TextField, useTheme } from "@mui/material";
import { Grid, CircularProgress } from "@mui/material";
import { useEffect, useState } from "react";
import ApiAutocomplete from "@/common/ApiAutocomplete";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import { Button } from "@mui/material";
import { request } from "@/Api";
import { MapOrgProfile } from "./OrgProfile";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import format from "date-fns/format";

export function OrgProfilePopup({orgProfile, setOrgProfile, orgPopupOpen, closePopup}){
    const theme = useTheme()
    const [ orgProfileLocal, setOrgProfileLocal ] = useState()
    const [ errors, setErrors ] = useState({})
    const [ updating, setUpdating ] = useState(false)

    useEffect(()=>{
        setOrgProfileLocal(orgProfile)
    }, [orgProfile])

    if (!orgProfileLocal){
        return null
    }

    const handleUpdateOrgProfile = () => {
        setUpdating(true)
        request.put(`org-profiles`, orgProfileLocal)
        .then(response=>{
            setOrgProfile(MapOrgProfile(response.data))
            closePopup()
            setErrors({})
        })
        .catch(error=>{
            console.log(error)
            if (error.response.status == 422){
                const details = error.response.data.detail
                setErrors(Object.fromEntries(details.map(detail => [
                    detail.loc[detail.loc.length-1], detail.msg 
                ])))
            }else if (error.response.status == 409){
                setErrors({...errors, "handle": error.response.data.detail})
            }
        })
        .finally(()=>{
            setUpdating(false)
        })
    }

    const handleLocationChange = (event, value) => {
        setOrgProfileLocal({
            ...orgProfileLocal,
            location: value,
        })
    }

    const handleAutocompleteChange = (field, idField, newObjField) => (event, value) => {
        let ids = null
        let newObjects = null
        if (Array.isArray(value)){
            ids = []
            for(var i = 0; i < value.length; i ++){
                const obj = value[i]
                if (typeof obj == "string"){
                    value[i] = {"name": obj, "id": `new-${obj}`}
                }else if (typeof obj == "object" && typeof obj.id != "string"){
                    ids.push(obj.id)
                }
            }
            newObjects = value.filter(obj => obj.id.toString().includes("new-"))
        } else {
            if (value){
                ids = value.id
            } else {
                return
            }
        }
        setOrgProfileLocal({
            ...orgProfileLocal, 
            [idField]: ids, 
            [field]: value, 
            [newObjField]: newObjects
        })
    }

    const handleInputChange = (field) => (event) => {
        setOrgProfileLocal({...orgProfileLocal, [field]: event.target.value})
    }

    const handleDateChange = (field) => (val) => {
        if (!isNaN(Date.parse(val))){
            setOrgProfileLocal({...orgProfileLocal, [field]: format(val, "y-MM-dd")})
        }
    }

    return (
        <>
            <Modal open={orgPopupOpen} onClose={closePopup}>
                <CustomBox style={{background: theme.palette.background.paper, padding: 40, paddingBottom: 0, overflow: "auto"}}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"} paddingBottom={2}>
                        <Grid item>
                            <Heading>Edit Profile</Heading>
                        </Grid>
                        <Grid item>
                            <Grid container spacing={0.5} onClick={closePopup} sx={{cursor: "pointer"}}>
                                <Grid item>
                                    <span>Close</span>
                                </Grid>
                                <Grid item>
                                    <CloseRoundedIcon fontSize="small"/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Divider />
                    <h3>General</h3>
                    <InputLabel required>Name</InputLabel>
                    <TextField 
                        error={errors.name ? true : false}
                        helperText={errors.name}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={orgProfileLocal.name || ""}
                        onInput={handleInputChange("name")}
                    />
                    <InputLabel required>Join Date</InputLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            inputFormat={"yyyy-MM-dd"}
                            value={orgProfileLocal.join_date || null}
                            onChange={handleDateChange("join_date")}
                            renderInput={(params) => (
                                <TextField
                                    size="small"
                                    sx={{paddingBottom: 2}}
                                    fullWidth
                                    {...params}
                                    error={errors.join_date ? true : false}
                                    helperText={errors.join_date}
                                />
                            )}
                        />
                    </LocalizationProvider>
                    <InputLabel>Headline</InputLabel>
                    <LimitedTextField
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={orgProfileLocal.headline || ""}
                        onInput={handleInputChange("headline")}
                        maxLength={220}
                    />
                    <InputLabel>Handle</InputLabel>
                    <TextField 
                        error={errors.handle ? true : false}
                        helperText={errors.handle}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={orgProfileLocal.handle}
                        onInput={handleInputChange("handle")}
                        InputProps={{startAdornment: <span style={{color: "grey", whiteSpace: "nowrap"}}>{ `${window.location.origin}/company-profile/` }</span>}}
                    />
                    <InputLabel>Location</InputLabel>
                    <ApiAutocomplete
                        size="small"
                        error={errors.location ? true : false}
                        helperText={errors.location}
                        sx={{paddingBottom: 2}}
                        fullOptionData
                        value={orgProfileLocal.location || ""}
                        apiRoute={"locations"}
                        fields={["full_name"]}
                        onChange={handleLocationChange}
                        onInputResponseLimit={30}
                    />
                    <h3>Bio</h3>
                    <InputLabel>Background</InputLabel>
                    <LimitedTextField 
                        multiline
                        rows={4}
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={orgProfileLocal.description || ""}
                        onInput={handleInputChange("description")}
                        maxLength={2600}
                        error={errors.description ? true : false}
                        helperText={errors.description}
                    />
                    <InputLabel>Reel URL (Vimeo or YouTube)</InputLabel>
                    <TextField 
                        sx={{paddingBottom: 2}}
                        fullWidth
                        size="small"
                        value={orgProfileLocal.reel_url || ""}
                        onInput={handleInputChange("reel_url")}
                    />

                    <InputLabel>Clients</InputLabel>
                    <ApiAutocomplete
                        freeSolo
                        sx={{paddingBottom: 2}}
                        fullOptionData
                        multiple
                        value={orgProfileLocal.client_profiles}
                        apiRoute={"client-profiles"}
                        fields={["name"]}
                        onChange={handleAutocompleteChange("client_profiles", "client_profile_ids", "new_client_profiles")}
                    />

                    <div style={{position: "sticky", bottom: 0, paddingBottom: 20, background: theme.palette.background.paper}}>
                        <Grid container justifyContent={"right"} spacing={2}>
                            <Grid item>
                                <Button
                                    variant="contained" color="inherit"
                                    size="small" onClick={closePopup}
                                >Cancel</Button>
                            </Grid>
                            <Grid item>
                                {updating ? <CircularProgress /> : 
                                    <Button
                                        variant="contained" color="primary"
                                        size="small" endIcon={<CheckRoundedIcon/>} 
                                        onClick={handleUpdateOrgProfile}
                                    >
                                        Save
                                    </Button>
                                }
                            </Grid>
                        </Grid>
                    </div>
                </CustomBox>
            </Modal>
        </>
    )
}