import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { useTheme } from '@mui/material'
import { useSelector } from 'react-redux'

import { Autocomplete, Button } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Modal from '@mui/material/Modal'
import { Box } from '@mui/system'
import CircularProgress from '@mui/material/CircularProgress'

import { request } from "@/Api"
import { useNavigate } from 'react-router-dom'
import { selectHasRole } from '@/auth/authSlice'
import ApiAutocomplete from '@/common/ApiAutocomplete'

const CustomBox = styled(Box)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width:30%;
    min-width: 400px;
    padding: 24px;
`

const Title = styled.h2`
    margin: 0;
`

export default function ClientPopup({ isNew, popupOpen, setPopupOpen, clients, setClients, activeClient }){
    const theme = useTheme()
    const navigate = useNavigate()

    const [client, setClient] = useState({})
    const [errors, setErrors] = useState({})
    const [isClientPending, setIsClientPending] = useState(false)

    const isSuperAdmin = useSelector(state => selectHasRole(state, "super_admin"))

    useEffect(()=>{
        setClient({...activeClient})
        setErrors({})
    }, [activeClient])

    const handleClose = () => {
        setPopupOpen(false);
    };

    const handleInputChange = (feildName) => (event) => {
        setClient({...client, [feildName]: event.target.value})
    }

    const handleAutocompleteChange = (feildName) => (event, newValue) => {
        setClient({...client, [feildName]: newValue})    
    }

    const handleErrorResponse = (error) => {        
        if (error.response.status == 422){
            let details = error.response.data.detail
            let currentErrors = {}
            for (var i in details){
                currentErrors[details[i].loc[details[i].loc.length-1]] = details[i].msg
            }
            setErrors(currentErrors)
        } else if (error.response.status == 400){
            let detail = error.response.data.detail
            setErrors({... {"name": detail}})
        }
    }
    
    const handleCreate = (client) => () => {
        setErrors({})
        setIsClientPending(true)
        request.post("clients", client)
        .then((response)=>{
            setClients([response.data, ...clients])
            handleClose()
            navigate(`/clients/${response.data.id}`)
        })
        .catch(handleErrorResponse)
        .finally((response) => {
            setIsClientPending(false)
        })
    }

    const handleEdit = (client) => () => {
        setErrors({})
        setIsClientPending(true)
        request.put(`clients/${client.id}`, client)
        .then((response) => {
            setClients(clients.map(client => 
                client.id === response.data.id 
                ? { ...response.data } 
                : client 
            ))
            handleClose()
        })
        .catch(handleErrorResponse)
        .finally(() => {
            setIsClientPending(false)
        })
    }

    return(
        <Modal open={popupOpen} onClose={handleClose}>
            <CustomBox sx={{ background: theme.palette.background.default }}>
                <Title>{isNew ? "Create Client" : `Edit ${activeClient.name}` }</Title>
                <Grid container sx={{ paddingTop: 2 }}>
                    {isSuperAdmin ? <Grid xs={6} md={10}>
                        <InputLabel>(ADMIN) Client Folder ID</InputLabel>
                        <TextField error={"google_drive_folder_id" in errors} helperText={errors["google_drive_folder_id"]} sx={{width:"100%"}} value={client.google_drive_folder_id || ""} onChange={handleInputChange("google_drive_folder_id")}/>
                    </Grid>: null}
                    <Grid xs={6} md={10}>
                        <InputLabel>Client Name</InputLabel>
                        <TextField error={"name" in errors} helperText={errors["name"]} sx={{width:"100%"}} value={client.name || ""} onChange={handleInputChange("name")}/>
                    </Grid>
                    <Grid xs={6} md={10}>
                        <InputLabel>Currency</InputLabel>
                        <Autocomplete
                            value={client.currency || null}
                            disablePortal
                            options={["CAD", "USD", "GBP", "EUR"]}
                            renderInput={(params) => <TextField {...params} error={"currency" in errors} helperText={errors["currency"]} />}
                            onChange={handleAutocompleteChange("currency")}
                        />                    
                    </Grid>
                    <Grid xs={6} md={10}>
                        <InputLabel>EP</InputLabel>
                        <ApiAutocomplete
                            fullWidth
                            value={client.owner_id || null}
                            apiRoute={"persons"}
                            fields={["first_name", "last_name"]}
                            onChange={handleAutocompleteChange("owner_id")}
                            apiFilters={{
                                role: "executive_producer"
                            }}
                        />
                    </Grid>
                </Grid>

                <div style={{ marginTop: "24px", textAlign: "right" }}>
                    {isClientPending ? <CircularProgress/> : (
                        <>
                            <Button variant="contained" color="secondary" sx={{ marginRight: "6px" }} onClick={handleClose}>Cancel</Button>
                            {isNew ? (
                                <Button variant="contained" color="primary" onClick={handleCreate(client)}>Create</Button>
                            ) : (
                                <Button variant="contained" color="primary" onClick={handleEdit(client)}>Update</Button>
                            )}
                        </>
                    )}
                </div>
            </CustomBox>
        </Modal>
    )
}