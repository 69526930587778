import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DragHandleIcon from '@mui/icons-material/DragHandle';

import { formattedCurrency } from "@/utils";
import { currencies } from "@/constants";
import { FlexTitleCell, FlexCell, SocketDiv } from "@/common/StyledComponents";
import SheetInputField from "@/bid/SheetInputField"
import DeleteRowButton from "@/common/DeleteRowButton";

const lineItemCells = [
    {
        align: "",
        minWidth: 110,
        grow: 0,
        renderTitle: ({ key, align, minWidth, grow, readOnly, }) => {
            return readOnly ? null : (
                <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow} />
            )
        },
        renderComponent: ({ key, align, minWidth, grow, readOnly, dragHandleProps, handleCopy, handleDeleteConfirm }) => {
            return readOnly ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    <Grid container alignItems="left">
                        <Grid item>
                            <IconButton size="small" {...dragHandleProps}>
                                <DragHandleIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <IconButton size="small" onClick={handleCopy}>
                                <ContentCopyIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <DeleteRowButton iconButtonSize="small" handleDeleteConfirm={handleDeleteConfirm}/>
                        </Grid> 
                    </Grid>
                </FlexCell>
            )
        }
    },
    {
        align: "left",
        minWidth: 220,
        grow: 3,
        renderTitle: ({ key, align, minWidth, grow, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Item
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, highlights, localLineItem, handleFocus, handleBlur, handleInputChange, readOnly, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="name")}>
                    <SheetInputField
                        type="text"
                        value={localLineItem.name || ""}
                        onFocus={handleFocus("name")}
                        onBlur={handleBlur("name")}
                        onChange={handleInputChange("name")}
                        onKeyDown={(e) => {e.key!="Enter" || handleBlur("name")(e)}}
                        disabled={readOnly}
                    />
                </SocketDiv>
            </FlexCell>
        )
    },
    {
        align: "left",
        minWidth: 60,
        grow: 0,
        renderTitle: ({ key, align, minWidth, grow,  }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Currency
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, highlights, localLineItem, readOnly, theme, handleFocus, handleBlur, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="vendor_currency")}>
                    <Autocomplete
                        size="small"
                        disableClearable
                        value={localLineItem.vendor_currency || ""}
                        options={currencies}
                        renderInput={(params) => 
                            <div ref={params.InputProps.ref}>
                                <input 
                                    name="vendor_currency"
                                    {...params.inputProps} 
                                    style={{
                                        outlineWidth: 0,
                                        fontSize: 11.2,
                                        color: readOnly ? "grey" : theme.palette.text.primary,
                                        textAlign: "left",
                                        borderRadius: 6,
                                        background: "transparent",
                                        border: "0.5px grey solid",
                                        width: "100%",
                                        padding: 8,
                                        height: 30,
                                        boxSizing: "border-box",
                                    }}
                                    onMouseEnter={(e)=>{e.currentTarget.style.border = `0.5px ${theme.palette.text.primary} solid`}}
                                    onMouseLeave={(e)=>{e.currentTarget.style.border = "0.5px grey solid"}}
                                />
                            </div>      
                        }
                        onFocus={handleFocus("vendor_currency")}
                        onBlur={handleBlur("vendor_currency")}
                        onKeyDown={(e) => {e.key!="Enter" || handleBlur("vendor_currency")(e)}}
                        disabled={readOnly}
                    />
                </SocketDiv>
            </FlexCell>
        )
    },
    {
        align: "right",
        minWidth: 70,
        grow: 2,
        renderTitle: ({ key, align, minWidth, grow, rateType, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                {rateType == "Flat rate" ? "Flat rate" : "Rate"}
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, highlights, localLineItem, handleFocus, handleBlur, handleInputChange, readOnly, isMakersFees, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="vendor_rate")}>
                    <SheetInputField
                        type="number"
                        value={localLineItem.vendor_rate || ""}
                        onFocus={handleFocus("vendor_rate")}
                        onBlur={handleBlur("vendor_rate")}
                        onChange={handleInputChange("vendor_rate")}
                        onKeyDown={(e) => {e.key!="Enter" || handleBlur("vendor_rate")(e)}}
                        disabled={readOnly || (localLineItem.service_item_id && isMakersFees) ? true : false}
                        formatter={(value) => formattedCurrency(value, localLineItem.vendor_currency, true)}
                    />
                </SocketDiv>
            </FlexCell>
        )
    },
    {
        align: "right",
        minWidth: 50,
        grow: 1,
        renderTitle: ({key, align, minWidth, grow, rateType, }) => {
            return rateType == "Flat rate" ? null : ( 
                <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    {rateType == "Percentage" ? "Days" : rateType}
                </FlexTitleCell>
            )
        },
        renderComponent: ({ key, align, minWidth, grow, rateType, isTemplate, highlights, localLineItem, handleFocus, handleBlur, handleInputChange, readOnly, }) => {
            return rateType == "Flat rate" ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    {isTemplate ? <NAField /> : (
                        <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="days")} >
                            <SheetInputField
                                type="number"
                                value={localLineItem.days || ""}
                                onFocus={handleFocus("days")}
                                onBlur={handleBlur("days")}
                                onChange={handleInputChange("days")}
                                onKeyDown={(e) => {e.key!="Enter" || handleBlur("days")(e)}}
                                disabled={readOnly}
                            />
                        </SocketDiv>
                    )}
                </FlexCell>
            )
        }
    },
    {
        align: "right",
        minWidth: 60,
        grow: 1,
        renderTitle: ({ key, align, minWidth, grow, rateType, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                {rateType == "Percentage" ? "Capacity" : "Units"}
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, isTemplate, highlights, rateType, localLineItem, handleFocus, handleBlur, handleInputChange, readOnly }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                {isTemplate ? <NAField /> : (
                    <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="units")}>
                        <SheetInputField
                            type="number"
                            value={rateType == "Percentage" ? (localLineItem.units * 100).toFixed() : localLineItem.units || ""}
                            onFocus={handleFocus("units")}
                            onBlur={handleBlur("units")}
                            onChange={handleInputChange("units")}
                            onKeyDown={(e) => {e.key!="Enter" || handleBlur("units")(e)}}
                            disabled={readOnly}
                            endadornment={rateType == "Percentage" ? "%" : null}
                        />
                    </SocketDiv>
                )}
            </FlexCell>
        ),
    },
    {
        align: "right",
        minWidth: 60,
        grow: 1,
        renderTitle: ({ key, align, minWidth, grow, rateType, }) => ["Flat rate", "Percentage"].includes(rateType) ? null : (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                OT x1.5
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, rateType, isTemplate, highlights, localLineItem, handleBlur, handleFocus, handleInputChange, readOnly, }) => {
            return rateType == "Flat rate" || rateType == "Percentage" ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    {isTemplate ? <NAField /> : (
                        <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="ot_1_5")}>
                            <SheetInputField
                                type="number"
                                value={localLineItem.ot_1_5 || ""}
                                onBlur={handleBlur("ot_1_5")}
                                onFocus={handleFocus("ot_1_5")}
                                onChange={handleInputChange("ot_1_5")}
                                onKeyDown={(e) => {e.key!="Enter" || handleBlur("ot_1_5")(e)}}
                                disabled={readOnly}
                            />
                        </SocketDiv>
                    )}
                </FlexCell>
        )}
    },
    {
        align: "right",
        minWidth: 60,
        grow: 1,
        renderTitle: ({ key, align, minWidth, grow, rateType, }) => ["Flat rate", "Percentage"].includes(rateType) ? null : (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                OT x2.0
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, rateType, isTemplate, highlights, localLineItem, handleBlur, handleFocus, handleInputChange, readOnly, }) => {
            return rateType == "Flat rate" || rateType == "Percentage" ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    {isTemplate ? <NAField /> : (
                        <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="ot_2_0")}>
                            <SheetInputField
                                type="number"
                                value={localLineItem.ot_2_0 || ""}
                                onBlur={handleBlur("ot_2_0")}
                                onFocus={handleFocus("ot_2_0")}
                                onChange={handleInputChange("ot_2_0")}
                                onKeyDown={(e) => {e.key!="Enter" || handleBlur("ot_2_0")(e)}}
                                disabled={readOnly}
                            />
                        </SocketDiv>
                    )}
                </FlexCell>
            )
        },
    },
    {
        align: "right",
        minWidth: 100,
        grow: 2,
        renderTitle: ({ key, align, minWidth, grow, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Budget
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, isTemplate, localLineItem, currency, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                {isTemplate ? <NAField /> : (
                    <SheetInputField
                        type="text"
                        align="right"
                        value={formattedCurrency(localLineItem.client_budget, currency) || ""}
                        disabled
                    />
                )} 
            </FlexCell>
        ),
    },
    {
        align: "right",
        minWidth: 60,
        grow: 1,
        renderTitle: ({ key, align, minWidth, grow, canViewConfidentialData, }) => !canViewConfidentialData ? null : (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Markup
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, canViewConfidentialData, highlights, localLineItem, handleFocus, handleBlur, handleInputChange, readOnly, isMakersFees, }) => {
            const hideMarkup = isMakersFees && (!localLineItem.markup || (localLineItem.markup * 100).toFixed(0) == 0)
            return !canViewConfidentialData ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="markup")}>
                        <SheetInputField
                            type="number"
                            value={hideMarkup ? "n/a" : localLineItem.markup ? (localLineItem.markup * 100).toFixed(0) : ""}
                            onFocus={handleFocus("markup")}
                            onBlur={handleBlur("markup")}
                            onChange={handleInputChange("markup")}
                            onKeyDown={(e) => {e.key!="Enter" || handleBlur("markup")(e)}}
                            disabled={readOnly || isMakersFees}
                            endadornment={hideMarkup ? "" : "%"}
                        />
                    </SocketDiv>
                </FlexCell>
            )
        },
    },
    {
        align: "right",
        minWidth: 70,
        grow: 2,
        renderTitle: ({ key, align, minWidth, grow, canViewConfidentialData, }) => !canViewConfidentialData ? null : (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Adjustments
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, canViewConfidentialData, highlights, isTemplate, localLineItem, handleBlur, handleFocus, handleInputChange, readOnly, currency }) => {
            return !canViewConfidentialData ? null : (
                <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                    <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="adjustment")}>
                        {isTemplate ? <NAField /> : (
                            <SheetInputField
                                type="number"
                                value={localLineItem.adjustment || ""}
                                onFocus={handleFocus("adjustment")}
                                onBlur={handleBlur("adjustment")}
                                onChange={handleInputChange("adjustment")}
                                onKeyDown={(e) => {e.key!="Enter" || handleBlur("adjustment")(e)}}
                                disabled={readOnly}
                                formatter={(value) => formattedCurrency(value, currency, false)}
                            />
                        )}
                    </SocketDiv>
                </FlexCell>
            )
        }
    },
    {
        align: "left",
        minWidth: 300,
        grow: 3,
        renderTitle: ({ key, align, minWidth, grow, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Notes
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, highlights, localLineItem, handleBlur, handleFocus, handleInputChange, readOnly, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                <SocketDiv highlights={highlights.filter(highlight=>highlight.field=="external_notes")} >
                    <SheetInputField
                        type="text"
                        value={localLineItem.external_notes || ""}
                        onFocus={handleFocus("external_notes")}
                        onBlur={handleBlur("external_notes")}
                        onChange={handleInputChange("external_notes")}
                        onKeyDown={(e) => {e.key!="Enter" || handleBlur("external_notes")(e)}}
                        align="left"
                        disabled={readOnly}
                    />
                </SocketDiv>
            </FlexCell>
        ),
    },
    {
        align: "right",
        minWidth: 90,
        grow: 2,
        renderTitle: ({ key, align, minWidth, grow, }) => (
            <FlexTitleCell key={key} align={align} minWidth={minWidth} grow={grow}>
                Total
            </FlexTitleCell>
        ),
        renderComponent: ({ key, align, minWidth, grow, isTemplate, localLineItem, currency, }) => (
            <FlexCell key={key} align={align} minWidth={minWidth} grow={grow}>
                {isTemplate ? <NAField /> : (
                    <SheetInputField
                        type="text"
                        align="right"
                        value={formattedCurrency(localLineItem.client_quoted, currency) || ""}
                        disabled
                    />
                )}
            </FlexCell>
        ),
    },
]

const NAField = () => {
    return (
        <SheetInputField 
            align="right"
            disabled
            value={"-"}
        />
    )
}

export default lineItemCells
