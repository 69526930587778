import styled from "styled-components"
import { Avatar, useTheme } from "@mui/material"
import { parseNameToInitials } from "@/utils"



export default function ProfileAvatar({image, name, size, darkModeInvert}){
    const theme = useTheme()
    const imageUrl = image ? (image.processed ? image.url_icon : image.url_orig) : null
    return (
        <Avatar 
            imgProps={{style: {height: "125%", filter: `invert(${theme.palette.mode=="dark" && darkModeInvert ? 1 : 0})`}}} 
            src={imageUrl} 
            sx={{
                width: size, height: size, 
                fontSize: size / 2, border: `1px ${theme.palette.text.primary} solid`
            }}
        >
            {name ? parseNameToInitials(name) : null}
        </Avatar>
    )
}