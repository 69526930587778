import { useDispatch } from "react-redux"
import IconButton from "@mui/material/IconButton"
import MenuIcon from '@mui/icons-material/Menu'
import { setNavOpen } from "@/nav/navSlice"

export default function MobileNavButton () {
    const dispatch = useDispatch()
    const handleOpen = () => {
        localStorage.setItem("navOpen", true)
        dispatch(setNavOpen(true))
    }
    return (
        <IconButton onClick={handleOpen}>
            <MenuIcon />
        </IconButton>
    )
}
