import { styled } from '@mui/material/styles';

export const ProfileStyle = styled('div')(({ theme }) => ({
    "span, a, p": {
        [theme.breakpoints.down('md')]: {
            fontSize: "13px"
        }
    },
    "h1": {
        [theme.breakpoints.down('md')]: {
            fontSize: "22px"
        },
    }
}));