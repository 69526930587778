import { Subtitle, Title } from "@/common/StyledComponents"
import { useState } from "react"
import { format } from "date-fns"
import { Autocomplete, Modal, TextField, Button, CircularProgress, Grid } from "@mui/material"
import { CustomBox } from "@/common/StyledComponents"
import { useTheme } from "@mui/material"
import NotificationsRoundedIcon from '@mui/icons-material/NotificationsRounded';
import { baseTheme } from '@/themes'
import styled from "styled-components"
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { getDeltaDays } from "@/utils";
import { milestoneReminderOptions } from "../project/constants"
import { request } from "@/Api"

const Icon = styled.div`
    display: flex;
    padding: 6px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor}; 
    margin-right: 12px;
`

export default function MilestonePopup({projectId, popupOpen, setPopupOpen, milestone, setMilestone, milestones, setMilestones}){
    const theme = useTheme()
    const [ isCreating, setIsCreating ] = useState(false)
    const [ errors, setErrors ] = useState({})
    const deltaDays = getDeltaDays(milestone.remind_date, milestone.complete_date)

    const handlePopupClose = () => {
        setMilestone({})
        setPopupOpen(false)
    } 

    const handleInput = (field) => (event) => {
        setErrors({...errors, [field]: null})
        setMilestone({...milestone, [field]: event.target.value})
    }

    const handleDateChange = (val) => {
        setErrors({...errors, complete_date: null})
        const updatedMilestone = {
            ...milestone, 
            complete_date: val, 
        }
        if (!val){
            updatedMilestone.remind_date = null
        }
        else if (milestone.remindObj){
            const reminderDate = calculateReminderDate(val, milestone.remindObj.delta)
            updatedMilestone.remind_date = reminderDate
        }
        else if (milestone.remind_date){
            const reminderDate = calculateReminderDate(val, deltaDays)
            updatedMilestone.remind_date = reminderDate
        }
        setMilestone(updatedMilestone)
    }

    const handleReminderChange = (event, val) => {
        const reminderDate = val ? calculateReminderDate(milestone.complete_date, val.delta) : null
        setMilestone({
            ...milestone, 
            remindObj: val, 
            remind_date: reminderDate
        })
    }

    const calculateReminderDate = (completeDate, delta) => {
        const reminderDate = new Date(completeDate)
        reminderDate.setDate(reminderDate.getDate() - delta)
        return reminderDate
    }

    const handle422 = (error) => {
        setIsCreating(false)
        if (error.response.status == 422){
            const details = error.response.data.detail
            setErrors(Object.fromEntries(details.map(detail => [
                detail.loc[detail.loc.length-1], detail.msg 
            ])))
        }  
    }

    const createMilestone = () => {
        if (isNaN(Date.parse(milestone.complete_date))){
            setErrors({...errors, complete_date: "date format is invalid"})
            return
        }
        setIsCreating(true)
        request.post("milestone", {
            ...milestone,
            project_id: projectId,
            complete_date: milestone.complete_date ? format(milestone.complete_date, "y-MM-dd") : null,
            remind_date: milestone.remind_date ? format(milestone.remind_date, "y-MM-dd") : null
        })
        .then((response)=>{
            let newMilestones = []
            if (milestones.length == 0){
                newMilestones = [response.data]
            }
            for (var i in milestones){
                if (milestones[i].complete_date >= response.data.complete_date){
                    newMilestones = [...milestones.slice(0, i), response.data, ...milestones.slice(i)]
                    break
                }
                if (i == milestones.length - 1){
                    newMilestones = [...milestones, response.data]
                }
            }
            setMilestones(newMilestones)
            handlePopupClose()
        })
        .catch((error)=>{
            setIsCreating(false)
            if (error.response.status == 422){
                handle422(error)
            }else{
                throw error
            }
        })
        .finally(()=>{
            setIsCreating(false)
        })
    } 

    const updateMilestone = () => {
        if (isNaN(Date.parse(milestone.complete_date))){
            setErrors({...errors, complete_date: "date format is invalid"})
            return
        }
        setIsCreating(true)
        request.put(`milestone/${milestone.id}`, {
            ...milestone,
            complete_date: milestone.complete_date ? format(milestone.complete_date, "y-MM-dd") : null,
            remind_date: milestone.remind_date ? format(milestone.remind_date, "y-MM-dd") : null
        })
        .then((response)=>{
            const newMilestones = milestones.map(milestone=>{return milestone.id == response.data.id ? response.data : milestone})
            newMilestones.sort((a,b) => (a.complete_date > b.complete_date) ? 1 : ((b.complete_date > a.complete_date) ? -1 : 0))
            setMilestones(newMilestones)
            handlePopupClose()
        })
        .catch((error)=>{
            setIsCreating(false)
            if (error.response.status == 422){
                handle422(error)
            }else{
                throw error
            }
        })
        .finally(()=>{
            setIsCreating(false)
        })
    }

    const deleteMilestone = () => {
        request.delete(`milestone/${milestone.id}`)
        .then((response)=>{
            handlePopupClose()
            setMilestones(milestones.filter(milestone=>
                milestone.id != response.data.id
            ))
        })
    }

    return (
        <Modal open={popupOpen} onClose={handlePopupClose}>
            <CustomBox sx={{ background: theme.palette.background.default }}>
                <Title borderColor={theme.palette.background.paper}>
                    <Icon backgroundColor={baseTheme.palette.primary.light}>
                        <NotificationsRoundedIcon fontSize="small" sx={{color: baseTheme.palette.text.primary}} />
                    </Icon>
                    {`${milestone.id ? "Update" : "Create new"} milestone`}
                </Title>

                <Subtitle style={{paddingTop: 10, paddingBottom: 10}}>
                    Name
                </Subtitle>
                <TextField 
                    fullWidth
                    value={milestone.name || ""}
                    onChange={handleInput("name")}
                    error={errors.name ? true : false}
                    helperText={errors.name}
                />

                <Subtitle style={{paddingTop: 10, paddingBottom: 10}}>
                    Description
                </Subtitle>
                <TextField 
                    fullWidth
                    value={milestone.description || ""}
                    onChange={handleInput("description")}
                    error={errors.description ? true : false}
                    helperText={errors.description}
                />
                <Subtitle style={{paddingTop: 10, paddingBottom: 10}}>
                    Completion Date
                </Subtitle>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        onChange={handleDateChange}
                        value={milestone.complete_date || null}
                        inputFormat={"yyyy-MM-dd"}
                        renderInput={(params) => (
                            <TextField
                                fullWidth
                                {...params}
                                error={errors.complete_date ? true : false}
                                helperText={errors.complete_date}
                            />
                        )}
                    />
                </LocalizationProvider>
                <Subtitle style={{paddingTop: 10, paddingBottom: 10}}>
                    {`Reminder Date ${milestone.remind_date && milestone.complete_date ? `(${milestone.remind_date.toDateString()})` : ""}`}
                </Subtitle>
                <Autocomplete 
                    sx={{paddingBottom: 3}}
                    onChange={handleReminderChange}
                    value={milestoneReminderOptions.find(option=>option.delta == deltaDays) || null}
                    options={milestoneReminderOptions}
                    renderInput={(params) => (
                        <TextField
                            fullWidth
                            {...params}
                        />
                    )}
                    getOptionLabel={(option)=>option.label || ""}
                    isOptionEqualToValue={(option, value)=>option.label == value.label}
                />

                <Grid spacing={1} justifyContent={"right"} container style={{width: "100%", textAlign: "right"}}>
                    {milestone.id ?      
                        <Grid item>
                            <Button variant="contained" color="error" onClick={deleteMilestone}>
                                Delete
                            </Button>
                        </Grid> : null
                    }
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handlePopupClose}>
                            Cancel
                        </Button>
                    </Grid>
                    <Grid item>
                        {isCreating ? 
                            <CircularProgress /> :
                            <Button variant="contained" color="primary" onClick={milestone.id ? updateMilestone : createMilestone}>
                                {`${milestone.id ? "Update" : "Create"}`}
                            </Button>
                        }
                    </Grid>
                </Grid>

            </CustomBox>
        </Modal>
    )
}