import Grid from "@mui/material/Grid";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";

export default function BidLoading() {
    return (
        <div>
            <Grid container spacing={6} paddingTop="76px">
                <Grid md={4} sm={12} item><Skeleton variant="rounded" height={205} /></Grid>
                <Grid md={4} sm={12} item><Skeleton variant="rounded" height={205} /></Grid>
                <Grid md={4} sm={12} item><Skeleton variant="rounded" height={205} /></Grid>
            </Grid>

            <Divider sx={{marginTop: 6, marginBottom: 6}}/>

            <Grid container spacing={2} >
                <Grid item sm={12}><Skeleton variant="rounded" height={100} /></Grid>
                <Grid item sm={12}><Skeleton variant="rounded" height={100} /></Grid>
                <Grid item sm={12}><Skeleton variant="rounded" height={100} /></Grid>
            </Grid>
        </div>
    )
}