import { Avatar, useTheme, Link } from "@mui/material";
import { parseNameToInitials } from "@/utils";

export default function PersonProfileAvatar({person_profile, size}){
    if (!person_profile){
        return null
    }
    const theme = useTheme()
    const image = person_profile.image
    const imageUrl = image ? (image.processed ? image.url_icon : image.url_orig) : null
    return (
        <Link href={`/profile/${person_profile.handle}`} sx={{textDecoration: "none"}}>
            <Avatar
                imgProps={{style: {height: "125%"}}}
                src={imageUrl}
                sx={{
                    width: size, height: size,
                    fontSize: size / 2, border: `1px ${theme.palette.text.primary} solid`
                }}
            >
                {parseNameToInitials(person_profile.name)}
            </Avatar>
        </Link>
    )
}