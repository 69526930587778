import { useState, useEffect } from "react"

import TableCell from "@mui/material/TableCell"
import EditRoundedIcon from '@mui/icons-material/EditRounded'
import Tooltip from "@mui/material/Tooltip"
import IconButton from "@mui/material/IconButton"
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

import { request } from "@/Api"

import CustomTable, { CustomRow } from "@/table/CustomTable"
import ClientPopup from "@/clients/ClientPopup"
import EntityCreateButton from "@/common/EntityCreateButton"
import { Heading } from "@/common/StyledComponents"
import { FLOAT_BASE_URL, FORECAST_BASE_URL } from "@/config"

export default function Clients() {
    const [clients, setClients] = useState([])
    const [activeClient, setActiveClient] = useState({})
    const [popupOpen, setPopupOpen] = useState(false)
    const [order, setOrder] = useState("+name")

    const getClients = () => {
        request.get("clients")
        .then((response)=>{
            setClients(response.data)
        })
    }

    useEffect(getClients, [])
    return(
        <>
            <Heading style={{marginBottom: 30, paddingTop: 12}}>Clients</Heading>
            <CustomTable
                headCells={[
                    {id: "name", label: "Client Name"}, 
                    {id: "currency", label: "Currency"}, 
                    {id: "_edit", label: "Edit"}, 
                ]}
                setOrder={setOrder}
                order={order.substring(1)}
                sign={order.substring(0,1)}
                rows={clients}
                rowComponent={(client) => (
                    <CustomRow>
                        <TableCell component="th" scope="row">{client.name}</TableCell>
                        <TableCell align="left">{client.currency}</TableCell>
                        <TableCell align="left">
                            <Tooltip title="Client details">
                                <IconButton href={`/clients/${client.id}`}>
                                    <EditRoundedIcon />
                                </IconButton>
                            </Tooltip>
                            {client.google_drive_folder_id ?
                            <Tooltip title="Client folder">
                                <IconButton onClick={()=>window.open("https://drive.google.com/drive/folders/"+client.google_drive_folder_id, "_blank")} aria-label="client-folder">
                                    <FolderOpenIcon />
                                </IconButton>
                            </Tooltip> : null}
                            {client.forecast_id || client.float_id ?
                            <Tooltip title={`Open in ${client.float_id ? "Float" : "Forecast"}`}>
                                <IconButton onClick={()=>
                                    window.open(
                                        client.float_id ? 
                                            `${FLOAT_BASE_URL}/?client=${encodeURIComponent(client.name)}` : 
                                            `${FORECAST_BASE_URL}/clients/${client.forecast_id}/edit/`, 
                                        "_blank"
                                    )
                                } aria-label="client-folder">
                                    <CalendarMonthIcon />
                                </IconButton>
                            </Tooltip> : null}
                        </TableCell>
                    </CustomRow>
                )}
            />
            
            <ClientPopup 
                isNew={activeClient.id==undefined} 
                popupOpen={popupOpen} 
                setPopupOpen={setPopupOpen} 
                clients={clients} 
                setClients={setClients} 
                activeClient={activeClient}
            />
            <EntityCreateButton setPopupOpen={()=>{setPopupOpen(true); setActiveClient({})}} />
        </>
    )
}
