import { useEffect, useState } from "react";

import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Alert from "@mui/material/Alert";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';

export default function InlineRename(props) {
    const { defaultName, close, rename } = props
    const [ newName, setNewName ] = useState('')
    const [ newNameError, setNewNameError ] = useState('')

    const handleNameChange = (event) => {
        setNewNameError('')
        setNewName(event.target.value)
    }
    const handleRename = (event) => {
        if (event) {
            event.stopPropagation()
        }

        if (newName == '') {
            setNewNameError("Please enter a valid name")
            return
        }

        rename(newName)
    }

    useEffect(() => {
        setNewName(defaultName)
    }, [defaultName])

    return (
        <Grid container alignItems="center" onClick={(e => { e.stopPropagation() })}>
            <Grid item>
                <TextField value={newName} onChange={handleNameChange} onKeyDown={(e) => {e.key!="Enter" || handleRename()}} autoFocus />
            </Grid>
            {newNameError ? (
                <Grid item>
                    <Alert severity="error">{newNameError}</Alert>
                </Grid>
            ) : (
                <Grid item>
                    <IconButton onClick={handleRename}>
                        <CheckCircleIcon />
                    </IconButton>
                    <IconButton onClick={close}>
                        <CancelIcon />
                    </IconButton>
                </Grid>
            )}
        </Grid>
    )
}
