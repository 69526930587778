import { useTheme } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import Grid from '@mui/material/Unstable_Grid2';

import { Title } from "@/common/StyledComponents";

export default function ProjectChecklist(props) {
    const { title, descriptions } = props
    const theme = useTheme()

    return(
        <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
                <Title bold>{title}</Title>
            </Grid>

            {descriptions.map(((description, index)=>{
                return (
                    <Grid container key={index} spacing={2} xs={12} md={4} alignItems={"center"} >
                        <Grid>
                            <CheckIcon sx={{
                                borderRadius: "50%",
                                padding: 1,
                                color: theme.palette.text.secondary,
                                border: `solid 1px ${theme.palette.text.secondary}`
                            }} />
                        </Grid>
                        <Grid xs={9} md={9}><span>{ description }</span></Grid>
                    </Grid>
                )
            }))}
        </Grid>
    )
}
