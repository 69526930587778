import { useState } from "react";
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete';
import { Grid, Button } from "@mui/material";

export default function DeleteRowButton(props){
    // TODO: add progress circle
    const { handleDeleteConfirm, iconButtonSize, iconSize } = props
    const [isConfirmingDelete, setIsConfirmingDelete] = useState(false)

    return (
        <>{
            isConfirmingDelete ?
            <Grid container width={150}>
                <Grid container justifyContent={"center"}>Delete for sure?</Grid>
                <Grid item justifyContent={"center"}>
                    <Button onClick={()=>{setIsConfirmingDelete(false)}} sx={{padding: "0px"}}>No</Button>
                    <Button onClick={()=>{setIsConfirmingDelete(false); handleDeleteConfirm()}} sx={{padding: "0px"}}>Yes</Button>
                </Grid>
            </Grid>
            :
            <IconButton size={iconButtonSize} onClick={()=>{setIsConfirmingDelete(true)}}>
                <DeleteIcon fontSize={iconSize}/>
            </IconButton>
        }</>
    )
}