import { useState } from "react"

import { Button, Grid, CircularProgress, Card, CardActions, CardContent, Alert } from "@mui/material"

import { request } from "@/Api"

export default function SyncButton({ name, route, caution, notes }) {
    const [success, setSuccess] = useState(null)
    const [error, setError] = useState(null)
    const [isSyncing, setIsSyncing] = useState(false)

    const sync = (endpoint) => {
        setSuccess(null)
        setError(null)
        setIsSyncing(true)

        request.get(endpoint)
        .then((response)=>{
            setSuccess(response.data || "Success")
        })
        .catch((error)=>{
            setError(error.response.data.detail)
        })
        .finally(() => {
            setIsSyncing(false)
        })
    }

    return (
        <Grid item md={3} xs={6}>
            <Card>
                <CardContent>
                    <h3 style={{marginTop: "0"}}>{ name }</h3>
                    <p>{ notes }</p>
                </CardContent>
                <CardActions>
                    {isSyncing ? <CircularProgress/> : (
                        <Button color={caution ? "warning" : "primary"} variant="contained" onClick={()=>{sync(route)}}>
                            Run
                        </Button>
                    )}
                    { success && (
                        <Alert severity="success">
                            {success}
                        </Alert>
                    )}
                    { error && (
                        <Alert severity="error">
                            {error}
                        </Alert>
                    )}
                </CardActions>
            </Card>
        </Grid>
    )
}
