import { useDispatch } from 'react-redux'
import { Chip } from '@mui/material'
import { openBidPopup } from '@/nav/navSlice'

export default function ProjectBidCreate({ project }) {
    const dispatch = useDispatch()
    const popupType = project?.confirmed_bid_id ? "Change Order" : "Bid Sheet"
    const label = project.legacy_bid ? `Switch to Integrated` : `Create ${popupType}`

    const handlePopupOpen = () => {
        dispatch(openBidPopup({
            type: popupType,
            project: project
        }))
    }

    return(
        <div>
            <Chip
                variant='outlined'
                clickable
                onClick={handlePopupOpen}
                label={label}
            />
        </div>
    )
}
