import { request } from "@/Api"
import { CustomBox } from "@/common/StyledComponents"
import { Button, Checkbox, Chip, Grid, IconButton, Modal, TextField, ToggleButton, Tooltip, useTheme } from "@mui/material"
import { useEffect, useState } from "react"

import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import EditRoundedIcon from '@mui/icons-material/EditRounded';

export default function ApiKeys(){
    const [scopePopupOpen, setScopePopupOpen] = useState(false)
    const [apiKeys, setApiKeys] = useState([])
    const [apiKey, setApiKey] = useState(null)

    const getApiKeys = () => {
        request.get("api-key")
        .then(response=>{
            setApiKeys(response.data)
        })
    }

    useEffect(()=>{
        getApiKeys()
    }, [])

    const closePopup = () => {
        setScopePopupOpen(false)
    }

    const openPopupCreate = () => {
        setScopePopupOpen(true)
        setApiKey(null)
    }

    const openPopupEdit = (apiKey) => () => {
        setScopePopupOpen(true)
        setApiKey(apiKey)
    }

    const copyApiKey = (apiKey) => () => {
        console.log(apiKey.key)
        navigator.clipboard.writeText(apiKey.key);
    }

    return (
        <>
            {apiKeys.map((apiKey, index)=>
                <Grid key={index} container justifyContent={"space-between"} alignItems={"center"} wrap="nowrap" spacing={1} sx={index == apiKeys.length - 1 ? {} : {borderBottom: "1px solid grey"}}>
                    <Grid item md={4}>
                        <Grid container alignItems={"center"} spacing={2}>
                            <Grid item>
                                <IconButton size="small" onClick={copyApiKey(apiKey)}>
                                    <ContentCopyRoundedIcon />
                                </IconButton>
                                <IconButton size="small" onClick={openPopupEdit(apiKey)}>
                                    <EditRoundedIcon />
                                </IconButton>
                            </Grid>
                            <Grid item>
                                <div style={{overflow: "hidden", textOverflow: "ellipsis", width: 200}}>
                                    {apiKey.name}
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Tooltip title={apiKey.permissions.map((permission=>permission.name)).join(", ")} placement="top-end">
                        <Grid textAlign={"right"} item md={8} sx={{textWrap: "nowrap"}} textOverflow={"ellipsis"} overflow={"hidden"}>
                            {apiKey.permissions.map((permission=>permission.name)).join(", ")}
                        </Grid>
                    </Tooltip>
                </Grid>
            )}

            <Button variant="contained" onClick={openPopupCreate} sx={{marginTop: "24px"}}>Add</Button>

            <ApiKeyScopesPopup 
                apiKeyData={apiKey} 
                getApiKeys={getApiKeys}
                open={scopePopupOpen}
                closePopup={closePopup}
            />            
        </>
    )
}

const ApiKeyScopesPopup = ({apiKeyData, getApiKeys, open, closePopup}) => {
    const [permissions, setPermissions] = useState([])
    const [apiKey, setApiKey] = useState({})
    const theme = useTheme()
    const apiKeyPermissionIds = apiKey.permissions ? apiKey.permissions.map(permission=>permission.id) : []

    useEffect(()=>{
        getPermissions()
    }, [])

    useEffect(()=>{
        if (apiKeyData){
            setApiKey(apiKeyData)
        } else {
            setApiKey({
                permissions: []
            })
        }
    }, [apiKeyData])

    const getPermissions = () => {
        request.get("permissions")
        .then(response=>{
            setPermissions(response.data)
        })
    }

    const addApiKey = () => {
        request.post("api-key", {name: apiKey.name, permission_ids: apiKeyPermissionIds})
        .then(_=>{
            getApiKeys()
            closePopup()
        })
    }

    const updateApiKey = (apiKeyId) => {
        request.put(`api-key/${apiKeyId}`, {name: apiKey.name, permission_ids: apiKeyPermissionIds})
        .then(_=>{
            getApiKeys()
            closePopup()
        })
    }

    const onToggle = (permission) => () => {
        const checked = apiKeyPermissionIds.includes(permission.id)
        if (!checked){
            setApiKey({...apiKey, permissions: [...apiKey.permissions, permission]})
        } else {
            setApiKey({...apiKey, permissions: apiKey.permissions.filter(p=>p.id!=permission.id)})
        }
    }

    const handleInputName = (event) => {
        setApiKey({...apiKey, "name": event.target.value})
    }

    const handleScopeConfirm = () => {
        if (apiKey.id) {
            updateApiKey(apiKey.id)
        } else {
            addApiKey()
        }
    }

    return (
        <Modal open={open} onClose={closePopup}>
            <CustomBox sx={{background: theme.palette.background.default, width: "80%"}}>
                <h3 style={{marginTop: 0}}>Name your key</h3>
                <TextField 
                    fullWidth
                    size="small"
                    onInput={handleInputName}
                    value={apiKey.name || ""}
                />

                <h3>Choose scopes</h3>
                <div style={{overflow: "scroll", height: 350}}>
                <Grid container justifyContent={"center"} alignItems={"center"} spacing={1}>
                    {permissions.map((permission, index)=>
                        <Grid key={index} item>
                            <ToggleButton
                                color="success"
                                sx={{width: 130, height: 60, fontSize: 12}}
                                value="check"
                                selected={apiKeyPermissionIds.includes(permission.id)}
                                onChange={onToggle(permission)}
                            >
                                <div style={{textWrap: "pretty", wordBreak: "break-word"}}>{permission.name.replace(":", " : ")}</div>
                            </ToggleButton>
                        </Grid>
                    )}
                </Grid>
                </div>
                <div style={{textAlign: "right", background: theme.palette.background.default, margin: 0, padding: 20}}>
                    <Button variant="contained" onClick={handleScopeConfirm}>{apiKey.id ? "Update" : "Create"}</Button>
                </div>
            </CustomBox>
        </Modal>
    )
}