import { useState } from 'react'
import styled from 'styled-components'
import { parse, format } from "date-fns"
import { NumericFormat } from "react-number-format"

import { useTheme, styled as muiStyled } from '@mui/material/styles'
import Autocomplete from '@mui/material/Autocomplete'
import Grid from '@mui/material/Unstable_Grid2'
import TextField from '@mui/material/TextField'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'

import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import PieChartOutlinedIcon from '@mui/icons-material/PieChartOutlined'
import AddCircleOutlinedIcon from '@mui/icons-material/AddCircleOutlined'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { baseTheme } from '@/themes'
import { billingSchedules, forecastCertainty, CUSTOM_BILLING_SCHEDULE } from '@/project/constants'
import { Title } from '@/common/StyledComponents'
import { request } from "@/Api"

const Icon = styled.div`
    display: flex;
    padding: 6px;
    border-radius: 5px;
    background-color: ${props => props.backgroundColor}; 
    margin-right: 12px;
`

const InputLabel = styled.div`
    padding-bottom: 4px;
    font-size: 12px;
    color: ${props => props.color};
`

const ForecastAccordion = muiStyled(({extraMargin, ...props}) => (
    <Accordion {...props} />
))(({ theme, extraMargin }) => `
    background-color: ${theme.palette.background.paper};
    border-radius: 10px;
    padding: 16px 20px;
    margin: ${extraMargin ? "48px auto" : "24px auto"};
    box-sizing: border-box;
    box-shadow: none;

    &:before {
        display: none;
    }
    &.Mui-expanded {
        margin: ${extraMargin ? "48px auto" : "24px auto"};
    }
    & .MuiAccordionSummary-root {
        min-height: auto;
    }
    & .MuiAccordionSummary-content {
        margin: 0;
    }
    & .MuiAccordionDetails-root {
        padding-top: 16px;
    }
`)
const Bills = styled.div`
    margin-top: 24px;
`

const ProjectForecast = ({ project_id, forecast, setForecast, currency, errors, setErrors, cacheForecastField, saveForecastField, saveForecast, createMode, showSaveAlert, defaultExpanded, readOnly }) => {
    const theme = useTheme()
    const [ cachedValue, setCachedValue ] = useState(null)

    const saveForecastBill = (index, field, value) => {
        if (createMode || readOnly) {
            return
        }

        if (cachedValue == value) {
            setCachedValue(null)
            return
        }

        const bill = forecast.forecast_bills[index]

        request.put(`forecast-bills/${bill.id}`, bill).then(() => {
            showSaveAlert()
        }).catch(error => {
            const detail = error.response.data.detail[0]
            setErrors({
                ...errors,
                forecast_bills: {
                    ...errors.forecast_bills,
                    [index]: {
                        ...errors.forecast_bills[index],
                        [detail.loc[1]]: detail.msg,
                    }
                }
            })
        })
    }

    const handleRevenueChange = (values) => {
        setForecast({
            ...forecast,
            forecast_revenue: values.value,
            forecast_bills: forecast.forecast_bills.map(bill => ({...bill, revenue: values.floatValue * bill.percentage})),
        })

        if (values.value && errors.forecast_revenue) {
            const { forecast_revenue, ...remainingErrors } = errors
            setErrors(remainingErrors)
        }
    }

    const handleScheduleChange = (event, forecast_schedule) => {
        const forecast_bills = []
        
        if (forecast_schedule) {
            var today = new Date()
            var date = format(new Date(today.getFullYear(), today.getMonth(), 1), "y-MM-dd")

            const percentages = forecast_schedule == CUSTOM_BILLING_SCHEDULE ? [0] : forecast_schedule.replace("%", "").split(" / ")
            for (var i in percentages){
                const percentage = parseFloat(percentages[i]) / 100
                forecast_bills.push({
                    "percentage": percentage,
                    "revenue": percentage * forecast.forecast_revenue,
                    "date": date,
                })
            }

            if (errors.forecast_schedule) {
                const { forecast_schedule, ...remainingErrors } = errors
                setErrors(remainingErrors)
            }
        }
        
        setForecast({...forecast, forecast_schedule, forecast_bills})
        saveForecast({forecast_schedule, forecast_bills})
    }

    const handleCertaintyChange = (event, forecast_certainty) => {
        if (forecast_certainty && errors.forecast_certainty) {
            const { forecast_certainty, ...remainingErrors } = errors
            setErrors(remainingErrors)
        }

        setForecast({...forecast, forecast_certainty})
        saveForecast({forecast_certainty})
    }

    const handlePercentageChange = (billIndex) => (values) => {
        setForecast({
            ...forecast,
            forecast_bills: [
                ...forecast.forecast_bills.slice(0, billIndex),
                { ...forecast.forecast_bills[billIndex], percentage: values.value, revenue: forecast.forecast_revenue * values.floatValue },
                ...forecast.forecast_bills.slice(billIndex+1),
            ],
        })

        if (values.value && errors.forecast_bills[billIndex] && errors.forecast_bills[billIndex].percentage) {
            const { percentage, ...remaingBillErrors } = errors.forecast_bills[billIndex]
            if (Object.keys(remaingBillErrors).length) {
                setErrors({
                    ...errors,
                    forecast_bills: {
                        ...errors.forecast_bills,
                        [billIndex]: remaingBillErrors,
                    },
                })
            } else {
                const { [billIndex]: _, ...remainingErrors} = errors.forecast_bills
                setErrors({
                    ...errors,
                    forecast_bills: {
                        ...remainingErrors
                    },
                })
            }
        }
    }

    const handleCalendarChange = (billIndex, save) => (newValue) => {
        let date = ''
        try {
            date = format(newValue, "y-MM-dd")
        } catch (e) {
            // Keep empty string if invalid date
        }

        setForecast({
            ...forecast,
            forecast_bills: [
                ...forecast.forecast_bills.slice(0, billIndex),
                { ...forecast.forecast_bills[billIndex], date },
                ...forecast.forecast_bills.slice(billIndex+1),
            ],
        })

        if (date && errors.forecast_bills[billIndex] && errors.forecast_bills[billIndex].date) {
            const { date, ...remaingBillErrors } = errors.forecast_bills[billIndex]
            if (Object.keys(remaingBillErrors).length) {
                setErrors({
                    ...errors,
                    forecast_bills: {
                        ...errors.forecast_bills,
                        [billIndex]: remaingBillErrors,
                    },
                })
            } else {
                const { [billIndex]: _, ...remainingErrors} = errors.forecast_bills
                setErrors({
                    ...errors,
                    forecast_bills: {
                        ...remainingErrors
                    },
                })
            }
        }

        if (save) {
            saveForecastBill(billIndex, "date", date)
        }
    }

    const handleAddBill = (e) => {
        var today = new Date()
        var date = format(new Date(today.getFullYear(), today.getMonth(), 1), "y-MM-dd")

        if (createMode) {
            setForecast({
                ...forecast,
                forecast_bills: [
                    ...forecast.forecast_bills,
                    {
                        "percentage": 0,
                        "revenue": 0,
                        "date": date,
                    }
                ],
            })
        } else {
            request.post(`forecast-bills`, {project_id}).then(response => {
                setForecast({
                    ...forecast,
                    forecast_bills: [
                        ...forecast.forecast_bills,
                        {
                            "id": response.data.id,
                            "percentage": 0,
                            "revenue": 0,
                            "date": date,
                        }
                    ],
                })

                showSaveAlert()
            })            
        }
    }

    const handleDeleteBill = (index) => () => {
        const billId = forecast.forecast_bills[index].id

        setForecast({
            ...forecast,
            forecast_bills: [
                ...forecast.forecast_bills.slice(0, index),
                ...forecast.forecast_bills.slice(index+1),
            ],
        })

        if (!createMode) {
            request.delete(`forecast-bills/${billId}`).then(() => showSaveAlert())
        }
    }

    return (
        <ForecastAccordion defaultExpanded={defaultExpanded} extraMargin={!createMode}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Title bold={!createMode} noSpacing>
                    <Icon backgroundColor={baseTheme.palette.primary.light}>
                        <PieChartOutlinedIcon fontSize="small" sx={{color: baseTheme.palette.text.primary}} />
                    </Icon>
                    Forecast
                </Title>
            </AccordionSummary>

            <AccordionDetails>
                <Grid container spacing={3}>
                    <Grid item xs={12} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Ballpark Budget</InputLabel>
                        <NumericFormat
                            customInput={TextField}
                            value={forecast.forecast_revenue}
                            sx={{width:"100%"}}
                            inputProps={{
                                style: {textAlign: "right"},
                                readOnly: readOnly,
                            }}
                            onValueChange={handleRevenueChange}
                            onFocus={cacheForecastField("forecast_revenue")}
                            onBlur={saveForecastField("forecast_revenue")}
                            prefix={currency}
                            thousandsGroupStyle="thousand"
                            thousandSeparator=","
                            error={Boolean(errors["forecast_revenue"])}
                            helperText={errors["forecast_revenue"]}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Projected Billing Schedule</InputLabel>
                        <Autocomplete
                            value={forecast.forecast_schedule}
                            disablePortal
                            options={billingSchedules}
                            renderInput={(params) => <TextField {...params} error={Boolean(errors["forecast_schedule"])} helperText={errors["forecast_schedule"]} />}
                            onChange={handleScheduleChange}
                            readOnly={readOnly}
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <InputLabel color={theme.palette.text.secondary}>Certainty</InputLabel>
                        <Autocomplete
                            value={forecast.forecast_certainty}
                            disablePortal
                            options={forecastCertainty}
                            renderInput={(params) => <TextField {...params} error={Boolean(errors["forecast_certainty"])} helperText={errors["forecast_certainty"]} />}
                            onChange={handleCertaintyChange}
                            readOnly={readOnly}
                        />
                    </Grid>
                </Grid>

                {forecast.forecast_bills.length > 0 || forecast.forecast_schedule == CUSTOM_BILLING_SCHEDULE ? (
                    <Bills>
                        <Grid container spacing={3}>
                            <Grid item xs={4}>
                                <InputLabel color={theme.palette.text.secondary}>Bill Percentage</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel color={theme.palette.text.secondary}>Projected Billing</InputLabel>
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel color={theme.palette.text.secondary}>Bill Month</InputLabel>
                            </Grid>
                        </Grid>
                        {forecast.forecast_bills.map((bill, index) => (
                            <Grid container spacing={3} alignItems="flex-start" key={`forecast_bill_${index}`}>
                                <Grid item xs={4}>
                                    <NumericFormat
                                        customInput={TextField}
                                        value={bill.percentage}
                                        sx={{width:"100%"}}
                                        inputProps={{
                                            style: {textAlign: "right"},
                                            readOnly: readOnly,
                                        }}
                                        onValueChange={handlePercentageChange(index)}
                                        onFocus={() => setCachedValue(bill.percentage)}
                                        onBlur={() => saveForecastBill(index, "percentage", bill.percentage)}
                                        suffix="%"
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        convertToPercentage
                                        error={errors.forecast_bills[index] && "percentage" in errors.forecast_bills[index]}
                                        helperText={errors.forecast_bills[index] ? errors.forecast_bills[index]["percentrage"] : ""}
                                        disabled={forecast.forecast_schedule != CUSTOM_BILLING_SCHEDULE && !readOnly}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <NumericFormat
                                        customInput={TextField}
                                        value={bill.revenue}
                                        sx={{width:"100%"}}
                                        inputProps={{
                                            style: {textAlign: "right"},
                                            readOnly: readOnly,
                                        }}
                                        prefix={currency}
                                        thousandsGroupStyle="thousand"
                                        thousandSeparator=","
                                        decimalScale={0}
                                        fixedDecimalScale
                                        disabled={!readOnly}
                                        readOnly={readOnly}
                                    />
                                </Grid>
                                <Grid item xs={forecast.forecast_schedule == CUSTOM_BILLING_SCHEDULE && !readOnly ? 3.5 : 4}>
                                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                                        <DatePicker
                                            value={bill.date ? parse(bill.date, "y-MM-dd", new Date()) : null}
                                            onChange={handleCalendarChange(index, false)}
                                            onAccept={handleCalendarChange(index, true)}
                                            renderInput={(params) => (
                                                <TextField
                                                    fullWidth
                                                    {...params}
                                                    label=" "
                                                    InputLabelProps={{shrink: false}}
                                                    error={errors.forecast_bills[index] && "date" in errors.forecast_bills[index]}
                                                    helperText={errors.forecast_bills[index] ? errors.forecast_bills[index]["date"] : ""}
                                                    onFocus={() => setCachedValue(bill.date)}
                                                    onBlur={() => saveForecastBill(index)}
                                                />
                                            )}
                                            views={["month", "year"]}
                                            openTo="month"
                                            disablePast
                                            readOnly={readOnly}
                                        />
                                    </LocalizationProvider>
                                </Grid>
                                {forecast.forecast_schedule == CUSTOM_BILLING_SCHEDULE && !readOnly ? (
                                    <Grid item xs={0.5} textAlign="right" sx={{paddingTop: "20px"}}>
                                        <IconButton
                                            onClick={handleDeleteBill(index)}
                                        >
                                            <DeleteOutlinedIcon />
                                        </IconButton>
                                    </Grid>
                                ) : null}
                            </Grid>
                        ))}

                        {forecast.forecast_schedule == CUSTOM_BILLING_SCHEDULE && !readOnly ? (
                            <Button
                                color="info"
                                startIcon={<AddCircleOutlinedIcon />}
                                sx={{marginTop: "16px"}}
                                onClick={handleAddBill}
                            >
                                Add bill
                            </Button>
                        ) : null}
                    </Bills>
                ) : null}
            </AccordionDetails>
        </ForecastAccordion>
    )
}

export default ProjectForecast
