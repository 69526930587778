import { useEffect } from 'react';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

import { request } from "@/Api";

export default function CustomFiltersDropdown(props){
    const { modelType, setFilters, defaultOrder, setOrder, customFilters, setCustomFilters, activeCustomFilter, setActiveCustomFilter } = props

    useEffect(() => {
        setActiveCustomFilter(customFilters.find((filter)=>(filter.is_default)) || null)
    }, [])

    const handleAutocompleteChange = (event, newValue, reason) => {
        setActiveCustomFilter(newValue)
        setFilters(newValue ? JSON.parse(newValue.filters) : {})
        setOrder(newValue ? newValue.orders : defaultOrder)
    }

    const handleAutocompleteClear = (event, newValue, reason) => {
        if (reason == "clear"){
            setActiveCustomFilter(null)
            setFilters({})
            setOrder(defaultOrder)
        }
    }

    const handleSetDefaultCustomFilterButton = () => {
        request.put(`custom_filters/${activeCustomFilter.id}`)
        .then(function (response) {            
            setCustomFilters(response.data.filter((filter)=>(filter.model_type == modelType)))
            setActiveCustomFilter(response.data.filter((filter)=>(filter.is_default))[0])
        })
    }

    const handleDeleteCustomFilter = () => {
        request.delete(`custom_filters/${activeCustomFilter.id}`)
        .then(function (response) {            
            setCustomFilters(response.data.filter((filter)=>(filter.model_type == modelType)))
            setActiveCustomFilter(null)
            setFilters({})
            setOrder(defaultOrder)
        })
    }

    return (
        <Grid container>
            <Grid sx={{width: "100%"}}>
                <Autocomplete
                    value={activeCustomFilter ? activeCustomFilter : null}
                    sx={{width: "100%", paddingBottom: "5px"}}
                    disablePortal
                    options={customFilters}
                    getOptionLabel={(customFilter) => customFilter.name ? customFilter.name + (customFilter.is_default ? " (is default)" : "") : ""}
                    renderInput={(params) => <TextField {...params} label="Custom Filters" />}
                    onChange={handleAutocompleteChange}
                    onInputChange={handleAutocompleteClear}
                /> 
            </Grid>
            { activeCustomFilter ? (
                <Grid container>
                    <Grid>
                        { !activeCustomFilter.is_default ? (
                            <Button
                                sx={{height:"100%"}}
                                onClick={handleSetDefaultCustomFilterButton}
                                variant='contained'
                            >
                                set "{activeCustomFilter.name}" to default
                            </Button>
                        ) : null }
                    </Grid>
                    <Grid>
                        <Button
                            sx={{width:"100px", height:"100%", background:"#f55656"}}
                            onClick={handleDeleteCustomFilter}
                            variant='contained'
                        >
                            Delete
                        </Button>
                    </Grid>
                </Grid>
            ) : null }
        </Grid>
    )
}
