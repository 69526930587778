import { useDispatch, useSelector } from "react-redux"
import { Droppable } from "react-beautiful-dnd"
import { useTheme } from "@mui/material";

import BidInsert from "@/bid/BidInsert"
import BidAccordion from "@/bid/BidAccordion"
import BidCategory from "@/bid/BidCategory"
import { bidCategories } from "@/bid/constants";
import { newGroup, selectGroup, selectGroupIsVisible, selectLineHighlights } from "@/bid/bidSlice"


export default function BidSection(props) {
    const { sectionId, draggableId, readOnly, currency, officeCurrency, expandAll, canViewConfidentialData, serviceItems, isTemplate, sendSocketMessage } = props
    const section = useSelector(state => selectGroup(state, sectionId))
    const isVisible = useSelector(state => selectGroupIsVisible(state, sectionId))
    const highlights = useSelector(state => selectLineHighlights(state, sectionId))
    const dispatch = useDispatch()
    const theme = useTheme()
   
    const createCategory = (value) => {
        if(!value){return}

        dispatch(newGroup({
            name: value,
            is_makers_fees: section.is_makers_fees,
            rate_type: section.rate_type,
            markup: section.markup,
            sort_order: section.children.length + 1,
            bid_id: section.bid_id,
            parent_id: section.id,
            lineGroupType: "category",
            popupType: "create"
        }))
    }

    if (!isVisible){
        return null
    }

    return (
        <BidAccordion {...props} lineGroup={section} initialExpanded extraSummaryPadding>
            <Droppable type="category" droppableId={draggableId}>
                {(provided)=>
                <div {...provided.droppableProps} ref={provided.innerRef}>
                    {section.children.map((categoryId, categoryIndex)=>{
                        return(
                            <BidCategory
                                key={`category_${categoryId}`}
                                categoryId={categoryId}
                                expandAll={expandAll}
                                officeCurrency={officeCurrency}
                                currency={currency}
                                deleteMessage="Deleting a category will remove all line items contained inside. This is non-reversable."
                                readOnly={readOnly}
                                sx={{background: theme.palette.background.default}}
                                draggableId={`category_${categoryId}`}
                                draggableIndex={categoryIndex}
                                lineGroupType={"category"}
                                canViewConfidentialData={canViewConfidentialData}
                                serviceItems={serviceItems}
                                isTemplate={isTemplate}
                                sendSocketMessage={sendSocketMessage}
                            />
                        )
                    })}
                    { provided.placeholder }
                </div>}
            </Droppable>
            {readOnly ? null : (
                <BidInsert
                    type="category"
                    options={bidCategories}
                    createItem={createCategory}
                />
            )}
        </BidAccordion>
    )
}
