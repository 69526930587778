import { useState, useEffect, useRef } from "react"

import { Grid, TextField, IconButton, CircularProgress } from "@mui/material"
import UploadIcon from '@mui/icons-material/Upload'

import { request } from "@/Api"
import { AccountSectionRow, AccountSubtitle, AccountInput } from "@/account/StyledComponents"
import { SectionEdit } from "@/account/CommonComponents"
import ProfileAvatar from "@/common/ProfileAvatar"
import ApiAutocomplete from "@/common/ApiAutocomplete"

export default function CompanySection({ data, setSaved, setError, setData }) {
    const fileHolder = useRef()
    const [ editing, setEditing ] = useState(false)
    const [ loading, setLoading ] = useState(false)
    const [ name, setName ] = useState(data.name)
    const [ address, setAddress ] = useState(data.address)
    const [ country, setCountry ] = useState(data.country_code)
    const [ logoLoading, setLogoLoading ] = useState(false)

    const handleSave = () => {
        setError("")
        setLoading(true)

        request.patch(`legal-entities/${data.id}`, {name, address, country_id: country.id}).then(response => {
            setEditing(false)
            setData({...response.data})
            setSaved(true)
        }).catch(error => {
            const msg = Array.isArray(error.response.data.detail) ? error.response.data.detail.map(e => e.msg).join(", ") : error.response.data.detail
            setError(msg || "Failed to save")
        }).finally(() => {
            setLoading(false)
        })
    }

    const handleLogo = () => {
        setError("")
        setLogoLoading(true)
        
        const file = fileHolder.current.files[0]
        const formData = new FormData()
        formData.append("file", file, file.name)
        request.post(`legal-entities/${data.id}/logo-image`, formData)
        .then(response => {
            setData({
                ...data,
                logo: response.data.logo,
            })
        })
        .catch(error => {
            console.log(error)
            setError("Failed to upload logo")
        })
        .finally(() => setLogoLoading(false))
    }

    const handleCancel = () => {
        setEditing(false)
        setName(data.name)
        setAddress(data.address)
        setCountry(data.country)
    }

    useEffect(() => {
        if (!editing) {
            setName(data.name)
            setAddress(data.address)
            setCountry(data.country)
        }
    }, [data])

    return (
        <Grid item>
            <SectionEdit
                title="Company"
                editing={editing}
                loading={loading}
                handleEdit={() => setEditing(true)}
                handleSave={handleSave}
                handleCancel={handleCancel}
            />
            
            <AccountSectionRow>
                <AccountSubtitle>Name</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <TextField
                            value={name || ""}
                            onChange={event => setName(event.target.value)}
                            required
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p>{data.name}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            <AccountSectionRow>
                <AccountSubtitle>Address</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <TextField
                            value={address || ""}
                            onChange={event => setAddress(event.target.value)}
                            required
                            multiline
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p style={{whiteSpace: "pre-line", textAlign: "right"}}>{data.address}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            <AccountSectionRow>
                <AccountSubtitle>Country</AccountSubtitle>
                <AccountInput>
                    { editing ? (
                        <ApiAutocomplete
                            fullOptionData
                            value={country}
                            apiRoute={"locations/countries"}
                            fields={["name"]}
                            onChange={(event, value) => setCountry(value)}
                            onInputResponseLimit={30}
                            variant="filled"
                            fullWidth
                            InputProps={{
                                disableUnderline: true,
                            }}
                        />
                    ) : (
                        <p>{data.country.name}</p>
                    )}
                </AccountInput>
            </AccountSectionRow>
            <AccountSectionRow>
                <AccountSubtitle>Logo</AccountSubtitle>
                <Grid container justifyContent="flex-end" spacing={4}>
                    <Grid item>
                        <ProfileAvatar image={data.logo} name={data.name} size={40} />
                    </Grid>
                    {editing ? logoLoading ? (
                        <Grid item>
                            <CircularProgress size={36} />
                        </Grid>
                    ) : (
                        <Grid item>
                            <IconButton onClick={() => fileHolder.current.click()}><UploadIcon /></IconButton>
                            <input
                                accept="image/png, image/jpeg, image/webp"
                                ref={fileHolder}
                                style={{display: "none"}}
                                type="file"
                                onChange={handleLogo}
                            />
                        </Grid>
                    ) : null}
                </Grid>
            </AccountSectionRow>
        </Grid>
    )
}
