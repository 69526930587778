import { useSelector } from "react-redux";
import { cancelDeallocatingBill, selectDeallocatingBill } from "./actualSlice";
import { Button, CircularProgress, Grid, Modal, useTheme } from "@mui/material";
import { CustomBox, Heading } from "@/common/StyledComponents";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { request } from "@/Api";

export default function AccountingBillDeallocatePopup({getProject}){
    const [ waiting, setWaiting ] = useState(false)
    const dispatch = useDispatch()
    const deallocatingBill = useSelector(selectDeallocatingBill)
    const theme = useTheme()
    const handleClose = () => {
        dispatch(cancelDeallocatingBill())
    }
    const confirmAllocation = () => {
        setWaiting(true)
        request.put(`accounting-bills/${deallocatingBill.id}/unassign`)
        .then(()=>{
            setWaiting(false)
            getProject()
            handleClose()
        })
    }
    return (
        <Modal onClose={handleClose} open={deallocatingBill != null}>
            <CustomBox sx={{background: theme.palette.background.default, padding: 4}}>
                <Heading>Confirmation</Heading>
                <p>{`Are you sure you want to deallocate the line items on ${deallocatingBill?.accounting_bill_number}?`}</p>
                <Grid container spacing={2}>
                    <Grid item>
                        <Button variant="contained" color="secondary" onClick={handleClose}>
                            Cancel
                        </Button>
                    </Grid>
                    {waiting ? <CircularProgress /> :
                    <Grid item>
                        <Button variant="contained" color="primary" onClick={confirmAllocation}>
                            Deallocate
                        </Button>
                    </Grid>}
                </Grid>
            </CustomBox>
        </Modal>
    )
}